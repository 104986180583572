//For auth 
export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";
export const INIT_PRODUCT = "INIT_PRODUCT";
export const ADD_CART_ITEM = "ADD_CART_ITEM";
export const UPDATE_DISCOUNT = "UPDATE_DISCOUNT";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const UPDATE_USER = "UPDATE_USER";
export const LOAD_BRAND = "LOAD_BRAND";
export const SELECTED_PRODUCT = "SELECTED_PRODUCT";
