import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Grid, Paper, Typography, Card, CardContent, ImageListItem, ImageList, styled, IconButton, AppBar, Button, Chip, Divider } from '@material-ui/core';

import profilePic from "../../assets/images/lissha_prpr.jpg"
import instaLogo from "../../assets/images/instagram.png"

import plumIcon from "../../assets/images/dummy_photos/KGPe5r5ojrUr1694595572365.png"

import dummyPhoto1 from "../../assets/images/dummy_photos/318979404_552826566263952_734592534680343139_n.jpg";
import dummyPhoto2 from "../../assets/images/dummy_photos/328437972_211997201313893_3458513646514237340_n.jpg";
import dummyPhoto3 from "../../assets/images/dummy_photos/318517827_1113616329346439_6160478259138521838_n.jpg";
import dummyPhoto4 from "../../assets/images/dummy_photos/Vanilla-Caramello_Body-Lotion2_750x.webp";

import dummyPhoto5 from "../../assets/images/dummy_photos/779116795582952285cm3nvcpnjgcatalog.jpg";

import dummyVideo1 from "../../assets/videos/2989296311522944310.mp4"
import dummyVideo2 from "../../assets/videos/dummy-video122.mp4"

import paperplaneLogo from "../../assets/images/logos/PaperPlaneIcon.png";
import wishlistIcon from "../../assets/images/wishlist.png";
import { BottomBar, StoreNav } from '../homePage';
import { colors } from "../../assets/css/colors"
import { Favorite, FavoriteBorderOutlined, LocalMallOutlined, Storefront } from '@material-ui/icons';

import noItemImage from "../../assets/images/dummy_photos/1000_F_601177928_HMQkTQ9teIr6WmFGuCWl4iXNQH3pacyf-transformed.png";
import { useDispatch, useSelector } from 'react-redux';
import { ADD_CART_ITEM } from '../../redux/constants';
import { useHistory } from "react-router-dom"


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    maxHeight: "100%",
    // position: "absolute",
    // top: 0,
    // left: 0,
    // overflowY: 'auto',
    // overflowX: "hidden"
  },
  paper: {
    padding: theme.spacing(0),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    position: "relative",
    marginTop: "5px"
  },
  coverPhoto: {
    height: '20%',
    width: '100%',
  },
  cardHeading: {
    color: 'black',
    fontSize: '18px',
    // fontWeight: 'bold',
  },
  profilePhoto: {
    height: '5.2rem',
    width: '5.2rem',
    borderRadius: '50%',
    border: '2px solid #fff',
    // position: "absolute",
    // top: "4.8rem",
    // left: "38%"

  },
  imagesSection: {
    display: 'flex',
    overflowX: 'scroll',
  },
  imageCard: {
    width: '30%',
    minWidth: '100px',
    marginRight: '8px',
  },
}));

const CustomGrid = styled(Grid)({
    // Set a fixed height for the container
    // height: "70vh",
    // Enable scrolling
    overflowY: 'scroll',
    // Hide the scrollbar
    // scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 1, // Adjust to desired thickness
      height: 0,
      backgroundColor: "transparent",
      // display: "none"
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'black', // Adjust to desired color
      position: "absolute",
    },
  });


const WishList = ({isMobile, setRemoveItemToast}) => {
  const classes = useStyles();

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const cartItems = useSelector(state => state?.auth?.cartItems)
  const discountPercent = useSelector(state => state?.auth?.discount)
  const dispatch = useDispatch()

  // useEffect(() => {
  //   if(cartItems?.length){
  //     let tempIaV = [...allProfileData?.productCollection].map(v => {
  //       if(cartItems?.find(f => f.id === v.id)){
  //         return {...v, wishlisted: true}
  //       } else {
  //         return {...v}
  //       }
  //     });
  
  //     setImageAndVideos([...tempIaV])
  //   }
  // }, [])

  const [openBottomBar, setOpenBottomBar] = useState(false)
  const wishlistClick = () => {
    setOpenBottomBar(true)
  }

  const getTotalAmount = () => {
    let totalAmount = 0
    cartItems?.map(cItem => {
      totalAmount += (Number(cItem.discountedPrice) * Number(cItem?.quantity))
    })

    return totalAmount
  }


  return (
    <React.Fragment>


    {/* <div className={classes.root}> */}
        <StoreNav defaultView={true} isMobile={isMobile} headerText={"Your Cart"}/>
      <Grid container justifyContent='center' className={classes.root}>

        <Grid item xs={12} md={3}>
          <Paper className={classes.paper} elevation={0} >
            {/* {coverPic()} */}

            
            <Grid container spacing={1} style={{height: "93vh", width: "100%", paddingLeft: "10px", paddingTop: "10px"}}>

                <Grid item xs={12} style={{textAlign: "left", height: "2%"}}>
                  <Typography variant="h6" className={classes.cardHeading}>
                    Cart Products
                  </Typography>
                </Grid>


                {/* <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                  <Chip label={"All"} variant='default' style={{background: colors.primaryGradient}} color='primary'/>
                  <Chip label={"From Creators"} variant='outlined' style={{marginLeft: 5}} color='primary'/>
                </Grid> */}


                {!cartItems?.length ? <Grid item xs={12} style={{textAlign: "center"}}>
                    <img src={noItemImage} style={{height: "auto", width: "60%"}}/>

                    <Typography style={{color: "black", fontSize: "14px"}}>
                        You haven't added any products here!
                    </Typography>
                    {/* <Typography style={{fontSize: "12px", marginTop: 5}}>
                        Tap on the heart icon on the product you like to see them here.
                    </Typography> */}
                </Grid> : 
                (
                  <Grid item xs={12} style={{height: "88%", maxHeight: "88%", paddingLeft: "15px", position: "relative"}}>
                    {[...cartItems]?.map((item, index) => (
                      <Grid item container spacing={2} xs={12} style={{marginBottom: "10px"}}>
                        <Grid item xs={2}>
                          <img src={item?.images?.[0]?.src} style={{width: "100%", height: "100%", objectFit: "contain", cursor: "pointer" }} onClick={() => {
                            history.push(`/products/${item?.id}`, {productItem: item, quantity: item?.quantity, variantIndex: item?.variantIndex})
                          }}/>  
                        </Grid>
                        <Grid item xs={10} container style={{paddingTop: "10px", paddingBottom: "10px"}}>
                          <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                              <Typography style={{color: 'black', fontSize: "14px", cursor: "pointer"}} onClick={() => {
                                  history.push(`/products/${item?.id}`, {productItem: item, quantity: item?.quantity, variantIndex: item?.variantIndex})
                                }}>
                                {item?.title}
                              </Typography>
                              <Typography variant='subtitle2' style={{fontSize: "12px"}}>
                                Size: {item?.variants?.[item?.variantIndex]?.title}
                              </Typography>
                            </div>
                            <div style={{marginLeft: 'auto'}}>
                              <Typography style={{color: 'black', fontSize: "14px"}}>
                              ₹{item?.discountedPrice * Number(item?.quantity)}
                              </Typography>
                              <Typography style={{fontSize: "12px", textDecoration: "line-through"}}>
                              ₹{item?.price * Number(item?.quantity)}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item style={{display: "flex", alignItems: "center"}}>
                            <IconButton onClick={() => {
                              dispatch({type: ADD_CART_ITEM, payload: {
                                ...item, quantity: Number(item?.quantity) - 1
                              }})
                            }} disableRipple size='small' style={{padding: 5, marginRight: 10}}>-</IconButton>
                            <Typography style={{fontSize: "11px", marginRight: 10}}>
                              {item?.quantity}
                            </Typography>
                            <IconButton onClick={() => {
                              dispatch({type: ADD_CART_ITEM, payload: {
                                ...item, quantity: Number(item?.quantity) + 1
                              }})
                            }} disableRipple size='small' style={{padding: 5, padding: 0}}>+</IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}

                  {cartItems?.length ? <Grid item xs={12}>
                    <Button variant="contained" color='primary'
                      style={{borderRadius: "20px", background: colors.primary, color: "black", width: "100%", marginTop:"20px"}} disableElevation
                      onClick={() => {
                        let variantQuantityString = "";
                        cartItems?.map((cItem, cIndex) => {
                          if(cIndex > 0){
                            variantQuantityString += ","
                          }
                          variantQuantityString += `${cItem?.variants?.[cItem?.variantIndex]?.id}:${cItem?.quantity}`
                        })
                        const checkoutLink = `https://baniwomen.com/cart/${variantQuantityString}?discount=CREATORPAY${discountPercent}&utm_source=creator-pay&utm_medium=creator-pay&utm_campaign=creator-pay&utm_id=edgyetika&utm_term=creator-pay&utm_content=creator-pay-bani-women`
                        window.open(checkoutLink, "_blank")
                      }}
                    >Check Out  - ₹{getTotalAmount()}</Button>
                  </Grid> : null}
                  </Grid>
                )}

                


                {/* <Grid item xs={12} container spacing={2} style={{marginTop: "10px"}}>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{fontSize: "16px", color: "black"}}>
                            Similar Products
                        </Typography>
                    </Grid>
                    <CustomGrid item xs={12}>
                        <CustomGrid className={classes.imagesSection}>
                        {[...curatedMedia].map((item, index) => (
                            (
                            <img src={item?.src} style={{height: "15rem", width: "9rem", borderRadius: "0.5rem", marginLeft: "10px", marginBottom: "10px"}} />
                            ) 
                        ))}
                        </CustomGrid>
                    </CustomGrid>
                </Grid> */}
            </Grid>
          </Paper>
        </Grid>

        {/* <Grid item xs={12}></Grid>

        <Grid item xs={12} md={3}>
          <Paper className={classes.paper} elevation={0} >
            {/* {coverPic()} 

            
            <Grid container spacing={1}>

                <Grid item xs={12} style={{textAlign: "left"}}>
                  <Typography variant="h6" className={classes.cardHeading}>
                    Similar in bestsellers
                  </Typography>

                  <Typography variant="subtitle1" style={{fontSize: 14}}>
                    Pick up where you left off. Continue shopping for all your favorite products!
                  </Typography> 
                </Grid>

                <Grid item xs={12}></Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}></Grid> */}

      </Grid>
    {/* </div> */}
    </React.Fragment>
  );
};

export default WishList;

