import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Grid, Paper, Typography, Card, CardContent, ImageListItem, ImageList, styled, IconButton, AppBar, Button, Chip, useTheme, Tooltip, Drawer, TextField, CircularProgress } from '@material-ui/core';

import profilePic from "../../assets/images/lissha_prpr.jpg"
import instaLogo from "../../assets/images/instagram.png"
import backLogo from "../../assets/images/back.png"

import dummyPhoto1 from "../../assets/images/dummy_photos/318979404_552826566263952_734592534680343139_n.jpg";
import dummyPhoto2 from "../../assets/images/dummy_photos/328437972_211997201313893_3458513646514237340_n.jpg";
import dummyPhoto3 from "../../assets/images/dummy_photos/318517827_1113616329346439_6160478259138521838_n.jpg";
import dummyPhoto5 from "../../assets/images/dummy_photos/779116795582952285cm3nvcpnjgcatalog.jpg";
import dummyPhoto4 from "../../assets/images/dummy_photos/Vanilla-Caramello_Body-Lotion2_750x.webp";

import dummyVideo1 from "../../assets/videos/2989296311522944310.mp4"
import dummyVideo2 from "../../assets/videos/dummy-video122.mp4"

import paperplaneLogo from "../../assets/images/logos/PaperPlaneIcon.png";
import wardrobePng from "../../assets/images/search.png";
import voucherPng from "../../assets/images/voucher.png";
import shopCartPng from "../../assets/images/shopping-cart.png";

import makeupIcon from "../../assets/images/makeup.png";
import lipstickIcon from "../../assets/images/shirt.png";

import flasIcon from "../../assets/images/lightning.png";

import successAnimation from "../../assets/animationData/animation_lo96ir8z.json"

import {useHistory} from "react-router-dom"
import { FavoriteOutlined, FavoriteBorderOutlined, FiberManualRecord, FiberManualRecordOutlined, ArrowForward, FavoriteBorder, Favorite } from "@material-ui/icons"

import { colors } from "../../assets/css/colors"
import CustomToast from '../customToast';
import SwipeableButton from '../swipableButton';
import CustomButton from '../swipableButton';
import GenericAnimation from '../GenericAnimation';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    maxHeight: "100%",
    // overflowY: 'auto',
    // position: "absolute",
    // top: 0,
    // left: 0,
    // overflowX: "hidden"
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    position: "relative",
    marginTop: "5px"
  },
  coverPhoto: {
    height: '20%',
    width: '100%',
  },
  cardHeading: {
    color: 'black',
    fontSize: '18px',
    // fontWeight: 'bold',
  },
  profilePhoto: {
    height: '5.2rem',
    width: '5.2rem',
    borderRadius: '50%',
    border: '2px solid #fff',
    position: "absolute",
    top: "2.5rem",
    left: "38%"

  },
  imagesSection: {
    display: 'flex',
    overflowX: 'scroll',
  },
  imageCard: {
    width: '30%',
    minWidth: '100px',
    marginRight: '8px',
  },
}));


export function PaymentDrawer({isMobile, isBottomBarOpen, setIsBottomBarOpen}) {

  const classes = useStyles();

  const touchStartY = useRef(0);

  const addSwipeListeners = (event) => {
    touchStartY.current = event?.touches?.[0]?.clientY;
  };

  const removeSwipeListeners = (event) => {
    
    const endY = event?.changedTouches?.[0]?.clientY;
    try{
      const deltaY = endY - touchStartY?.current;
      if (deltaY > 50) {
        setIsBottomBarOpen(false);
      }
    } catch (err) {
      console.log("..", err);
    }
  };


  const carouselContent = [
    {
      "image": wardrobePng,
      "heading": "Get product links from influencer's content",
      "subHeading": "Easily find links of looks and products recommended by top influencers"
    },
    {
      "image": voucherPng,
      "heading": "Get coupon codes, offers and deals",
      "subHeading": "Receive price drop alerts and coupons on your favourite products on Whatsapp"
    },
    {
      "image": shopCartPng,
      "heading": "Wishlist and save products for later",
      "subHeading": "Login to create your wishlist and refer it anytime directly via Whatsapp"
    }
  ]

  const touchStart = useRef(null);
  const touchEnd = useRef(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    touchEnd.current = null;
    touchStart.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e) => {
    touchEnd.current = e.targetTouches[0].clientX;
  };

  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return;
    const distance = touchStart.current - touchEnd.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe)
      // console.log('swipe', isLeftSwipe ? 'left' : 'right');
      if(isLeftSwipe){
        setSelectedIndex((prevState) => {
          prevState = (prevState < carouselContent.length - 1 ? prevState + 1 : 0);
          return prevState
        })
      } else {
        setSelectedIndex((prevState) => {
          prevState = (prevState > 0 ? prevState - 1 : carouselContent.length - 1);
          return prevState
        })
      }
    //
  }
  
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [autoUpdate, setAutoUpdate] = useState("0")
  useEffect(() => {
    if(isBottomBarOpen) {
      setTimeout(() => {
        setSelectedIndex((prevState) => {
          prevState = (prevState < carouselContent.length - 1 ? prevState + 1 : 0);
          return prevState
        })
        setAutoUpdate(autoUpdate+"0")
      }, 5000)
    }
  }, [isBottomBarOpen, autoUpdate])


  const [success, setSuccess] = useState(false)

  useEffect(() => {
    setSuccess(false)
  }, [isBottomBarOpen])


  return (
    <React.Fragment>

      {isBottomBarOpen ? <><div style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 99999,
        pointerEvents: "none"
      }}></div>

      <div 
      onTouchStart={addSwipeListeners} onTouchEnd={removeSwipeListeners}
       style={{height: "auto", background: "white", zIndex: 999999, position: "fixed", bottom: 0, borderRadius: "20px 20px 0px 0px", width: isMobile ? "90%": "22%", 
        padding: isMobile ? "5%" : "1.4%",
        transition: "height 2s"
        }}>
        <Grid container spacing={1}>
          <Grid item xs={12} container justifyContent='center'>
            <Grid item>
              <div style={{width: "50px", height: "10px", backgroundColor: "grey", borderRadius: "5px", cursor: "pointer"}} onClick={() => {
                setIsBottomBarOpen(false)
                }}></div>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}></Grid> */}
          {success ? 
              <GenericAnimation animationData={successAnimation} loop={true} />
              : <Grid item container xs={12} spacing={1} alignItems='center' style={{marginTop: "5%"}}>
            <Grid item xs={3}>
              <Typography style={{fontSize: "14px", fontWeight: "bold"}}>
                Ship To
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField variant="standard" size='small' multiline fullWidth inputProps={{style: {fontSize: "14px"}}} placeholder='Enter your address' 
              value={`Tushar Rastogi 
982/24, Shiv Shakti Nagar, Madhav Puram, Meerut, 250002`}
               />
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item xs={3}>
              <Typography style={{fontSize: "14px", fontWeight: "bold"}}>
                Amount
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography style={{fontSize: "14px"}}>
                ₹ 425
              </Typography>
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item xs={3}>
            <Typography style={{fontSize: "14px", fontWeight: "bold"}}>
                Payment
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography style={{fontSize: "14px"}}>
                Cash On Delivery
              </Typography>     
            </Grid>

            <Grid item xs={12}></Grid>
          </Grid>}

          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
              {/* <SwipeableButton variant='contained' fullWidth style={{borderRadius: "20px", background: "white"}} disableElevation endIcon={<ArrowForward color='primary'/>} onClick={() => setIsBottomBarOpen(false)}>
                  <span style={{
                    background: colors.primaryGradient,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent', 
                  }}>
                    Get me in
                  </span>
              </SwipeableButton> */}

              {!success ? <CustomButton setSuccess={setSuccess} /> : (
                <Typography style={{fontSize: "20px", textAlign: "center", fontWeight: "bold"}}>
                  <span style={{
                     background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                     WebkitBackgroundClip: 'text',
                     WebkitTextFillColor: 'transparent', 
                  }}>
                    Order Placed!
                  </span>
                </Typography>
              )}

              
          </Grid>
        </Grid>
      </div></> : null}
    </React.Fragment>
  );
}
