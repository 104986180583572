import { Grid, Paper, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      height: '100%',
      maxHeight: "100%",
      // position: "absolute",
      // top: 0,
      // left: 0,
      overflowY: 'hidden',
      overflowX: "hidden"
    },
    paper: {
    //   padding: theme.spacing(2),
      // textAlign: 'center',
      width: "100%",
      color: "black",
      height: "100%",
      position: "relative",
      marginTop: "5px"
    }
}))

export default function MobileViewRender({children, isMobile}) {

    const classes = useStyles();

    return (
        <Grid container justifyContent='center' className={classes.root}>
            <Grid item xs={12} md={3}>
                <Paper className={classes.paper} elevation={0}>
                    {children}
                </Paper>
            </Grid>
        </Grid>
    )
}