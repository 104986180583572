
import React, { useEffect, useState } from 'react';
import ProfilePage, { BottomBar } from './components/homePage';
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom"
import ViewItem from './components/viewItem';
import { Grid, Typography, withWidth } from '@material-ui/core';
import './App.css'
import WishList from './components/wishlist';
import CustomToast from './components/customToast';
import BrandProduct from './components/brandProducts';
import { useDispatch, useSelector } from "react-redux"
import { initState } from './redux/actions/auth.actions';
import { SELECTED_PRODUCT, UPDATE_DISCOUNT, UPDATE_USER } from './redux/constants';
import discountAnimation from "./assets/animationData/Animation 1718645481538.json"
import GenericAnimation from './components/GenericAnimation';


function App({width}) {

  const isMobile = width === "xs" || width === "sm" ? true : false

  const [carouselOpen, setCarouselOpen] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();
  // const userExists = null
  const userExists = useSelector(state => state?.auth?.user)

  useEffect(() => {
    dispatch(initState())
    // if(!userExists){
    //   setTimeout(() => {
    //     setCarouselOpen(true)
    //   }, 10000)
    // }
  }, []);

  const selectedProduct = useSelector(state => state?.auth?.selectedProduct)
  useEffect(() => {
    if(selectedProduct && selectedProduct?.handle) {
      setCarouselOpen(true)
    }
  }, [selectedProduct])

  const [addItemToast, setAddItemToast] = useState(false)
  const [removeItemToast, setRemoveItemToast] = useState(false)

  const [openBottomBar, setOpenBottomBar] = useState(false)

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/products/:id" render={(props) => <ViewItem isMobile={isMobile} otherProps={props}
          setAddItemToast={setAddItemToast}
          setRemoveItemToast={setRemoveItemToast}
          openBottomBar={openBottomBar}
          setOpenBottomBar={setOpenBottomBar}
        />} />
        <Route exact path="/cart" render={(props) => <WishList isMobile={isMobile} otherProps={props}
          setAddItemToast={setAddItemToast}
          setRemoveItemToast={setRemoveItemToast}
          />}/>
        {/*
        <Route
          path="/:username"
          render={(props) => <ProfilePage isMobile={isMobile} carouselOpen={carouselOpen} setCarouselOpen={setCarouselOpen} updateWishListItem={updateWishListItem}
            wishListItem={wishListItem}
            setAddItemToast={setAddItemToast}
            setRemoveItemToast={setRemoveItemToast}
            openBottomBar={openBottomBar}
            login={login}
            setLogin={setLogin}
            setOpenBottomBar={setOpenBottomBar}
            allProfileData={allProfileData}
          />}
        /> */}

        {/*<Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route
          path="/verificationPending"
          render={(props) => <PendingVerification />}
        />
        <Route path="/meet" render={(props) => <MeetLayout {...props} />} /> */}

        <Route exact path='/chase-haul/:creator' render={(props) => <BrandProduct {...props} isMobile={isMobile} brand={'chase-haul'}/>} />
        <Route exact path='/bani-women/:creator' render={(props) => <BrandProduct {...props} isMobile={isMobile} brand={'bani-women'}/>} />
        <Route exact path='/chase-haul' render={(props) => <BrandProduct {...props} isMobile={isMobile} brand={'chase-haul'}/>} />
        <Route path='/' render={(props) => <BrandProduct {...props} isMobile={isMobile}/>} />
        <Redirect from="*" to={`/`} />
      </Switch>

      {addItemToast ? <CustomToast 
        addItem={true}
        open={addItemToast}
        onClose={() => setAddItemToast(false)}
        actionFunc={() => {
          history.push(`/cart`)
          // setOpenBottomBar(true)
        }}
      /> : null}

      {removeItemToast ? <CustomToast 
        addItem={false}
        open={removeItemToast}
        onClose={() => setRemoveItemToast(false)}
      /> : null}

        {/* <div style={{position: !isMobile ? "absolute" : null, left: "37.4%"}}>
          <BottomBar isMobile={isMobile} open={carouselOpen} setOpen={(bool) => {
            if(!bool){
              dispatch({type: SELECTED_PRODUCT, payload: null})
            }
            setCarouselOpen(false)
          }} setLogin={(num) => {
            // setcarouselOpen

            if(num?.length === 10) {
              // setCarouselOpen(false)
              // dispatch({type: UPDATE_DISCOUNT, payload: 35})
              // dispatch({type: UPDATE_USER, payload: num})
              navigator.clipboard.writeText("CREATORPAY35");
              window.open(`https://baniwomen.com/products/${selectedProduct?.handle}?utm_source=creator-pay&utm_medium=creator-pay&utm_campaign=creator-pay&utm_id=edgyetika&utm_term=creator-pay&utm_content=creator-pay-bani-women`, "_self")
            }
          }}
          //  allProfileData={allProfileData}
           />
        </div> */}
    </React.Fragment>


  )
}


export default withWidth()(App)
