import { ADD_CART_ITEM, END_LOADING, INIT_PRODUCT, LOAD_BRAND, SELECTED_PRODUCT, START_LOADING, UPDATE_DISCOUNT, UPDATE_USER } from "../constants"


const initialState = {
  user: null,
  productLoader: false,
  auth_error_msg: "",
  products: [],
  cartItems: [],
  discount: 25,
  selectedProduct: null,
  brand: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        productLoader: true,
      }

    case END_LOADING:
      return {
        ...state,
        productLoader: false,
      }
    
    case INIT_PRODUCT: {
      return {
        ...state, products: [...action.payload]
      }
    }

    case ADD_CART_ITEM: {
      let cartItems = [...state?.cartItems];
      const cartItem = action.payload
      const ifExists = cartItems?.findIndex(f => f?.variants?.[f?.variantIndex] === cartItem?.variants?.[cartItem?.variantIndex])

      if(ifExists === -1 && Number(cartItem?.quantity) !== 0) {
        cartItems = [{...cartItem}, ...cartItems]
      } else if(Number(cartItem?.quantity) < 1) {
        cartItems.splice(ifExists, 1)
      } else {
        cartItems[ifExists] = {...cartItem}
      }

      return {...state, cartItems: [...cartItems]} 
    }

    case UPDATE_USER: {
      return {
        ...state, user: action.payload
      }
    }

    case UPDATE_DISCOUNT: {
      let discount = Number(action.payload)
      let cartItems = [...state?.cartItems].map(item => {
        let productPrice = Number(item?.price)
        productPrice = productPrice - Math.ceil(Number(productPrice * (discount / 100)))

        return {...item, discountedPrice: productPrice}
      });
      return {
        ...state, discount: action.payload, cartItems: [...cartItems]
      }
    }

    case SELECTED_PRODUCT: {
      return {
        ...state, selectedProduct: action.payload ?? null
      }
    }

    case LOAD_BRAND: {
      return {
        ...state, brand: action?.payload ?? null 
      }
    }
    
    default:
      return state
  }
}
