import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Grid, Paper, Typography, Card, CardContent, ImageListItem, ImageList, styled, IconButton, AppBar, Button, Chip, Divider } from '@material-ui/core';

import whatsappPng from "../../assets/images/whatsapp.png"

import paperplaneLogo from "../../assets/images/logos/PaperPlaneIcon.png";
import wishlistIcon from "../../assets/images/wishlist.png";
import { BottomBar, StoreNav } from '../homePage';
import { colors } from "../../assets/css/colors"
import baniLogo from "../../assets/images/Bani_Women_Logo_-_Final.pdf_193_x_60_px.webp"
import etikaProfilePic from '../../assets/images/etika.jpg'
import { ArrowBack, Favorite, FavoriteBorderOutlined, Instagram, LocalMallOutlined, ShoppingCart, Star, Storefront } from '@material-ui/icons';
import { PaymentDrawer } from '../bottomDrawer/paymentDrawer';

import { useLocation, useHistory } from "react-router-dom"
import CustomToast from '../customToast';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_CART_ITEM } from '../../redux/constants';

import DOMPurify from 'dompurify';
import parse from 'html-react-parser';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    maxHeight: "100%",
    // position: "absolute",
    // top: 0,
    // left: 0,
    overflowY: 'auto',
    overflowX: "hidden"
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    position: "relative",
    marginTop: "5px"
  },
  coverPhoto: {
    height: '20%',
    width: '100%',
  },
  cardHeading: {
    color: 'black',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  profilePhoto: {
    height: '5.2rem',
    width: '5.2rem',
    borderRadius: '50%',
    border: '2px solid #fff',
    // position: "absolute",
    // top: "4.8rem",
    // left: "38%"

  },
  imagesSection: {
    display: 'flex',
    overflowX: 'scroll',
  },
  imageCard: {
    width: '30%',
    minWidth: '100px',
    marginRight: '8px',
  },
}));

const CustomGrid = styled(Grid)({
    // Set a fixed height for the container
    // height: "70vh",
    // Enable scrolling
    overflowY: 'scroll',
    // Hide the scrollbar
    // scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 1, // Adjust to desired thickness
      height: 0,
      backgroundColor: "transparent",
      // display: "none"
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'black', // Adjust to desired color
      position: "absolute",
    },
  });


const ViewItem = ({isMobile, setAddItemToast}) => {
  const classes = useStyles();

  const location = useLocation()
  const history = useHistory()

  const productItems = useSelector(state => state?.auth?.products)
  const discountPercent = useSelector(state => state?.auth?.discount)
  const dispatch = useDispatch()

  const { productItem, isSpecial } = location.state || {};

  const [waToast, setWaToast] = useState(false)

  // useEffect(() => {
  //   if (location?.pathname && productItems?.length) {
  //     let productId = location?.pathname?.split("/view/");
  //     productId = productId[1];

  //     let productItem_ = productItems.find(f => f?.productId === Number(productId));

  //     setProductItem({...productItem_})
  //   }
  // }, [location, productItems])

  const sendMessage = (mobile) => {
    if(mobile?.length) {

      const body = JSON.stringify({ 
        "mobileNo": mobile,
        ...productItem
      });

      fetch('https://loadbalancer.paperplane.health:8400/api/v1/auth/store-message', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body
        });
    }
  }


  // const coverPic = () => {
  //   const coverPicStyle = {
  //     textAlign: 'center',
  //     height: '7.25rem',
  //     minWidth: '100%',
  //     borderRadius: '0.5rem',
  //     backgroundImage: 'url("https://d3g01po1nkka75.cloudfront.net/Cover2.png")',
  //     backgroundSize: 'cover',
  //     backgroundPosition: 'center center',
  //   };
  
  //   return (
  //     <div className="cover-pic-wrapper" style={coverPicStyle}>
  //       {/* You can add any additional content or components within this div */}
  //     </div>
  //   );
  // };


  // const curratedPic = (curatePic) => {

  //   const coverPicStyle = {
  //     textAlign: 'center',
  //     height: '21rem',
  //     width: '13rem',
  //     minWidth: '12rem',
  //     marginLeft: 10,
  //     marginBottom: 10,
  //     borderRadius: '0.5rem',
  //     backgroundImage: `url(${curatePic})`,
  //     backgroundSize: 'cover',
  //     backgroundPosition: 'center center',
  //   };
  
  //   return (
  //     <div className="cover-pic-wrapper" style={coverPicStyle}>
  //       {/* You can add any additional content or components within this div */}
  //     </div>
  //   );
  // };

  const [update, setUpdate] = useState(0)

  useEffect(() => {
    window.scrollTo(0, -100);
    setVariantIndex(0)
    setProductQuantity(1)
  }, [String(update)])

  // const curatedMedia = [
  //   { type: 'image', src: dummyPhoto2, alt: 'Image 1' },
  //   { type: 'image', src: dummyPhoto3, alt: 'Image 2' },
  //   // Add more images and videos as needed
  // ];

  const [openBottomBar, setOpenBottomBar] = useState(false)
  const [selectedImage, setSelectedImage] = useState(0)
  const [variantIndex, setVariantIndex] = useState(location?.state?.variantIndex ?? 0)
  const [redirectTo, setRedirectTo] = useState(null)
  const [paymentDrawer, setPaymentDrawer] = useState(false)

  const wishlistClick = (type = null) => {
    setOpenBottomBar(true)
    setRedirectTo(type)
  }

  const [productQuantity, setProductQuantity] = useState(location?.state?.quantity ?? 1)

  const getBodyText = (htmlString) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);

    const parsedHTML = parse(sanitizedHTML);
    return parsedHTML
  }

  const getDiscountPrice = (product) => {
    let productPrice = Number(product?.variants?.[0]?.price)
    productPrice = Number(product?.variants?.[0]?.price) - Math.ceil(Number(productPrice * (discountPercent / 100)))

    return productPrice
  }

  const allTestimonials = productItem?.reviews ?? [];

  const [startIndex, setStartIndex] = useState(0);
    const n = allTestimonials.length;

    const testimonials = [
        allTestimonials[startIndex % n],
        allTestimonials[(startIndex + 1) % n],
        allTestimonials[(startIndex + 2) % n]
    ];

    const next = () => {
        setStartIndex(prev => (prev + 1) % n);
    };

    const prev = () => {
        setStartIndex((startIndex - 1 + n) % n);
    };

    // useEffect(() => {
    //   if(isSpecial){
    //     const intervalId = setInterval(() => {
    //         next();
    //       }, 4000); // 4000 milliseconds = 4 seconds
      
    //       // Cleanup the interval on component unmount
    //       return () => clearInterval(intervalId);
    //   }
    // }, [])

  return (
    <React.Fragment>


    {/* <div className={classes.root}> */}
        <StoreNav defaultView={true} isMobile={isMobile} wishlistClick={wishlistClick}   />
      <Grid container justifyContent='center' className={classes.root}>

        <Grid item xs={12} md={3}>
          <Paper className={classes.paper} elevation={0}>
            {/* {coverPic()} */}

            
            <Grid container spacing={1}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} container justifyContent='center'>
                    <Grid item style={{position: "relative"}}>
                        <img src={productItem?.images?.[selectedImage]?.src} style={{height: "18rem", width: "12rem", objectFit: "contain", borderRadius: "5px"}} />
                        {/* <div style={{position: "absolute", top: 0, right: -50}}>
                          <IconButton onClick={() => {
                            // setIsWishlisted(!isWishlisted)
                            // if(!isWishlisted) {
                            //   updateWishListItem(productItem, "add")
                            //   setAddItemToast(true)
                            // } else {
                            //   updateWishListItem(productItem, "removeById", 0, productItem?.id)
                            //   setRemoveItemToast(true)
                            // }
                          }}>
                            {false ? <Favorite /> : <FavoriteBorderOutlined />}
                          </IconButton>
                        </div> */}
                        {/* <div style={{position: "absolute", top: -10, left: -90}}>
                          <IconButton onClick={() => {
                            history.goBack()
                          }}>
                            <ArrowBack />
                          </IconButton>
                        </div>
                        <div style={{position: "absolute", top: -0, right: -85}}>
                          <IconButton onClick={() => {
                            history.goBack()
                          }} color='primary' size='small'>
                            <ShoppingCart />
                          </IconButton>
                        </div> */}
                    </Grid>
                    <Grid item xs={12}></Grid>
                    {productItem?.images?.length ? <Grid item>
                      <Grid container style={{marginTop: isSpecial ? null : "-20px", marginBottom: "30px"}}>
                        <Grid item md={3} xs={2}></Grid>
                        <CustomGrid item md={6} xs={8} style={{display: "flex", overflowX: "scroll", maxWidth: isMobile ? "400px" : null}}>
                          {productItem?.images?.map((v, i) => (
                            <img src={v?.src} style={{height: "40px", width: "40px", marginRight: "10px",
                              cursor: "pointer",
                              padding: 5,
                              border: i === selectedImage ? "solid 1px black" : null
                            }}
                            onClick={() => {
                              setSelectedImage(i)
                            }}
                            />
                          ))}
                        </CustomGrid>
                        <Grid item md={3} xs={2}></Grid>
                      </Grid>
                    </Grid> : null}
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} container>
                            <Grid item xs={6}>
                                <Typography variant="h5" style={{fontSize: "16px", color: "black"}}>
                                  {productItem?.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} container justifyContent='right'>
                                <Grid item style={{marginLeft: 'auto'}}>
                                  <div style={{display: "flex"}}>
                                    <Typography variant="h5" style={{fontSize: "16px", fontWeight: "bold"}}>₹{productItem?.discountedPrice}</Typography>
                                    <Typography variant="h5" style={{fontSize: "14px", marginLeft: 10, opacity: 0.5, textDecoration: "line-through"}}>₹{productItem?.price} </Typography>
                                  </div>
                                  <div style={{textAlign: "right", marginTop: "5px"}}>
                                    <Typography variant="h5" style={{fontSize: "12px", color: colors.secondary}}>({discountPercent}% OFF)</Typography>
                                  </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography variant="body1">Item Description</Typography>
                        </Grid> */}
                        {/* <Grid item xs={12}>
                        <Button variant="contained" color="primary" className="buy-button">
                            Buy
                        </Button>
                        <Button variant="contained" color="secondary" className="go-to-cart-button">
                            Go to Cart
                        </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
                
                <Grid item xs={12} style={{display: "flex", alignItems: 'center', marginTop: "10px"}}>
                      <Typography>
                        Size
                      </Typography>
                </Grid>
                <Grid item xs={12} style={{display: "flex", alignItems: 'center', marginTop: "5px", marginBottom: "10px"}}>

                  {productItem?.variants?.length ? (
                    productItem?.variants?.map((variant, variantIdx) => (
                      <Button variant={variantIdx === variantIndex ? "contained" : "outlined"} style={{
                      width: '30px',  // You can adjust the size
                      height: '30px', // You can adjust the size
                      minWidth: '0',  // Override default minWidth
                      padding: '0', marginRight: "10px"}} disableElevation onClick={() => setVariantIndex(variantIdx)}>
                        {variant?.title}
                      </Button>
                    ))
                  ) : null}
                </Grid>

                <Grid item xs={12} style={{display: "flex", alignItems: 'center', marginTop: "10px"}}>
                    <Button variant='outlined' style={{ borderRadius: '50%',
                      width: '30px',  // You can adjust the size
                      height: '30px', // You can adjust the size
                      minWidth: '0',  // Override default minWidth
                      padding: '0', }} onClick={() => {
                        setProductQuantity(prevState => {
                          if(prevState > 0){
                            return (prevState - 1)
                          } return prevState
                        })
                      }}>-</Button>
                    <Typography style={{marginLeft: "10px"}}>
                      {productQuantity}
                    </Typography>
                    <Button variant='outlined' style={{ borderRadius: '50%',
                      width: '30px',  // You can adjust the size
                      height: '30px', // You can adjust the size
                      minWidth: '0',  // Override default minWidth
                      padding: '0', marginLeft: "10px" }} onClick={() => {
                        setProductQuantity(prevState => prevState + 1)
                      }}>+</Button>

                    <Button disabled={productQuantity <= 0} variant='outlined' onClick={() => {
                        dispatch({
                          type: ADD_CART_ITEM,
                          payload: {
                            ...productItem,
                            quantity: productQuantity,
                            variantIndex: variantIndex
                          }
                        });
                        setAddItemToast(true)  
                      }} style={{borderRadius: "20px", marginLeft: "auto", marginRight: "10px", color: "black", borderColor: "black"}} disableElevation>
                        Add to Cart
                    </Button>
                    <Button disabled={productQuantity <= 0} variant='contained' onClick={() => {
                      // wishlistClick("payment")
                      window.open(`https://baniwomen.com/cart/${productItem?.variants?.[variantIndex]?.id}:${productQuantity}?discount=CREATORPAY${discountPercent}&utm_source=creator-pay&utm_medium=creator-pay&utm_campaign=creator-pay&utm_id=edgyetika&utm_term=creator-pay&utm_content=creator-pay-bani-women`, "_blank")
                    }} style={{borderRadius: "20px", background: colors.primary, color: "black"}} disableElevation>
                        Buy Now
                    </Button>
                </Grid>

                {productItem?.body_html ? <Grid item xs={12} style={{marginTop: "10px"}}>
                    <Typography variant='h6' style={{fontSize: "14px", color: "black"}}>
                        Product Description
                    </Typography>
                    <Typography variant='h6' style={{fontSize: "12px", marginTop: 10, marginBottom: 10}}>
                      {getBodyText(productItem?.body_html)}
                    </Typography>
                </Grid> : null}

                {isSpecial ? (
                    <Grid item xs={12} container justifyContent="center" spacing={1} style={{marginTop: "10px"}}>
                      <Grid item>
                          <Typography variant="h6" style={{fontWeight: "normal", fontSize: "16px"}}>
                              Recent Purchases
                          </Typography>
                      </Grid>

                      <Grid item xs={12}></Grid>

                      <Grid item style={{display: "flex", alignItems: "center"}}>
                          {testimonials?.map((testimonial, index) => (
                              <img src={testimonial?.src} style={{
                                  width: index === 1 ? "60px" : "40px",
                                  objectFit: "contain",
                                  height: index === 1 ? "60px" : "40px",
                                  borderRadius: "10px",
                                  marginRight: index < 2 ? "20px" : null,
                                  cursor: index === 1 ? null : 'pointer'
                              }} onClick={index === 1 ? null : () => (index === 0 ? prev() : next())}/>
                          ))}
                      </Grid>

                      <Grid item xs={12}></Grid>

                      <Grid item style={{display: "flex", alignItems: "center"}}>
                          <Star style={{fontSize: "12px", marginRight: "1px", color: "orange"}} />
                          <Star style={{fontSize: "12px", marginRight: "1px", color: "orange"}} />
                          <Star style={{fontSize: "12px", marginRight: "1px", color: "orange"}} />
                          <Star style={{fontSize: "12px", marginRight: "1px", color: "orange"}} />
                          <Star style={{fontSize: "12px", marginRight: "1px", color: "orange"}} />
                      </Grid>
                      <Grid item xs={12}></Grid>

                      <Grid item style={{marginTop: "-10px", width: "95%"}}>
                          <Typography style={{fontSize: "14px", textAlign: "center"}}>
                              {testimonials?.[1]?.comment}
                          </Typography>
                      </Grid>

                      <Grid item xs={12}></Grid>

                      <Grid item style={{marginTop: "-10px"}}>
                          <Typography style={{fontSize: "12px", textAlign: "center"}}>
                            <i>
                              - {testimonials?.[1]?.alt}
                            </i>
                          </Typography>
                      </Grid>
                  </Grid>
                ) : null}

                <Grid item xs={12} style={{
                    // height: "150px",
                    width: "100%",
                    borderRadius: "20px",
                    background: "#F8F9F9",
                    marginTop: "10px"
                  }} container>
                  <Grid item xs={12} style={{display: "flex", alignItems: "center", padding: 10}}>
                    <img src={baniLogo} style={{
                      borderRadius: "10px",
                      // height: "40px",
                      width: "40px",
                      objectFit: "contain",
                      marginRight: "10px"
                    }} />

                    <Typography variant="caption">
                      Fulfilled by
                      <Typography style={{fontSize: "14px", fontWeight: "500"}}>
                        Bani Women
                      </Typography>
                    </Typography>
                    
                    <Button variant="text" startIcon={<Storefront  />} style={{marginLeft: "auto", fontSize: "10px"}}
                      onClick={() => window.open("https://baniwomen.com?utm_source=creator-pay&utm_medium=creator-pay&utm_campaign=creator-pay&utm_id=edgyetika&utm_term=creator-pay&utm_content=creator-pay-bani-women")}
                    >
                      Visit  Brand
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} style={{display: "flex", alignItems: "center", padding: 10}}>
                    <img src={etikaProfilePic} style={{
                      borderRadius: "10px",
                      height: "40px",
                      width: "40px",
                      marginRight: "10px"
                    }} />
                    
                    <Typography variant="caption">
                      Curated for you by
                      <Typography style={{fontSize: "14px", fontWeight: "500"}}>
                        Etika Nehra
                      </Typography>
                    </Typography>

                    <Button variant="text" startIcon={<Instagram  />} style={{marginLeft: "auto", fontSize: "10px"}}
                      onClick={() => window.open("https://instagram.com/edgyetika")}
                    >
                      Visit Insta
                    </Button>
                  </Grid>
                </Grid>

               {!isSpecial && [...productItems]?.length ? <Grid item xs={12} container spacing={2} style={{marginTop: "10px"}}>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{fontSize: "14px", color: "black"}}>
                            Similar Products
                        </Typography>
                    </Grid>
                    <CustomGrid item xs={12}>
                        <CustomGrid className={classes.imagesSection} style={{height: "18rem", overflowY: 'hidden'}}>
                        {[...productItems]?.filter(f => f?.id !== productItem?.id).map((product, index) => (
                            (
                              <Paper style={{width: "8rem", minWidth: "8rem", height: "17rem", marginLeft: "10px", cursor: "pointer" }}
                                  onClick={() => {
                                    history.push(`/products/${product?.id}`, {productItem: {...product, price: Number(product?.variants?.[0]?.price),
                                      discountedPrice:getDiscountPrice(product)
                                    }})
                                    setUpdate(prev => prev+1)
                                }}
                              >
                                  <Grid container spacing={1}>
                                      <Grid item xs={12}>
                                          <img src={product?.images?.[0]?.src} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                                      </Grid>
                                      <Grid item xs={12} style={{padding: 8}}>
                                          <Typography style={{fontSize: "12px"}}>
                                              {product.title}
                                          </Typography>
                                          <div style={{display:"flex", alignItems:'center', marginTop: 5}}>
                                              <Typography style={{textDecoration: 'line-through', color: 'grey', fontSize: "11px"}}>
                                                  &#8377;{Number(product?.variants?.[0]?.price)}
                                              </Typography>
                                              <Typography style={{fontWeight: "bold", marginLeft: 5, fontSize: "12px"}}>
                                                  &#8377;{getDiscountPrice(product)}
                                              </Typography>
                                              <Typography style={{color: "red", marginLeft: 'auto', fontSize: "10px"}}>
                                                  {discountPercent}% OFF
                                              </Typography>
                                          </div>
                                      </Grid>
                                  </Grid>
                              </Paper>
                            ) 
                        ))}
                        </CustomGrid>
                </CustomGrid>
                </Grid> : null}

            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}></Grid>

        {/* <Grid item xs={12} md={3}>
          <BottomBar isMobile={isMobile} open={openBottomBar} setOpen={setOpenBottomBar} setLogin={setLogin} onLogin={redirectTo === "payment" ? (() => {
            setPaymentDrawer(true)
            setRedirectTo(null)
          }) : (redirectTo === "cart" ? ((mobile) => {
            // alert(mobile)
            setWaToast(true)
            sendMessage(mobile)
            setRedirectTo(null)
          }) : null)}
          //  allProfileData={allProfileData}
           />
        </Grid> */}

        <Grid item xs={12}></Grid>

        <Grid item xs={12} md={3}>
          <PaymentDrawer isMobile={isMobile} isBottomBarOpen={paymentDrawer} setIsBottomBarOpen={setPaymentDrawer}/>
        </Grid>

        {waToast ? <CustomToast 
          addItem={false}
          open={waToast}
          onClose={() => setWaToast(false)}
          customMessage={"Sent mesasge on your whatsapp!"}
          imagePng={whatsappPng}
          // actionFunc={() => setOpenBottomBar(true)}
        /> : null}

      </Grid>
    {/* </div> */}
    </React.Fragment>
  );
};

export default ViewItem;

