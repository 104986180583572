import React, { useEffect, useState } from "react";
import MobileViewRender from "../mobileView";
import { Button, CircularProgress, Grid, Paper, Typography, styled } from "@material-ui/core";
import baniWomenLogo from "../../assets/images/Bani_Women_Logo_-_Final.pdf_193_x_60_px.webp";
import creatorPayLogo from "../../assets/images/creatorpaylogo.png";
import {useHistory, useParams} from "react-router-dom"
import { FiberManualRecord, ShoppingCart, Star } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import baniBanner from "../../assets/images/bani-banner.png"
import { baniWomenProducts, chaseHaulProducts, chaseHaulProducts1, chaseHaulProducts2, reelProducts } from "./products";
import { LOAD_BRAND, SELECTED_PRODUCT } from "../../redux/constants";
import { chaseHaulProducts3 } from "./products1";


const CustomGrid = styled(Grid)({
    // Set a fixed height for the container
    // height: "70vh",
    // Enable scrolling
    overflowY: 'scroll',
    // Hide the scrollbar
    // scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 1, // Adjust to desired thickness
      height: 0,
      backgroundColor: "transparent",
      // display: "none"
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'black', // Adjust to desired color
      position: "absolute",
    },
  });


export default function BrandProduct({isMobile, brand}) {

    const productLoader = useSelector(state => state?.auth?.productLoader);
    const collectionProducts = useSelector(state => state?.auth?.products);
    const discountPercent = useSelector(state => state?.auth?.discount);
    const history = useHistory()

    const creator = window.location.pathname?.split("/");

    const dispatch = useDispatch()

    const getFrontImage = (product) => {
        return (product?.images?.[0]?.src)
    }

    const brandData = useSelector(state => state?.auth?.brand);

    useEffect(() => {
        let brandProducts = null;
        if(brand === 'chase-haul') {
            brandProducts = chaseHaulProducts;

            if(creator?.[2] === "stylewithshikharti"){
                brandProducts = chaseHaulProducts2;
            } else if(creator?.[2] === "suminegi_haul"){
                brandProducts = chaseHaulProducts3;
            } else if(creator?.[2]){
                brandProducts = chaseHaulProducts1;
            }

            dispatch({type: LOAD_BRAND, payload: {
                'site': 'https://chasehaul.com',
                'image': 'https://storage.googleapis.com/website-paperplane/CHASE_HAUL_LOGO_220x.png',
                'banner': 'https://storage.googleapis.com/website-paperplane/chase%20haul%20banner.png',
                'products': brandProducts,
                "creator": creator?.[2] ? creator?.[2] : 'suminegi_haul'
            }})
        } else if(brand === 'bani-women') {
            brandProducts = baniWomenProducts;
            dispatch({type: LOAD_BRAND, payload: {
                'site': 'https://baniwomen.com',
                'image': baniWomenLogo,
                'banner': baniBanner,
                'products': brandProducts,
                "creator": creator?.[2]
            }})
        }
    }, [brand])

    const getDiscountPrice = (product) => {
        let productPrice = Number(product?.variants?.[0]?.price)
        productPrice = Number(product?.variants?.[0]?.price) - Math.ceil(Number(productPrice * (discountPercent/100)))

        return productPrice
    }

    const allTestimonials = [
        {
          src: "//baniwomen.com/cdn/shop/files/Vasundhra_Chauhan.jpg?v=1685351133&width=70",
          alt: "Vasundhra Chauhan",
          comment: "The sharara set is absolutely stunning and it fits like a glove :p"
        },
        {
          src: "//baniwomen.com/cdn/shop/files/Urwashi_Lalwani.jpg?v=1685351013&width=70",
          alt: "Urwashi Lalwani",
          comment: "I love the dress, the fit, the quality is still going strong. I get compliments whenever I wear it."
        },
        {
          src: "//baniwomen.com/cdn/shop/files/Rudrakshi_Jain.jpg?v=1685351092&width=70",
          alt: "Rudrakshi Jain",
          comment: "Yess, loved them! Totally worth it."
        },
        {
          src: "//baniwomen.com/cdn/shop/files/Akanksha.jpg?v=1685351056&width=70",
          alt: "Akanksha",
          comment: "Fabric is super comfortable and it looks fabulous. Highly recommend!"
        },
      ];

    
    
    const [startIndex, setStartIndex] = useState(0);
    const n = allTestimonials.length;

    const testimonials = [
        allTestimonials[startIndex % n],
        allTestimonials[(startIndex + 1) % n],
        allTestimonials[(startIndex + 2) % n]
    ];

    const next = () => {
        setStartIndex(prev => (prev + 1) % n);
    };

    const prev = () => {
        setStartIndex((startIndex - 1 + n) % n);
    };

    useEffect(() => {

        const intervalId = setInterval(() => {
            next();
          }, 4000); // 4000 milliseconds = 4 seconds
      
          // Cleanup the interval on component unmount
          return () => clearInterval(intervalId);
    }, [])

    return (
        <MobileViewRender isMobile={isMobile}>
            <Grid container spacing={2} style={{padding: 10, marginTop: "-20px"}}>
                <Grid item xs={12} container justifyContent="center">
                    <Grid item>
                        <img src={!brand ? baniWomenLogo : brandData?.image} style={{height: "80px", width: "160px", objectFit: "contain"}} />
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item>
                        <Typography style={{marginTop: "-10px", fontSize: "14px", display: "flex", alignItems: "center"}}>
                            Powered by <img src={creatorPayLogo} style={{width: "100px", marginLeft: "10px", objectFit: "contain"}} />
                        </Typography>
                    </Grid>
                </Grid>
                <img src={!brand ? "https://storage.googleapis.com/website-paperplane/etika%202.png" : brandData?.banner} style={{width: "100%", objectFit: 'contain', marginTop: "20px"}} />

                <Grid item xs={12} style={{marginTop: "5%", display: "flex", alignItems: "center"}}>
                    <Typography variant="h6" style={{fontSize: "16px"}}>
                        Recently Watched Products
                        <Typography variant="h6" style={{fontSize: "14px", color: "grey"}}>
                            Get the dresses you see in my reels!
                        </Typography>
                    </Typography>
                    {/* <Button variant='text' startIcon={<ShoppingCart style={{fontSize: "15px"}}/>} style={{marginLeft: "auto"}} 
                        // onClick={() => !login ? wishlistClick() : history.push(`/${allProfileData?.username}/wishlist`)}
                        onClick={() => history.push(`/cart`)}
                    >
                        <Typography style={{fontSize: "14px"}}>
                            My Cart
                        </Typography>

                        {wishListItemExists ? <Typography style={{position: "absolute", right: 0, top: 0, fontWeight: 'bold', color: "black", fontSize: '10px'}}>{wishListItemExists}</Typography> : null}
                    </Button>  */}
                </Grid>
                {!productLoader ? <Grid item spacing={2} container xs={12} style={{marginLeft: "2px"}}>
                    {(!brand ? reelProducts : brandData?.products)?.map((product, pIdx) => (
                        <Grid item xs={6}>
                            <Paper style={{width: "100%", cursor: "pointer" }}
                                onClick={() => {
                                    // history.push(`/products/${product?.id}`, {productItem: {...product, price: Number(product?.variants?.[0]?.price),
                                    //     discountedPrice:getDiscountPrice(product)
                                    // }, isSpecial: true})
                                    if(!brand){
                                        window.open(`https://baniwomen.com/products/${product?.handle}?utm_source=creator-pay&utm_medium=creator-pay&utm_campaign=creator-pay&utm_id=suminegi_haul&utm_term=creator-pay&utm_content=creator-pay`, '_self')

                                        // dispatch({type: SELECTED_PRODUCT, payload: product})
                                    } else {
                                        window.open(`${brandData?.site}/products/${product?.handle}?utm_source=creator-pay&utm_medium=creator-pay&utm_campaign=creator-pay&utm_id=${brandData?.creator}&utm_term=creator-pay&utm_content=creator-pay`, '_self')
                                    }
                            }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <img src={getFrontImage(product)} style={{width: "100%", height: "300px", objectFit: "contain"}}/>
                                    </Grid>
                                    <Grid item xs={12} style={{padding: 8, height: "auto"}}>
                                        <Typography style={{fontSize: "14px"}}>
                                            {product.title}
                                        </Typography>
                                        <div style={{display:"flex", alignItems:'center', marginTop: 5}}>
                                            <Typography style={{textDecoration: 'line-through', color: 'grey', fontSize: "12px"}}>
                                                &#8377;{Number(product?.variants?.[0]?.compare_at_price)}
                                            </Typography>
                                            <Typography style={{fontWeight: "bold", marginLeft: 5, fontSize: "14px"}}>
                                                &#8377;{brand ? Number(product?.variants?.[0]?.price) : getDiscountPrice(product)}
                                            </Typography>
                                            <Typography style={{color: "red", marginLeft: 'auto', fontSize: "12px"}}>
                                                {brand ? product?.defaultDiscount : discountPercent}% OFF
                                            </Typography>
                                        </div>
                                        <Typography style={{color: 'grey', fontSize: "12px", marginTop: "5px"}}>
                                            {product?.noViews ? `👁️ ${product?.noViews}k views today` : (pIdx === 0 ? "👁️ 10.2k views today" : "👁️ 8.9k views today")}
                                        </Typography>
                                        <Typography style={{color: 'grey', fontSize: "12px", marginTop: "5px"}}>
                                            {product?.noPurchases ? `🛍️ ${product?.noPurchases}k purchases from Myntra` : (pIdx === 0 ? "🛍️ 2.1k purchases from Myntra" : "🛍️ 1.9k purchases from Myntra")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        ))}
                </Grid> : (<React.Fragment>
                        <Grid item xs={12} container justifyContent="center">
                            <Grid item style={{marginTop: "20%", marginBottom: "20%"}}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </React.Fragment>)}
                    
                {!brand ? <React.Fragment>
                    <Grid item xs={12} style={{marginTop: "5%"}}>
                    <Typography variant="h6" style={{fontSize: "16px"}}>
                        Most Loved
                        <Typography variant="h6" style={{fontSize: "14px", color: "grey"}}>
                            Most bought products from the brand
                        </Typography>
                    </Typography>
                </Grid>
                {!productLoader ? <CustomGrid item xs={12} style={{display: "flex", height: "21rem", maxHeight: "21rem", overflowY: "hidden", width: "99%", maxWidth: "99%", overflowX: "scroll", alignItems: 'center', padding: 10}}>
                    {collectionProducts?.length ? collectionProducts?.map((product) => (
                        <Paper style={{width: "10rem", minWidth: "10rem", height: "20rem", marginLeft: "10px", cursor: "pointer"}}
                        onClick={() => {
                            // history.push(`/products/${product?.id}`, {productItem: {...product, price: Number(product?.variants?.[0]?.price),
                            //     discountedPrice:getDiscountPrice(product)
                            // }})
                            dispatch({type: SELECTED_PRODUCT, payload: product})
                        }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <img src={getFrontImage(product)} style={{width: "100%", height: "100%", maxHeight: "100%", objectFit: "contain"}}/>
                                </Grid>
                                <Grid item xs={12} style={{padding: 8}}>
                                    <Typography style={{fontSize: "14px"}}>
                                        {product.title}
                                    </Typography>
                                    <div style={{display:"flex", alignItems:'center', marginTop: 5}}>
                                        <Typography style={{textDecoration: 'line-through', color: 'grey', fontSize: "12px"}}>
                                            &#8377;{Number(product?.variants?.[0]?.price)}
                                        </Typography>
                                        <Typography style={{fontWeight: "bold", marginLeft: 5, fontSize: "14px"}}>
                                            &#8377;{getDiscountPrice(product)}
                                        </Typography>
                                        <Typography style={{color: "red", marginLeft: 'auto', fontSize: "12px"}}>
                                            {discountPercent}% OFF
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>))
                    : null}
                </CustomGrid> : (<React.Fragment>
                        <Grid item xs={12} container justifyContent="center">
                            <Grid item style={{marginTop: "20%", marginBottom: "80%"}}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </React.Fragment>)}

                <Grid item xs={12} container justifyContent="center" spacing={1} style={{marginTop: "20px"}}>
                    <Grid item>
                        <Typography variant="h6" style={{fontWeight: "normal"}}>
                            Hear the BANI
                        </Typography>
                    </Grid>

                    <Grid item xs={12}></Grid>

                    <Grid item style={{display: "flex", alignItems: "center"}}>
                        {testimonials?.map((testimonial, index) => (
                            <img src={testimonial?.src} style={{
                                width: index === 1 ? "80px" : "60px",
                                objectFit: "contain",
                                height: index === 1 ? "80px" : "60px",
                                borderRadius: "10px",
                                marginRight: index < 2 ? "20px" : null,
                                cursor: index === 1 ? null : 'pointer'
                            }} onClick={index === 1 ? null : () => (index === 0 ? prev() : next())}/>
                        ))}
                    </Grid>

                    <Grid item xs={12}></Grid>

                    <Grid item style={{display: "flex", alignItems: "center"}}>
                        <Star style={{fontSize: "12px", marginRight: "1px", color: "orange"}} />
                        <Star style={{fontSize: "12px", marginRight: "1px", color: "orange"}} />
                        <Star style={{fontSize: "12px", marginRight: "1px", color: "orange"}} />
                        <Star style={{fontSize: "12px", marginRight: "1px", color: "orange"}} />
                        <Star style={{fontSize: "12px", marginRight: "1px", color: "orange"}} />
                    </Grid>
                    <Grid item xs={12}></Grid>

                    <Grid item style={{marginTop: "-10px", width: "95%"}}>
                        <Typography style={{fontSize: "14px", textAlign: "center"}}>
                            {testimonials?.[1]?.comment}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}></Grid>

                    <Grid item style={{marginTop: "-10px"}}>
                        <Typography style={{fontSize: "12px", textAlign: "center"}}>
                           <i>
                            - {testimonials?.[1]?.alt}
                           </i>
                        </Typography>
                    </Grid>
                </Grid> </React.Fragment> : null}
                <Grid item xs={12}></Grid>
            </Grid>
        </MobileViewRender>
    )
}