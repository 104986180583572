
import { END_LOADING, INIT_PRODUCT, SELECTED_PRODUCT, START_LOADING, UPDATE_DISCOUNT } from "../constants"


// const registerUserSuccess = (userData) => ({
//   type: REGISTER_SUCCESS,
//   payload: userData,
// })
// export const loginUser = (payload) => {
//   console.log(payload)
//   return async (dispatch) => {
//     dispatch({ type: USER_LOADING })

//     customAxios
//       .post(`/auth/login`, {dispatch
//         ...payload,
//       })
//       .then((res) => {
//         if (!res.data.success) {
//           dispatch({ type: LOGIN_FAIL, payload: {} })
//           toast.error("Please enter the correct authentication details")
//         } else {
//           localStorage.setItem("token", res.data.data.access_token)
          
//           // FOR LOADING DATA ON LOGIN
//           dispatch(loadDoctor())
//           dispatch(loginUserSuccess(res.data.data))
//         }
//       })
//       .catch((err) => {
//         //setAlertState({ show: true, type: "danger", msg: err });
//         console.log(err)
//         dispatch({ type: LOGIN_FAIL, payload: err })
//         //message.error({ content: error.message, key: messageKey });
//       })
//   }
// }

export const initState = () => {

  return (dispatch) => {
    dispatch({type: START_LOADING});

    fetch('https://baniwomen.com/collections/co-ords/products.json?limit=1000', {method: "GET"}).then((res) => {
        return res.json();
    }).then(result => {
        dispatch({type: END_LOADING})
        dispatch({type: INIT_PRODUCT, payload: [...result?.products]})
        dispatch({type: SELECTED_PRODUCT, payload: null})
        dispatch({type: UPDATE_DISCOUNT, payload: 25})
    })
  }
}