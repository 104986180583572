import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Grid, Paper, Typography, Card, CardContent, ImageListItem, ImageList, styled, IconButton, AppBar, Button, Chip, useTheme, Tooltip, Drawer, TextField, CircularProgress } from '@material-ui/core';

import instaLogo from "../../assets/images/instagram.png"
import backLogo from "../../assets/images/back.png"
import wardrobePng from "../../assets/images/search.png";
import voucherPng from "../../assets/images/voucher.png";
import shopCartPng from "../../assets/images/shopping-cart.png";

import makeupIcon from "../../assets/images/makeup.png";
import makeupIconHover from "../../assets/images/makeup-white.png";

import lipstickIcon from "../../assets/images/shirt.png";
import lipstickIconHover from "../../assets/images/t-shirt-white.png";

import flasIcon from "../../assets/images/lightning.png";
import discountAnimation from "../../assets/animationData/Animation 1718645481538.json"

import {useHistory} from "react-router-dom"
import { FavoriteOutlined, FavoriteBorderOutlined, FiberManualRecord, FiberManualRecordOutlined, ArrowForward, FavoriteBorder, Favorite, ShoppingCart, Replay, Edit, FileCopy, FileCopyOutlined } from "@material-ui/icons"

import { colors } from "../../assets/css/colors"
import CustomToast from '../customToast';
import baniWomenLogo from "../../assets/images/Bani_Women_Logo_-_Final.pdf_193_x_60_px.webp";
import creatorPayLogo from "../../assets/images/creatorpaylogo.png";
import { useSelector } from 'react-redux';
import GenericAnimation from '../GenericAnimation';
import giftAnimation from "../../assets/animationData/Animation 1718739004083.json"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    maxHeight: "100%",
    // overflowY: 'auto',
    // position: "absolute",
    // top: 0,
    // left: 0,
    // overflowX: "hidden"
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    position: "relative",
    marginTop: "5px"
  },
  coverPhoto: {
    height: '20%',
    width: '100%',
  },
  cardHeading: {
    color: 'black',
    fontSize: '18px',
    // fontWeight: 'bold',
  },
  profilePhoto: {
    height: '5.2rem',
    width: '5.2rem',
    borderRadius: '50%',
    border: '2px solid #fff',
    position: "absolute",
    top: "2.5rem",
    left: "38%"

  },
  imagesSection: {
    display: 'flex',
    overflowX: 'scroll',
  },
  imageCard: {
    width: '30%',
    minWidth: '100px',
    marginRight: '8px',
  },
}));

const CustomGrid = styled(Grid)({
  // Set a fixed height for the container
  // height: "70vh",
  // Enable scrolling
  overflowY: 'scroll',
  // Hide the scrollbar
  // scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: 1, // Adjust to desired thickness
    height: 0,
    backgroundColor: "transparent",
    // display: "none"
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'black', // Adjust to desired color
    position: "absolute",
  },
});

const ProfilePage = ({isMobile, allProfileData, carouselOpen, setCarouselOpen,  updateWishListItem, setAddItemToast, setRemoveItemToast, openBottomBar, setOpenBottomBar, wishListItem, login, setLogin}) => {
  const classes = useStyles();

  const coverPic = () => {
    const coverPicStyle = {
      textAlign: 'center',
      height: '7.25rem',
      minWidth: '100%',
      borderRadius: '0.5rem',
      backgroundImage: 'url("https://d3g01po1nkka75.cloudfront.net/Cover2.png")',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    };
  
    return (
      <div className="cover-pic-wrapper" style={coverPicStyle}>
        {/* You can add any additional content or components within this div */}
      </div>
    );
  };

  const history = useHistory();

  const curatedVideoRef = useRef([]);
  const videoRef = useRef([]);

  const navBarRef = useRef(null);


  const playVideo = (vRef, vIdx) => {
    try{
      if (vRef.current?.[vIdx]) {
        vRef.current?.[vIdx].play();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const pauseVideo = (vRef, vIdx, check = false) => {
    try{
      if (vRef.current?.[vIdx]) {
        if(!check)
          vRef.current?.[vIdx].pause();
        
        else {
          if(vRef.current?.[vIdx]?.paused)
            vRef.current?.[vIdx].play();
          
          else
            vRef.current?.[vIdx].pause();
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  
  const [curatedMedia, setCuratedMedia] = useState([]);

  const [imagesAndVideos, setImageAndVideos] = useState([]);

  useEffect(() => {
    if(wishListItem?.length && curatedMedia?.length && imagesAndVideos?.length){
      let tempCM = [...curatedMedia].map(v => {
        if(wishListItem?.find(f => f.id === v.id)){
          return {...v, wishlisted: true}
        } else {
          return {...v}
        }
      });
  
      let tempIaV = [...imagesAndVideos].map(v => {
        if(wishListItem?.find(f => f.id === v.id)){
          return {...v, wishlisted: true}
        } else {
          return {...v}
        }
      });
  
      setImageAndVideos([...tempIaV])
      setCuratedMedia([...tempCM])
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const wishlistClick = () => {
    setOpenBottomBar(true)
  }

  const getSpotifyWidget = () => {
    try{
      return (
        <iframe style={{borderRadius:"12px"}} src="https://open.spotify.com/embed/track/5cGBQ9W3XtRuRQFgDklDrI?utm_source=generator" width="100%" height={isMobile ? "80" : "152"} frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
      )
    } catch (err) {
      return (
        <Typography color='error'>
          Content Not Allowed
        </Typography>
      )
    }
  }


  const updateInWishList = (index, tempState, setTempState, type = "add") => {
    switch(type) {
      case "add": {

        let updatedTempState = [...tempState];
        updatedTempState[index].wishlisted = true;

        setTempState([...updatedTempState]);

        updateWishListItem(updatedTempState[index], "add")
        
        break;
      }

      case "remove": {

        let updatedTempState = [...tempState];
        updatedTempState[index].wishlisted = false;

        setTempState([...updatedTempState]);

        updateWishListItem(updatedTempState[index], "removeById", 0, updatedTempState?.[index]?.id)
        
        break;
      }
    }
  }

  const [selectedChip, setSelectedChip] = useState(0)


  useEffect(() => {
    if(allProfileData?.name){
      setImageAndVideos([...allProfileData?.productCollection])
      setCuratedMedia([...allProfileData?.curatedCollection])
    }
  }, [allProfileData])



  return (
    <React.Fragment>

      <StoreNav navBarRef={navBarRef} isMobile={isMobile} wishlistClick={wishlistClick} wishListItemExists={wishListItem?.length} login={login} setLogin={setLogin}
        profileName={allProfileData?.name}
        profilePic={allProfileData?.profilePic}
        allProfileData={allProfileData}
      />

    {/* <div className={classes.root}> */}
      <Grid container justifyContent='center' className={classes.root} style={{paddingLeft: isMobile ? null : 10}}>

        <Grid item xs={12} md={3} container style={{position: "relative"}}>

          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0}>

              
              <Grid container spacing={1} innerRef={navBarRef}>
                {coverPic()}

                <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                  <img
                    src={allProfileData?.profilePic}
                    alt="Profile Photo"
                    className={classes.profilePhoto}
                  />
                </Grid>

                <Grid item container justifyContent='center' style={{paddingTop: "20px", marginTop: "-15px"}}>
                    <Grid item>
                      <Typography variant="h6" style={{fontWeight: "bold"}}>
                        {allProfileData?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item style={{display: "flex", alignItems: "center", marginTop: "5px"}}>
                        <img src={instaLogo} height={15} width={15} style={{marginRight: 5}} /> 
                        <Typography style={{fontSize: "14px"}}>
                          {allProfileData?.followers} Followers
                        </Typography>
                    </Grid>
                    <Grid  item xs={12}></Grid>
                    <Grid item style={{marginTop: 5}}>
                      <Typography style={{fontSize: "14px"}}>
                        Welcome to my Store!
                      </Typography>
                    </Grid>
                </Grid>

                <Grid item style={{position: "absolute", right: 0, top: 0}}>
                  <IconButton onClick={() => !login ? wishlistClick() : history.push(`/${allProfileData?.username}/wishlist`)} style={{position: "relative"}}>
                    <FavoriteBorderOutlined />
                    
                    {wishListItem?.length ? <FiberManualRecord style={{position: "absolute", right: "20%", top: "1%", color: colors.primary, width: 10}} /> : null}

                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper} style={{textAlign: "left", paddingBottom: 10}} elevation={0}>
              <Grid container spacing={1}>
                  <Grid item xs={12} style={{textAlign: "left"}}>
                    <Typography variant="h6" className={classes.cardHeading}>
                      Track
                    </Typography>
                    <Typography variant="subtitle1" style={{fontSize: 14}}>
                      Here is what I am listening these days!
                    </Typography>
                  </Grid>   
                  <Grid item xs={12}>
                    {getSpotifyWidget()}
                  </Grid>         
                </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0}>
              <Grid container spacing={1}>
                <Grid item xs={12} style={{textAlign: "left"}}>
                  <Typography variant="h6" className={classes.cardHeading}>
                    My Curated Collections
                  </Typography>
                  <Typography variant="subtitle1" style={{fontSize: 14}}>
                    Easy to style collections that are must haves this season. Check'em out!
                  </Typography>

                  {/* <Button variant="text" style={{}}>
                    View All
                  </Button> */}
                </Grid>
                <Grid item xs={12}></Grid>

                <CustomGrid item xs={12}>
                  <CustomGrid className={classes.imagesSection}>
                  {[...curatedMedia].map((item, index) => (
                    <div style={{position: "relative", width: "100%", maxWidth: "100%", marginLeft: "10px", marginBottom: "10px"}}>
                      {item?.type === 'image' ? (
                        <img src={item?.src} style={{height: "21rem", width: "12rem", borderRadius: "0.5rem"}} />
                      ) : (
                        <video ref={(ref) => curatedVideoRef.current[item?.videoIndex] = ref} style={{height: "21rem", width: "12rem", borderRadius: "0.5rem"}}
                        // onMouseEnter={() => playVideo(curatedVideoRef, item?.videoIndex)}
                        // onMouseLeave={() => pauseVideo(curatedVideoRef, item?.videoIndex)}
                        onClick={() => pauseVideo(curatedVideoRef, item?.videoIndex, true)}
                        autoPlay muted 
                      >
                          <source src={item?.src} />
                        </video>
                      )}
                      <div style={{position: "absolute", bottom: 0, left: 0, padding: 10, background: "linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1))", width: "90%"}}>
                        <Typography style={{fontSize: "16px", color: "white"}}>
                          {item?.bottomText ?? "Beauty and Skin Products"}
                        </Typography>
                      </div>

                      <div style={{position: "absolute", top: 0, right: 0}}>
                        <IconButton onClick={() => {
                          if(item?.wishlisted) {
                            setRemoveItemToast(true)
                            updateInWishList(index, curatedMedia, setCuratedMedia, "remove")
                          }
                          else {
                            setAddItemToast(true)
                            updateInWishList(index, curatedMedia, setCuratedMedia, "add")
                          }
                        }}>
                          {!item?.wishlisted ? <FavoriteBorderOutlined style={{color: "white"}} /> : <Favorite style={{
                            color: "white"
                          }} />}
                        </IconButton>
                      </div>
                    </div>
                  ))}
                  </CustomGrid>
                </CustomGrid>

              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0}>
              <Grid container spacing={1}>
                <Grid item xs={12} style={{textAlign: "left"}}>
                  <Typography variant="h6" className={classes.cardHeading}>
                    Shop my looks
                  </Typography>
                  <Typography variant="subtitle1" style={{fontSize: 14}}>
                    Get everything that I try-on in my posts and videos
                  </Typography>
                </Grid>
                <Grid item xs={12}></Grid>

                <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                  <Chip label={"All Posts"} variant={selectedChip === 0 ? 'default' : "outlined"} onClick={() => setSelectedChip(0)} style={{background: selectedChip === 0 ? colors.primaryGradient : null, cursor: "pointer", color: selectedChip === 0 ? "white" : null}} color='primary'/>
                  <Chip label={"Cosmetics"} onClick={() => setSelectedChip(1)} avatar={<img src={selectedChip === 1 ? makeupIconHover : makeupIcon}  style={{background: selectedChip === 1 ? "transparent" : "white", height: 20, width: 20, marginLeft: 10}}/>} variant={selectedChip === 1 ? "default" : 'outlined'} style={{marginLeft: 5, background: selectedChip === 1 ? colors.primaryGradient : null}} color='primary'/>
                  <Chip label={"Clothes"}  onClick={() => setSelectedChip(2)} avatar={<img src={selectedChip === 2 ? lipstickIconHover : lipstickIcon} style={{background: selectedChip === 2 ? "transparent" : "white", height: 20, width: 20, marginLeft: 10}}/>} variant={selectedChip === 2 ? "default" : 'outlined'} style={{marginLeft: 5, background: selectedChip === 2 ? colors.primaryGradient : null}} color='primary'/>
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} container spacing={1} alignItems='center'>
                  {[...imagesAndVideos].filter((f, i) => selectedChip ? (selectedChip === 1 && i < 2 ? true : (selectedChip === 2 && i >= 2 ? true : false)) : true ).map((item, index) => (
                    <Grid item xs={12} key={index} style={{position: "relative", width: "100%", maxWidth: "100%", cursor: "pointer"}} onClick={() => history.push(`/${allProfileData?.username}/view/${item?.productId }`)}>
                      {item?.type === 'image' ? (
                        <Paper style={{borderRadius: "1rem", height: "350px"}}>
                          <img src={item?.imageUrl} style={{height: "260px", width: "100%", minWidth: "100%", display: "block", objectFit: "contain"}} />
                          <div style={{padding: 10}}>
                            <Typography style={{fontSize: "14px", color: "black"}}>
                              {item?.name}
                            </Typography>
                            <div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                              <Typography style={{fontSize: "14px", color: "black"}}>
                              ₹ {item?.discountedPrice}
                              </Typography>
                              <Typography variant="h5" style={{fontSize: "11px", marginLeft: 5, opacity: 0.5, textDecoration: "line-through"}}>₹ {item?.price} </Typography>
                              <Typography variant="h5" style={{fontSize: "11px", marginLeft: 5, color: colors.secondary}}>({item?.offerPercentage}% OFF)</Typography>
                            </div>
                          </div>
                        </Paper>
                      ) : (
                        <video ref={(ref) => videoRef.current[item?.videoIndex] = ref} style={{height: "350px", width: "100%", borderRadius: "1rem", display: "block", objectFit: "cover"}} 
                          // onMouseEnter={() => playVideo(videoRef, item?.videoIndex)}
                          // onMouseLeave={() => pauseVideo(videoRef, item?.videoIndex)}
                        onClick={() => pauseVideo(videoRef, item?.videoIndex, true)}

                          autoPlay muted 
                        >
                          <source src={item?.src} />
                        </video>
                      )}
                      {item?.type === 'video' ? <div style={{position: "absolute", bottom: "1%", padding: "2.5%", left: "1%", borderRadius: "0 0 1rem 1rem", background: "linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1))", width: "92.7%" }}>
                        <Typography style={{fontSize: "16px", color: "white"}}>
                          Beauty and Skin Products
                        </Typography>
                      </div> : null}

                      <div style={{position: "absolute", top: 0, right: 0}}>
                        <IconButton onClick={(event) => {
                          event.stopPropagation();
                          if(item?.wishlisted){
                            setRemoveItemToast(true) 
                            updateInWishList(index, imagesAndVideos, setImageAndVideos, "remove")
                          }
                          else {
                            setAddItemToast(true)
                            updateInWishList(index, imagesAndVideos, setImageAndVideos, "add")
                          }
                        }}>
                          {!item?.wishlisted ? <FavoriteBorderOutlined style={{color: item?.type === 'image' ? "black" : "white"}} /> :  <Favorite style={{color: item?.type === 'image' ? "black" : "white"}} /> }
                        </IconButton>
                      </div>
                  </Grid>
        
                  ))}
                </Grid>

                <Grid item xs={12} container justifyContent='center'>
                  <Grid item style={{display: "flex", alignItems: "center", marginTop: 5}}>
                    <img src={flasIcon} style={{height: "15px"}}/>
                    <Typography variant="caption">
                      Powered by 
                    </Typography>
                    <span style={{
                          // color: "#4D63DD",
                          background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent', 
                          fontWeight: "bold",
                          marginLeft: 3,
                          fontSize: "13px"
                          }}>
                          <i>Paperplane</i></span>
                    {/* <img src={paperplaneLogo} style={{height: "auto", width: "70px", marginLeft: "5px"}} /> */}
                  </Grid>
                </Grid>

              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <BottomBar isMobile={isMobile} open={openBottomBar} setOpen={setOpenBottomBar} setLogin={setLogin} allProfileData={allProfileData}/>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <CarouselBar isMobile={isMobile} isBottomBarOpen={carouselOpen} setIsBottomBarOpen={setCarouselOpen} />
          </Grid>
        </Grid>



      </Grid>

    {/* </div> */}
    </React.Fragment>
  );
};

export default ProfilePage;



export const StoreNav = ({navBarRef, defaultView = false, isMobile, wishlistClick, headerText, login, profilePic = "", profileName = "", allProfileData}) => {

  const classes = useStyles()
  const history = useHistory()

  const wishListItemExists = useSelector(state => state?.auth?.cartItems?.length)

  const [stickBar, setStickBar] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio < 0.001) {
          // This code will run when the component is scrolled out of view
          console.log("Component has scrolled out of view");
          setStickBar(true);
          // Add your code here to perform an action when the component is out of view
        } else {
          setStickBar(false);
        }
      });
    });

    // Start observing the target element (your component's DOM element)
    if(navBarRef){
      if (navBarRef.current) {
        observer.observe(navBarRef.current);
      }
  
      // Clean up the observer when the component unmounts
      return () => {
        if (navBarRef.current) {
          observer.unobserve(navBarRef.current);
        }
      };
    }
  }, []);

  return (
    <Grid container justifyContent='center' style={{pointerEvents: defaultView ? null : (!stickBar ? "none" : null)}}>
        <Grid item xs={12} md={3} style={{position: "relative !important"}}>
          <AppBar elevation={1} style={{
            position: defaultView ? "sticky" : "fixed",
            opacity: defaultView || stickBar ? 1: 0,
            height: defaultView || stickBar ? "auto": 0,
            transition: "opacity 0.3s ease",
            backgroundColor: "white",
            width: isMobile || defaultView ? "auto" : "25%",
            top: 0,
            left: isMobile || defaultView ? 0  : "38%",
          }}>
            <Paper className={classes.paper} elevation={0} style={{marginTop: "-5px"}}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item style={{display: "flex", alignItems: "center"}}>
                  {defaultView ? <div style={{marginRight: 5, cursor: "pointer"}} onClick={() => history.push("/")}>
                    <img src={backLogo} height={16} width={18} />
                  </div> : null}

                  {!headerText ? 
                    <Grid item xs={12} container justifyContent="left">
                        <Grid item>
                            <img src={baniWomenLogo} style={{width: "140px", objectFit: "contain"}} />
                        </Grid>
                        {/* <Grid item xs={12}></Grid> */}
                        {/* <Grid item>
                            <Typography style={{marginTop: "-10px", fontSize: "14px", display: "flex", alignItems: "center"}}>
                                Powered by <img src={creatorPayLogo} style={{width: "100px", marginLeft: "10px", objectFit: "contain"}} />
                            </Typography>
                        </Grid> */}
                    </Grid>
                  : 
                  <Typography style={{fontSize: "18px", alignItems: "center", color: "black", marginLeft: 5, marginBottom: 2}}>
                    {headerText}
                  </Typography>
                  }
                </Grid>


                {wishlistClick ? <Grid item xs={12} style={{position: "absolute", right: 0, top: "20%"}}>
                  <Button variant='text' startIcon={<ShoppingCart style={{fontSize: "15px", position: "relative"}}/>} 
                  onClick={() => !true ? wishlistClick() : history.push(`/cart`)}
                  >
                    <Typography style={{fontSize: "14px"}}>
                      My Cart
                    </Typography>

                    {wishListItemExists ? <Typography style={{position: "absolute", right: 0, top: 0, fontWeight: 'bold', color: "black", fontSize: '10px'}}>{wishListItemExists}</Typography> : null}
                  </Button> 
                </Grid> : null}
              </Grid>
            </Paper>

          </AppBar>
        </Grid>
      </Grid>
  )
}



export function BottomBar({isMobile, open, setOpen, setLogin, onLogin = null, allProfileData}) {
  const [isBottomBarOpen, setIsBottomBarOpen] = useState(true);
  const [mobile, setMobile] = useState("");

  const history = useHistory();
  const selectedProduct = useSelector(state => state?.auth?.selectedProduct)

  useEffect(() => {
    setIsBottomBarOpen(open);
  }, [String(open)])

  const classes = useStyles();

  const touchStartY = useRef(0);

  const addSwipeListeners = (event) => {
    touchStartY.current = event?.touches?.[0]?.clientY;
  };

  const removeSwipeListeners = (event) => {
    
    // const endY = event?.changedTouches?.[0]?.clientY;
    // try{
    //   const deltaY = endY - touchStartY?.current;
    //   if (deltaY > 50) {
    //     // setIsBottomBarOpen(false);
    //     setStepper(0)
    //     // if(setOpen)
    //     //   setOpen(false)
    //   }
    // } catch (err) {
    //   console.log("..", err);
    // }
  };

  const [loader, setLoader] = useState(false)
  const [error, setError] = useState(false)

  const [stepper, setStepper] = useState(0)



  const handleLogin = () => {

    if(mobile?.length !== 10) {
      return null
    }

    setLoader(true);
    if(stepper === 0){
      fetch(`https://api.creatorcheckout.com/api/v1/creator/send-otp?mobile_no=${mobile}&otp_type=STORE_FRONT_OTP`, {method: "GET"}).then((res) => {
          return res.json();
      }).then(result => {
          setLoader(false)
          setStepper(1)
      })
    } else if(stepper === 1) {
      fetch(`https://api.creatorcheckout.com/api/v1/creator/check-otp`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phoneNo: mobile,
          otp: otp.join("")
        })
      }).then((res) => {
          return res.json();
      }).then(result => {
        setLoader(false);
        if(result.success){
          setStepper(2)
          // setTimeout(() => {
          //   setLogin(mobile)
          // }, 3000)
        } else {
          setError(true)
        }
      }).catch(err => console.log(err))
    } else if(stepper === 2)
      setLogin(mobile)
      setLoader(false)
  }

  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [timerRef, setTimerRef] = useState(60)
  const otpRef = useRef([])

  useEffect(() => {
    if(stepper === 1){
      const intervalId = setInterval(() => {
        if(timerRef > 0)
          setTimerRef(prev => prev - 1)
      }, 1000); // 4000 milliseconds = 4 seconds
  
      // Cleanup the interval on component unmount
      return () => clearInterval(intervalId);
    } else if(stepper === 2){
      setTimerRef(10)
      const intervalId = setInterval(() => {
        setTimerRef(prev => {
          if(prev === 0){
            setLogin(mobile)
          }
          return prev - 1
        })
        // if(timerRef <= 0) {
        //   setLogin(mobile)
        // }
      }, 1000); // 4000 milliseconds = 4 seconds
  
      // Cleanup the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [stepper])

  const handleInputChange = (index) => (event) => {

    const value = event.target.value;

    // Perform a regex check for numbers only
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value ? value[0] : value;
      setOtp(newOtp);

      // Move focus to the next input field
      if (value !== '' && otpRef.current[index+1]) {
        otpRef.current[index+1].focus();
      }
    }
  };


  return (
    <React.Fragment>

      {isBottomBarOpen ? <><div style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.7)",
        zIndex: 9999,
        display: "block",
        cursor: "not-allowed"
      }}></div>

      {false ? <div style={{
        position: 'fixed',
        zIndex: 9999999,
        top:0,
        right: "0%",
        bottom: "40%",
        width: "100%",
        height: "100%"
      }}>
        <Grid container justifyContent="center" style={{marginTop: isMobile ? "5%" : "5%"}}>
          <Grid item>
            <GenericAnimation animationData={discountAnimation} width={300} height={300}/>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item>
            <Typography style={{color: "white", maxWidth: "80%", textAlign: "center", fontWeight: "bold", marginLeft: "10%", fontSize: "16px"}}>
              Congrats! Prices now down by 35% ✨
            </Typography>
          </Grid>
        </Grid>
      </div> : null}

      <div 
      onTouchStart={addSwipeListeners} onTouchEnd={removeSwipeListeners}
       style={{height: "auto", background: "white", zIndex: 999999, position: "fixed", bottom: 0, borderRadius: "20px 20px 0px 0px", width: isMobile ? "90%": "22%", 
        padding: isMobile ? "5%" : "1.4%",
        transition: "height 2s"
        }}>
        <Grid container spacing={1}>
          <Grid item xs={12} container justifyContent='center'>
            <Grid item>
              <div style={{width: "50px", height: "10px", backgroundColor: "grey", opacity: "20%", borderRadius: "5px", cursor: "pointer"}} onClick={() => {
                setStepper(0)
                if(setOpen)
                  setOpen(false)
                // setIsBottomBarOpen(false)
                }}></div>
            </Grid>
          </Grid>
          {stepper <= 1 ? <Grid item xs={12}>
            <GenericAnimation animationData={giftAnimation} style={{marginBottom: "-15%"}}/>
          </Grid>  : <Grid item xs={12}>
            <GenericAnimation animationData={discountAnimation} style={{marginBottom: "-5%"}}/>
            </Grid>}
          {stepper <= 1 ? <React.Fragment>

            <Grid item xs={12}>
              <Typography style={{fontSize: "16px", fontWeight: "bold"}}>
                {/* Login to your Paperplane account */}
                {/* Hey, we’re loving your taste 😍, give us your phone number to send personalised offers you’ll love! 💯 */}
                {/* Hey, we think you absolutely deserve an EXTRA 10% OFF 😍 */}
                Yay! you just unlocked EXTRA 10% OFF 🥳 
              </Typography>
              {/* <Typography style={{fontSize: "14px", marginTop: "10px"}}>
                Please enter your phone number to continue.
              </Typography> */}
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              {stepper === 0 ? (
              <React.Fragment>
                <div style={{marginTop: "-2%", marginBottom: "10px"}}>
                  <Typography style={{fontSize: "14px", color: "grey"}}>
                    {/* Enter your phone nuwidth={300} height={300}mber to claim your discount!  */}
                    Enter your WhatsApp number to claim
                  </Typography>
                </div>
                <TextField variant="standard" size='small' inputProps={{style: {fontSize: "12px"}, maxLength: 10}} value={mobile} onChange={(event) => {
                  const inputValue = event.target.value;
                  const numericValue = inputValue.replace(/\D/g, '');
                  setMobile(numericValue)
                }} placeholder='Enter 10 digit number' />
              </React.Fragment>
              ) : (
                <React.Fragment>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <Typography style={{fontSize: "14px", color: "black"}}>
                      Enter OTP
                    </Typography>
                    <Button variant='text' disabled={timerRef > 0} style={{color: timerRef > 0 ? "grey" : 'black',  marginLeft: "auto", padding: 0}} startIcon={<Replay />}
                      onClick={() => {
                        setTimerRef(60);
                        fetch(`https://api.creatorcheckout.com/api/v1/creator/send-otp?mobile_no=${mobile}&otp_type=STORE_FRONT_OTP`, {method: "GET"}).then((res) => {
                              return res.json();
                          })
                      }}
                    >
                      Resend {timerRef === 60 ? "01:00" : (timerRef > 0 ? (timerRef > 9 ? `00:${timerRef}` : `00:0${timerRef}`) : '')}
                    </Button>
                  </div>
                  {otp.map((value, index) => (
                        <TextField
                        key={index}
                        variant="outlined"
                        error={error}
                        size="small"
                        margin="normal"
                        // focused={index===0}
                        autoFocus={index===0}
                        style={{width: "12%", marginRight: "10px"}}
                        inputRef={(ref) => otpRef.current[index] = ref}
                        type={isMobile ? "number": "text"}
                        value={value}
                        onChange={handleInputChange(index)}
                        inputProps={{
                            maxLength: 1,
                            style: { textAlign: 'center' },
                        }}
                        />
                    ))}
                  <div>
                    <Button variant="text" onClick={() => {
                      setStepper(0)
                      setTimerRef(60)
                      }} style={{padding: 0, fontSize: "12px",color: "black", marginTop: "10px", marginBottom: -5}} startIcon={<Edit />}>
                      Edit mobile +91-{mobile}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
                <Button variant='contained' fullWidth style={{borderRadius: "20px", background: colors.primary, color: "black"}} disableElevation onClick={() => {
                  handleLogin()
                }}
                endIcon={loader ? <CircularProgress style={{color: "white", height: 18, width: 18}} /> : null}
                >
                    {stepper === 0 ? "Get OTP" : "Submit"}
                </Button>
            </Grid>
          </React.Fragment> : (<React.Fragment>
            <Grid item xs={12}>
              <Typography style={{fontSize: "16px", fontWeight: "bold"}}>
                {/* Login to your Paperplane account */}
                {/* Hey, we’re loving your taste 😍, give us your phone number to send personalised offers you’ll love! 💯 */}
                {/* Hey, we think you absolutely deserve an EXTRA 10% OFF 😍 */}
                {/* Redirecting to Bani women… <br /> */}

                Use <span style={{backgroundColor: "whitesmoke", padding: 5, cursor: "pointer"}} onClick={() => navigator.clipboard.writeText("CREATORPAY35")}>
                      CREATORPAY35 <IconButton size={"small"} style={{color: 'black'}} onClick={() => navigator.clipboard.writeText("CREATORPAY35")}> <FileCopyOutlined style={{fontSize: "14px"}}/> </IconButton>
                  </span> at checkout for applying discount. 
              </Typography>
              {/* <Typography style={{fontSize: "14px", marginTop: "10px"}}>
                Please enter your phone number to continue.
              </Typography> */}
            </Grid>
            <Grid item xs={12}>
              <Typography style={{fontSize: "14px", color: 'grey'}}>
                Redirecting to Bani women - {timerRef > 0 ? timerRef : 0}s
              </Typography>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
                <Button variant='contained' fullWidth style={{borderRadius: "20px", background: colors.primary, color: "black"}} disableElevation onClick={() => {
                  handleLogin()
                }}
                endIcon={loader ? <CircularProgress style={{color: "white", height: 18, width: 18}} /> : null}
                >
                    Copy & Redirect
                </Button>
            </Grid>
          </React.Fragment>)}  
          {/* <Grid item xs={12}></Grid> */}
          {stepper <= 1 ? <Grid item xs={12}>
            {/* <Typography style={{textAlign: "center", marginBottom: "5px", fontSize: "12px", marginTop: "-10px"}}>
              OR
            </Typography> */}
            <Button variant='text' onClick={() => {
              window.open(`https://baniwomen.com/products/${selectedProduct?.handle}?utm_source=creator-pay&utm_medium=creator-pay&utm_campaign=creator-pay&utm_id=edgyetika&utm_term=creator-pay&utm_content=creator-pay-bani-women`, "_self")
            }} style={{color: "#4D63DD", textDecoration: "underline", borderRadius: "20px"}} fullWidth>
              Skip & continue without discount
            </Button>
          </Grid> : null}
        </Grid>
      </div></> : null}
    </React.Fragment>
  );
}



export function CarouselBar({isMobile, isBottomBarOpen, setIsBottomBarOpen}) {

  const classes = useStyles();

  const touchStartY = useRef(0);

  const addSwipeListeners = (event) => {
    touchStartY.current = event?.touches?.[0]?.clientY;
  };

  const removeSwipeListeners = (event) => {
    
    const endY = event?.changedTouches?.[0]?.clientY;
    try{
      const deltaY = endY - touchStartY?.current;
      if (deltaY > 50) {
        setIsBottomBarOpen(false);
      }
    } catch (err) {
      console.log("..", err);
    }
  };


  const carouselContent = [
    {
      "image": wardrobePng,
      "heading": "Get product links from influencer's content",
      "subHeading": "Easily find links of looks and products recommended by top influencers"
    },
    {
      "image": voucherPng,
      "heading": "Get coupon codes, offers and deals",
      "subHeading": "Receive price drop alerts and coupons on your favourite products on Whatsapp"
    },
    {
      "image": shopCartPng,
      "heading": "Wishlist and save products for later",
      "subHeading": "Login to create your wishlist and refer it anytime directly via Whatsapp"
    }
  ]

  const touchStart = useRef(null);
  const touchEnd = useRef(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    touchEnd.current = null;
    touchStart.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e) => {
    touchEnd.current = e.targetTouches[0].clientX;
  };

  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return;
    const distance = touchStart.current - touchEnd.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe)
      // console.log('swipe', isLeftSwipe ? 'left' : 'right');
      if(isLeftSwipe){
        setSelectedIndex((prevState) => {
          prevState = (prevState < carouselContent.length - 1 ? prevState + 1 : 0);
          return prevState
        })
      } else {
        setSelectedIndex((prevState) => {
          prevState = (prevState > 0 ? prevState - 1 : carouselContent.length - 1);
          return prevState
        })
      }
    //
  }
  
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [autoUpdate, setAutoUpdate] = useState("0")
  useEffect(() => {
    if(isBottomBarOpen) {
      setTimeout(() => {
        setSelectedIndex((prevState) => {
          prevState = (prevState < carouselContent.length - 1 ? prevState + 1 : 0);
          return prevState
        })
        setAutoUpdate(autoUpdate+"0")
      }, 5000)
    }
  }, [isBottomBarOpen, autoUpdate])


  return (
    <React.Fragment>

      {isBottomBarOpen ? <><div style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 99999,
        pointerEvents: "none"
      }}></div>

      <div 
      onTouchStart={addSwipeListeners} onTouchEnd={removeSwipeListeners}
       style={{height: "auto", background: colors?.primaryGradient, zIndex: 999999, position: "fixed", bottom: 0, borderRadius: "20px 20px 0px 0px", width: isMobile ? "90%": "22%", 
        padding: isMobile ? "5%" : "1.4%",
        transition: "height 2s"
        }}>
        <Grid container spacing={1}>
          <Grid item xs={12} container justifyContent='center'>
            <Grid item>
              <div style={{width: "50px", height: "10px", backgroundColor: "white", borderRadius: "5px", cursor: "pointer"}} onClick={() => {
                setIsBottomBarOpen(false)
                }}></div>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}></Grid> */}
          <Grid item xs={12}>
            <Typography style={{fontSize: "16px", fontWeight: "bold", textAlign: "center", color: "white"}}>
              Hey hi! Welcome to Paperplane
            </Typography>
          </Grid>
          {/* <Grid item xs={12}></Grid> */}
          <Grid onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} onTouchMove={onTouchMove} item container xs={12}>
            <Grid item style={{marginTop: 10, marginBottom: 10}}>
              <img src={carouselContent[selectedIndex]?.image} style={{height: "80px", width: "80px"}} />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={10}>
              <Typography style={{fontSize: "18px", fontWeight: "bold", textAlign: "left", color: "white"}}>
                {carouselContent[selectedIndex]?.heading}
              </Typography>

              <Typography style={{fontSize: "14px", textAlign: "left", color: "white", marginTop: 5}}>
                {carouselContent[selectedIndex]?.subHeading}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}></Grid> */}
          <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                {[0, 1, 2].map(v => (
                  v === selectedIndex ? <FiberManualRecord style={{color: "white"}} /> : <FiberManualRecordOutlined style={{color: "white"}} />
                ))}
          </Grid>
          {/* <Grid item xs={12}></Grid> */}
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
              <Button variant='contained' fullWidth style={{borderRadius: "20px", background: "white"}} disableElevation endIcon={<ArrowForward color='primary'/>} onClick={() => setIsBottomBarOpen(false)}>
                  <span style={{
                    background: colors.primaryGradient,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent', 
                  }}>
                    Get me in
                  </span>
              </Button>
          </Grid>
          <Grid item xs={12}>
              <Button variant='text' fullWidth style={{borderRadius: "20px", color: "white", marginBottom: "-4%"}} disableElevation onClick={() => setIsBottomBarOpen(false)}>
                  Skip for now
              </Button>
          </Grid>
        </Grid>
      </div></> : null}
    </React.Fragment>
  );
}
