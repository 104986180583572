export const reelProducts = [
   {
          "id": 9114705920288,
          "title": "Bani Women Womens Printed Midi Dress",
          "body_html": "",
          "vendor": "CHESHTAA APPARELS INDIA PVT. LTD.",
          "product_type": "Dress",
          "created_at": "2024-03-26T10:49:11+05:30",
          "handle": "bani-women-womens-printed-midi-dress-1",
          "updated_at": "2024-06-18T02:47:03+05:30",
          "published_at": "2024-03-26T10:49:11+05:30",
          "template_suffix": "",
          "published_scope": "web",
          "tags": "Dress, Fashion, Floral, Printed, Rayon/Liva",
          "variants": [
            {
              "id": 47923244400928,
              "product_id": 9114705920288,
              "title": "S",
              "price": "1499.00",
              "sku": "BW7078GRN_DRS-1",
              "position": 2,
              "compare_at_price": "2899.00",
              "fulfillment_service": "manual",
              "inventory_management": "shopify",
              "option1": "S",
              "option2": null,
              "option3": null,
              "created_at": "2024-03-26T10:49:14+05:30",
              "updated_at": "2024-06-18T02:47:03+05:30",
              "taxable": true,
              "barcode": "True",
              "grams": 0,
              "image_id": null,
              "weight": 0,
              "weight_unit": "kg",
              "requires_shipping": true
            },
            {
              "id": 47923244433696,
              "product_id": 9114705920288,
              "title": "M",
              "price": "1499.00",
              "sku": "BW7078GRN_DRS-2",
              "position": 3,
              "compare_at_price": "2899.00",
              "fulfillment_service": "manual",
              "inventory_management": "shopify",
              "option1": "M",
              "option2": null,
              "option3": null,
              "created_at": "2024-03-26T10:49:14+05:30",
              "updated_at": "2024-06-18T02:47:03+05:30",
              "taxable": true,
              "barcode": "",
              "grams": 0,
              "image_id": null,
              "weight": 0,
              "weight_unit": "kg",
              "requires_shipping": true
            },
            {
              "id": 47923244466464,
              "product_id": 9114705920288,
              "title": "L",
              "price": "1499.00",
              "sku": "BW7078GRN_DRS-3",
              "position": 4,
              "compare_at_price": "2899.00",
              "fulfillment_service": "manual",
              "inventory_management": "shopify",
              "option1": "L",
              "option2": null,
              "option3": null,
              "created_at": "2024-03-26T10:49:14+05:30",
              "updated_at": "2024-06-18T02:47:03+05:30",
              "taxable": true,
              "barcode": "",
              "grams": 0,
              "image_id": null,
              "weight": 0,
              "weight_unit": "kg",
              "requires_shipping": true
            },
            {
              "id": 47923244499232,
              "product_id": 9114705920288,
              "title": "XL",
              "price": "1499.00",
              "sku": "BW7078GRN_DRS-4",
              "position": 5,
              "compare_at_price": "2899.00",
              "fulfillment_service": "manual",
              "inventory_management": "shopify",
              "option1": "XL",
              "option2": null,
              "option3": null,
              "created_at": "2024-03-26T10:49:14+05:30",
              "updated_at": "2024-06-18T02:47:03+05:30",
              "taxable": true,
              "barcode": "",
              "grams": 0,
              "image_id": null,
              "weight": 0,
              "weight_unit": "kg",
              "requires_shipping": true
            },
            {
              "id": 47923244532000,
              "product_id": 9114705920288,
              "title": "XXL",
              "price": "1499.00",
              "sku": "BW7078GRN_DRS-5",
              "position": 6,
              "compare_at_price": "2899.00",
              "fulfillment_service": "manual",
              "inventory_management": "shopify",
              "option1": "XXL",
              "option2": null,
              "option3": null,
              "created_at": "2024-03-26T10:49:14+05:30",
              "updated_at": "2024-06-18T02:47:03+05:30",
              "taxable": true,
              "barcode": "",
              "grams": 0,
              "image_id": null,
              "weight": 0,
              "weight_unit": "kg",
              "requires_shipping": true
            }
          ],
          "options": [
            {
              "id": 11473541595424,
              "product_id": 9114705920288,
              "name": "Size",
              "position": 1,
              "values": [
                "S",
                "M",
                "L",
                "XL",
                "XXL"
              ]
            }
          ],
          "images": [
            {
              "id": 44829754818848,
              "product_id": 9114705920288,
              "position": 1,
              "created_at": "2024-03-26T10:49:11+05:30",
              "updated_at": "2024-03-26T10:49:14+05:30",
              "alt": null,
              "width": 1500,
              "height": 2000,
              "src": "https://storage.googleapis.com/website-paperplane/IMG_2619.JPG",
              "variant_ids": []
            },
            {
              "id": 44829754720544,
              "product_id": 9114705920288,
              "position": 2,
              "created_at": "2024-03-26T10:49:11+05:30",
              "updated_at": "2024-03-26T10:49:13+05:30",
              "alt": null,
              "width": 1500,
              "height": 2000,
              "src": "https://storage.googleapis.com/website-paperplane/IMG_2626.JPG",
              "variant_ids": []
            },
            {
              "id": 44829755146528,
              "product_id": 9114705920288,
              "position": 3,
              "created_at": "2024-03-26T10:49:11+05:30",
              "updated_at": "2024-03-26T10:49:15+05:30",
              "alt": null,
              "width": 1500,
              "height": 2000,
              "src": "https://storage.googleapis.com/website-paperplane/IMG_2624.JPG",
              "variant_ids": []
            },
            {
                "id": 44829754818848,
                "product_id": 9114705920288,
                "position": 1,
                "created_at": "2024-03-26T10:49:11+05:30",
                "updated_at": "2024-03-26T10:49:14+05:30",
                "alt": null,
                "width": 1500,
                "height": 2000,
                "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7078GRN_DRS-1_0b6f2c52-3db8-4ce8-a2ca-49594e6e5cbf.jpg?v=1711430354",
                "variant_ids": []
              },
              {
                "id": 44829754720544,
                "product_id": 9114705920288,
                "position": 2,
                "created_at": "2024-03-26T10:49:11+05:30",
                "updated_at": "2024-03-26T10:49:13+05:30",
                "alt": null,
                "width": 1500,
                "height": 2000,
                "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7078GRN_DRS-2_050b3ac3-297b-4ade-8725-4143e1e3d26e.jpg?v=1711430353",
                "variant_ids": []
              },
              {
                "id": 44829755146528,
                "product_id": 9114705920288,
                "position": 3,
                "created_at": "2024-03-26T10:49:11+05:30",
                "updated_at": "2024-03-26T10:49:15+05:30",
                "alt": null,
                "width": 1500,
                "height": 2000,
                "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7078GRN_DRS-3_63e8643f-a0e1-475a-8cb6-885dcc9adea2.jpg?v=1711430355",
                "variant_ids": []
              },
              {
                "id": 44829755080992,
                "product_id": 9114705920288,
                "position": 4,
                "created_at": "2024-03-26T10:49:11+05:30",
                "updated_at": "2024-03-26T10:49:15+05:30",
                "alt": null,
                "width": 1500,
                "height": 2000,
                "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7078GRN_DRS-4_d27c4d9c-4e7f-45ac-be6f-f7cfbfc1f194.jpg?v=1711430355",
                "variant_ids": []
              },
              {
                "id": 44829754884384,
                "product_id": 9114705920288,
                "position": 5,
                "created_at": "2024-03-26T10:49:11+05:30",
                "updated_at": "2024-03-26T10:49:14+05:30",
                "alt": null,
                "width": 1500,
                "height": 2000,
                "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7078GRN_DRS-5_eaa22685-ef86-4393-b1da-d03c0fc23dde.jpg?v=1711430354",
                "variant_ids": []
              },
              {
                "id": 44829754786080,
                "product_id": 9114705920288,
                "position": 6,
                "created_at": "2024-03-26T10:49:11+05:30",
                "updated_at": "2024-03-26T10:49:13+05:30",
                "alt": null,
                "width": 1500,
                "height": 2000,
                "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7078GRN_DRS-6_8f3452c6-51ec-4e65-9c2b-9b0904933d4e.jpg?v=1711430353",
                "variant_ids": []
              },
              {
                "id": 44829754949920,
                "product_id": 9114705920288,
                "position": 7,
                "created_at": "2024-03-26T10:49:11+05:30",
                "updated_at": "2024-03-26T10:49:14+05:30",
                "alt": null,
                "width": 1500,
                "height": 2000,
                "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7078GRN_DRS-7_96f3a7be-de5c-49aa-aec6-ec38dd156296.jpg?v=1711430354",
                "variant_ids": []
              }
          ],
          "image": {
            "id": 44829754818848,
            "product_id": 9114705920288,
            "position": 1,
            "created_at": "2024-03-26T10:49:11+05:30",
            "updated_at": "2024-03-26T10:49:14+05:30",
            "alt": null,
            "width": 1500,
            "height": 2000,
            "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7078GRN_DRS-1_0b6f2c52-3db8-4ce8-a2ca-49594e6e5cbf.jpg?v=1711430354",
            "variant_ids": []
          },
          "reviews": [
            {
              src: "https://storage.googleapis.com/website-paperplane/image%20549.png",
              alt: "Rashmika",
              comment: "Dress is exactly as shown in the reel 😍"
            },
            {
              src: "https://storage.googleapis.com/website-paperplane/image%20550.png",
              alt: "Vani",
              comment: "Dress feels premium and comfortable. 100% recommend 💯"
            },
            {
              src: "https://storage.googleapis.com/website-paperplane/image%20551.png",
              alt: "Bhumi",
              comment: "Loved it. superb!! 👌"
            },
          ]
        },
         {
          "id": 9004637028640,
          "title": "Bani Women Off-Shoulder Womens Floral Printed Dress Brown",
          "body_html": "",
          "vendor": "Bani Women",
          "product_type": "Dress",
          "created_at": "2024-02-05T18:04:52+05:30",
          "handle": "bani-women-off-shoulder-womens-floral-printed-dress-brown",
          "updated_at": "2024-06-18T02:47:36+05:30",
          "published_at": "2024-02-05T18:04:50+05:30",
          "template_suffix": null,
          "published_scope": "web",
          "tags": "Dress",
          "variants": [
            {
              "id": 47551944458528,
              "product_id": 9004637028640,
              "title": "S",
              "price": "1299.00",
              "sku": "BW6092BRN_DRS-S",
              "position": 1,
              "compare_at_price": "2599.00",
              "fulfillment_service": "manual",
              "inventory_management": null,
              "option1": "S",
              "option2": null,
              "option3": null,
              "created_at": "2024-02-05T18:04:52+05:30",
              "updated_at": "2024-06-18T02:47:36+05:30",
              "taxable": true,
              "barcode": "TRUE",
              "grams": 200,
              "image_id": null,
              "weight": 0.2,
              "weight_unit": "kg",
              "requires_shipping": true
            },
            {
              "id": 47551944425760,
              "product_id": 9004637028640,
              "title": "M",
              "price": "1299.00",
              "sku": "BW6092BRN_DRS-M",
              "position": 2,
              "compare_at_price": "2599.00",
              "fulfillment_service": "manual",
              "inventory_management": null,
              "option1": "M",
              "option2": null,
              "option3": null,
              "created_at": "2024-02-05T18:04:52+05:30",
              "updated_at": "2024-06-18T02:47:36+05:30",
              "taxable": true,
              "barcode": "TRUE",
              "grams": 200,
              "image_id": null,
              "weight": 0.2,
              "weight_unit": "kg",
              "requires_shipping": true
            },
            {
              "id": 47551944392992,
              "product_id": 9004637028640,
              "title": "L",
              "price": "1299.00",
              "sku": "BW6092BRN_DRS-L",
              "position": 3,
              "compare_at_price": "2599.00",
              "fulfillment_service": "manual",
              "inventory_management": null,
              "option1": "L",
              "option2": null,
              "option3": null,
              "created_at": "2024-02-05T18:04:52+05:30",
              "updated_at": "2024-06-18T02:47:36+05:30",
              "taxable": true,
              "barcode": "TRUE",
              "grams": 200,
              "image_id": null,
              "weight": 0.2,
              "weight_unit": "kg",
              "requires_shipping": true
            },
            {
              "id": 47551944491296,
              "product_id": 9004637028640,
              "title": "XL",
              "price": "1299.00",
              "sku": "BW6092BRN_DRS-XL",
              "position": 4,
              "compare_at_price": "2599.00",
              "fulfillment_service": "manual",
              "inventory_management": null,
              "option1": "XL",
              "option2": null,
              "option3": null,
              "created_at": "2024-02-05T18:04:52+05:30",
              "updated_at": "2024-06-18T02:47:36+05:30",
              "taxable": true,
              "barcode": "TRUE",
              "grams": 200,
              "image_id": null,
              "weight": 0.2,
              "weight_unit": "kg",
              "requires_shipping": true
            },
            {
              "id": 47551944524064,
              "product_id": 9004637028640,
              "title": "XXL",
              "price": "1299.00",
              "sku": "BW6092BRN_DRS-XXL",
              "position": 5,
              "compare_at_price": "2599.00",
              "fulfillment_service": "manual",
              "inventory_management": null,
              "option1": "XXL",
              "option2": null,
              "option3": null,
              "created_at": "2024-02-05T18:04:52+05:30",
              "updated_at": "2024-06-18T02:47:36+05:30",
              "taxable": true,
              "barcode": "TRUE",
              "grams": 200,
              "image_id": null,
              "weight": 0.2,
              "weight_unit": "kg",
              "requires_shipping": true
            }
          ],
          "options": [
            {
              "id": 11347941720352,
              "product_id": 9004637028640,
              "name": "Size",
              "position": 1,
              "values": [
                "S",
                "M",
                "L",
                "XL",
                "XXL"
              ]
            }
          ],
          "images": [
            {
              "id": 44196034674976,
              "product_id": 9004637028640,
              "position": 1,
              "created_at": "2024-02-05T18:04:52+05:30",
              "updated_at": "2024-02-05T18:04:52+05:30",
              "alt": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW6092BRN_DRS-3.jpg?v=1707130487",
              "width": 1080,
              "height": 1440,
              "src": "https://storage.googleapis.com/website-paperplane/IMG_2623.JPG",
              "variant_ids": []
            },
            {
              "id": 44205775749408,
              "product_id": 9004637028640,
              "position": 2,
              "created_at": "2024-02-06T17:37:22+05:30",
              "updated_at": "2024-02-06T17:37:24+05:30",
              "alt": null,
              "width": 1080,
              "height": 1440,
              "src": "https://storage.googleapis.com/website-paperplane/IMG_2622.JPG",
              "variant_ids": []
            },
            {
              "id": 44205776273696,
              "product_id": 9004637028640,
              "position": 3,
              "created_at": "2024-02-06T17:37:22+05:30",
              "updated_at": "2024-02-06T17:37:26+05:30",
              "alt": null,
              "width": 1080,
              "height": 1440,
              "src": "https://storage.googleapis.com/website-paperplane/IMG_2625.JPG",
              "variant_ids": []
            },
            {
                "id": 44196034674976,
                "product_id": 9004637028640,
                "position": 1,
                "created_at": "2024-02-05T18:04:52+05:30",
                "updated_at": "2024-02-05T18:04:52+05:30",
                "alt": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW6092BRN_DRS-3.jpg?v=1707130487",
                "width": 1080,
                "height": 1440,
                "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/products/BW6092BRN_DRS-1.jpg?v=1707136492",
                "variant_ids": []
              },
              {
                "id": 44205775749408,
                "product_id": 9004637028640,
                "position": 2,
                "created_at": "2024-02-06T17:37:22+05:30",
                "updated_at": "2024-02-06T17:37:24+05:30",
                "alt": null,
                "width": 1080,
                "height": 1440,
                "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW6092BRN_DRS-2_95196db5-d1e3-408e-9f88-9cfcaadbabee.jpg?v=1707221244",
                "variant_ids": []
              },
              {
                "id": 44205776273696,
                "product_id": 9004637028640,
                "position": 3,
                "created_at": "2024-02-06T17:37:22+05:30",
                "updated_at": "2024-02-06T17:37:26+05:30",
                "alt": null,
                "width": 1080,
                "height": 1440,
                "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW6092BRN_DRS-3_c39a418e-fba9-41e5-aa40-6a64daa5215e.jpg?v=1707221246",
                "variant_ids": []
              },
              {
                "id": 44205776044320,
                "product_id": 9004637028640,
                "position": 4,
                "created_at": "2024-02-06T17:37:22+05:30",
                "updated_at": "2024-02-06T17:37:24+05:30",
                "alt": null,
                "width": 1080,
                "height": 1440,
                "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW6092BRN_DRS-4_63dea2b9-cf8a-4bd5-9b80-cbbae3302168.jpg?v=1707221244",
                "variant_ids": []
              },
              {
                "id": 44205776142624,
                "product_id": 9004637028640,
                "position": 5,
                "created_at": "2024-02-06T17:37:22+05:30",
                "updated_at": "2024-02-06T17:37:25+05:30",
                "alt": null,
                "width": 1080,
                "height": 1440,
                "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW6092BRN_DRS-5_5aaa54b2-dad3-437b-99c3-eb01d10a50d0.jpg?v=1707221245",
                "variant_ids": []
              },
              {
                "id": 44205776240928,
                "product_id": 9004637028640,
                "position": 6,
                "created_at": "2024-02-06T17:37:22+05:30",
                "updated_at": "2024-02-06T17:37:25+05:30",
                "alt": null,
                "width": 1080,
                "height": 1440,
                "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW6092BRN_DRS-6_152fbaea-f395-4d06-8be6-3387347bb819.jpg?v=1707221245",
                "variant_ids": []
              }
          ],
          "image": {
            "id": 44196034674976,
            "product_id": 9004637028640,
            "position": 1,
            "created_at": "2024-02-05T18:04:52+05:30",
            "updated_at": "2024-02-05T18:04:52+05:30",
            "alt": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW6092BRN_DRS-3.jpg?v=1707130487",
            "width": 1080,
            "height": 1440,
            "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/products/BW6092BRN_DRS-1.jpg?v=1707136492",
            "variant_ids": []
          },
          "reviews": [
            {
              src: "https://storage.googleapis.com/website-paperplane/image%20546.png",
              alt: "Naveli",
              comment: "Loved it! Fits naturally as described in size"
            },
            {
              src: "https://storage.googleapis.com/website-paperplane/image%20547.png",
              alt: "Gerogina",
              comment: "Thank you for making this affordable with amazing discounts 🙌."
            },
            {
              src: "https://storage.googleapis.com/website-paperplane/image%20548.png",
              alt: "Sahiba",
              comment: "Seamless checkout and delivery experience"
            },
          ]
        }
]

export const chaseHaulProducts = [
  {
    "id": 8310648013078,
    "title": "Women's Wine & Beige Relaxed Fit Regular Georgette Dress",
    "body_html": "\u003Cp\u003EWine &amp; Beige Embroidered Georgette Relaxed Fit Dress. It has Half Sleeves and has two side pockets.\u003C/p\u003E\n\u003Cul\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EColor: \u003C/strong\u003EWine &amp; Beige\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EMaterial Composition: \u003C/strong\u003E100% Polyester\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFabric: \u003C/strong\u003EGeorgette\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ELength: \u003C/strong\u003ERegular\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EPattern: \u003C/strong\u003EEmbroidered\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFit: \u003C/strong\u003ERelaxed Fit\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EOccasion: \u003C/strong\u003EParty\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EKnit/Woven: \u003C/strong\u003EWoven\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EClosure: \u003C/strong\u003EZip\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ENumber of Pockets: \u003C/strong\u003EYes, 2 Side Pockets\u003C/li\u003E\n\u003C/ul\u003E",
    "vendor": "Miss Chase",
    "product_type": "Dresses",
    "created_at": "2023-06-08T18:09:26+05:30",
    "handle": "womens-wine-beige-relaxed-fit-regular-georgette-dress-mcss22d06-47-603",
    "updated_at": "2024-07-01T17:03:08+05:30",
    "published_at": "2023-06-08T18:09:26+05:30",
    "template_suffix": null,
    'defaultDiscount': "51",
    "published_scope": "global",
    "tags": "3XL, 4XL, Best Fit, Best Seller, Dresses, Embroidered, Fusionwear, Inf, L, Lace-Silhouettes, Lights-Camera-Sparkle, M, Maxi, Miss Chase, must haves, nightlife, Party, Pockets, Rs. 1000 - Rs. 1999, S, shop all, sufficient, summer, Topwear, UPTO80, Wine, XL, XS, XXL, XXS",
    "variants": [
      {
        "id": 47088977674518,
        "product_id": 8310648013078,
        "title": "XXS / Wine & Beige",
        "price": "1590.00",
        "sku": "MCSS22D06-47-603-01",
        "position": 1,
        "compare_at_price": "3245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XXS",
        "option2": "Wine & Beige",
        "option3": null,
        "created_at": "2023-12-08T18:11:35+05:30",
        "updated_at": "2024-07-01T17:03:08+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088977707286,
        "product_id": 8310648013078,
        "title": "XS / Wine & Beige",
        "price": "1590.00",
        "sku": "MCSS22D06-47-603-02",
        "position": 2,
        "compare_at_price": "3245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XS",
        "option2": "Wine & Beige",
        "option3": null,
        "created_at": "2023-12-08T18:11:35+05:30",
        "updated_at": "2024-07-01T17:03:08+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088977740054,
        "product_id": 8310648013078,
        "title": "S / Wine & Beige",
        "price": "1590.00",
        "sku": "MCSS22D06-47-603-03",
        "position": 3,
        "compare_at_price": "3245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "S",
        "option2": "Wine & Beige",
        "option3": null,
        "created_at": "2023-12-08T18:11:35+05:30",
        "updated_at": "2024-07-01T17:03:08+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088977772822,
        "product_id": 8310648013078,
        "title": "M / Wine & Beige",
        "price": "1590.00",
        "sku": "MCSS22D06-47-603-04",
        "position": 4,
        "compare_at_price": "3245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "M",
        "option2": "Wine & Beige",
        "option3": null,
        "created_at": "2023-12-08T18:11:35+05:30",
        "updated_at": "2024-07-01T17:03:08+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088977805590,
        "product_id": 8310648013078,
        "title": "L / Wine & Beige",
        "price": "1590.00",
        "sku": "MCSS22D06-47-603-05",
        "position": 5,
        "compare_at_price": "3245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "L",
        "option2": "Wine & Beige",
        "option3": null,
        "created_at": "2023-12-08T18:11:35+05:30",
        "updated_at": "2024-07-01T17:03:08+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088977838358,
        "product_id": 8310648013078,
        "title": "XL / Wine & Beige",
        "price": "1590.00",
        "sku": "MCSS22D06-47-603-06",
        "position": 6,
        "compare_at_price": "3245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XL",
        "option2": "Wine & Beige",
        "option3": null,
        "created_at": "2023-12-08T18:11:35+05:30",
        "updated_at": "2024-07-01T17:03:08+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088977871126,
        "product_id": 8310648013078,
        "title": "XXL / Wine & Beige",
        "price": "1590.00",
        "sku": "MCSS22D06-47-603-07",
        "position": 7,
        "compare_at_price": "3245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XXL",
        "option2": "Wine & Beige",
        "option3": null,
        "created_at": "2023-12-08T18:11:35+05:30",
        "updated_at": "2024-07-01T17:03:08+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088977903894,
        "product_id": 8310648013078,
        "title": "3XL / Wine & Beige",
        "price": "1590.00",
        "sku": "MCSS22D06-47-603-08",
        "position": 8,
        "compare_at_price": "3245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "3XL",
        "option2": "Wine & Beige",
        "option3": null,
        "created_at": "2023-12-08T18:11:35+05:30",
        "updated_at": "2024-07-01T17:03:08+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088977936662,
        "product_id": 8310648013078,
        "title": "4XL / Wine & Beige",
        "price": "1590.00",
        "sku": "MCSS22D06-47-603-09",
        "position": 9,
        "compare_at_price": "3245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "4XL",
        "option2": "Wine & Beige",
        "option3": null,
        "created_at": "2023-12-08T18:11:35+05:30",
        "updated_at": "2024-07-01T17:03:08+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      }
    ],
    "options": [
      {
        "id": 10532688494870,
        "product_id": 8310648013078,
        "name": "Size",
        "position": 1,
        "values": [
          "XXS",
          "XS",
          "S",
          "M",
          "L",
          "XL",
          "XXL",
          "3XL",
          "4XL"
        ]
      },
      {
        "id": 11427825090838,
        "product_id": 8310648013078,
        "name": "Color",
        "position": 2,
        "values": [
          "Wine & Beige"
        ]
      }
    ],
    "images": [
      {
        "id": 41446552273174,
        "product_id": 8310648013078,
        "position": 1,
        "created_at": "2023-06-08T18:36:49+05:30",
        "updated_at": "2024-03-04T12:53:05+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/0_d4088558-1d41-462b-ac73-cac7c455a066.jpg?v=1709536985",
        "variant_ids": []
      },
      {
        "id": 41446552797462,
        "product_id": 8310648013078,
        "position": 2,
        "created_at": "2023-06-08T18:36:49+05:30",
        "updated_at": "2024-03-04T12:53:05+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/1_84828946-fac4-4a21-b9eb-ee49a12e7e83.jpg?v=1709536985",
        "variant_ids": []
      },
      {
        "id": 41446552469782,
        "product_id": 8310648013078,
        "position": 3,
        "created_at": "2023-06-08T18:36:49+05:30",
        "updated_at": "2024-03-04T12:53:05+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/Misschase6_5.jpg?v=1709536985",
        "variant_ids": []
      },
      {
        "id": 41446552633622,
        "product_id": 8310648013078,
        "position": 4,
        "created_at": "2023-06-08T18:36:49+05:30",
        "updated_at": "2024-02-01T15:17:38+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/Misschase6_3.jpg?v=1706780858",
        "variant_ids": []
      },
      {
        "id": 41446552371478,
        "product_id": 8310648013078,
        "position": 5,
        "created_at": "2023-06-08T18:36:49+05:30",
        "updated_at": "2023-09-20T15:25:17+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/Misschase6_4.jpg?v=1695203717",
        "variant_ids": []
      },
      {
        "id": 41446552535318,
        "product_id": 8310648013078,
        "position": 6,
        "created_at": "2023-06-08T18:36:49+05:30",
        "updated_at": "2023-09-20T15:25:17+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/Misschase6_9.jpg?v=1695203717",
        "variant_ids": []
      }
    ],
    "image": {
      "id": 41446552273174,
      "product_id": 8310648013078,
      "position": 1,
      "created_at": "2023-06-08T18:36:49+05:30",
      "updated_at": "2024-03-04T12:53:05+05:30",
      "alt": null,
      "width": 1080,
      "height": 1440,
      "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/0_d4088558-1d41-462b-ac73-cac7c455a066.jpg?v=1709536985",
      "variant_ids": []
    }
  },
  {
    "id": 8057859211542,
    "title": "Women's Teal Floral Printed Relaxed Fit V-Neck 3/4th Sleeve Maxi Dress",
    "body_html": "\u003Cp\u003ETeal relaxed fit wrap around maxi dress, has a v-neck and 3/4th sleeve, has a zip closure and 2 side pockets.\u003C/p\u003E\n\u003Cul\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EColor: \u003C/strong\u003ETeal\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EMaterial Composition: \u003C/strong\u003E100% Polyester\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EGender: \u003C/strong\u003EWomen\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFabric: \u003C/strong\u003EPolyester\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ELength: \u003C/strong\u003EMaxi\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EPattern: \u003C/strong\u003EFloral\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFit: \u003C/strong\u003ERelaxed Fit\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EOccasion: \u003C/strong\u003ECasual\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EYear: \u003C/strong\u003E2022\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ESeason: \u003C/strong\u003ESS22\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EKnit/Woven: \u003C/strong\u003EWoven\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EClosure: \u003C/strong\u003EZip\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ECollar: \u003C/strong\u003EV-Neck\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ESleeve: \u003C/strong\u003E3/4th Sleeves\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ENeck: \u003C/strong\u003EV-Neck\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ENumber of Pockets: \u003C/strong\u003EYes, 2 Side Pockets\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EWash Care: \u003C/strong\u003EHand wash thoroughly in warm water, do not bleach, soak or wring, drip dry away from direct heat, warm iron only, dry cleanable, wash dark colors separately\u003C/li\u003E\n\u003C/ul\u003E",
    "vendor": "Miss Chase",
    "product_type": "Dresses",
    "created_at": "2022-12-22T16:58:36+05:30",
    "handle": "women-s-teal-floral-printed-relaxed-fit-v-neck-3-4th-sleeve-maxi-dress-mcss21d14-67-83",
    "updated_at": "2024-07-01T17:04:09+05:30",
    'defaultDiscount': "57",
    "published_at": "2024-06-19T13:57:35+05:30",
    "template_suffix": null,
    "published_scope": "global",
    "tags": "3XL, 4XL, Best Fit, Best Seller, Brand, Dresses, Floral, L, Lace-Silhouettes, M, Maxi, Miss Chase, must haves, Printed, Rs. 1000 - Rs. 1999, S, shop all, Statment Prints, sufficient, summer, Teal, Topwear, UPTO80, vacation, Women's Western Wear, XL, XS, XXL",
    "variants": [
      {
        "id": 47089245061398,
        "product_id": 8057859211542,
        "title": "XS / Teal",
        "price": "1375.00",
        "sku": "MCSS21D14-67-83-02",
        "position": 1,
        "compare_at_price": "3199.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XS",
        "option2": "Teal",
        "option3": null,
        "created_at": "2023-12-08T18:49:22+05:30",
        "updated_at": "2024-07-01T17:04:09+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47089245094166,
        "product_id": 8057859211542,
        "title": "S / Teal",
        "price": "1375.00",
        "sku": "MCSS21D14-67-83-03",
        "position": 2,
        "compare_at_price": "3199.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "S",
        "option2": "Teal",
        "option3": null,
        "created_at": "2023-12-08T18:49:22+05:30",
        "updated_at": "2024-07-01T17:04:09+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47089245126934,
        "product_id": 8057859211542,
        "title": "M / Teal",
        "price": "1375.00",
        "sku": "MCSS21D14-67-83-04",
        "position": 3,
        "compare_at_price": "3199.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "M",
        "option2": "Teal",
        "option3": null,
        "created_at": "2023-12-08T18:49:22+05:30",
        "updated_at": "2024-07-01T17:04:09+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47089245159702,
        "product_id": 8057859211542,
        "title": "L / Teal",
        "price": "1375.00",
        "sku": "MCSS21D14-67-83-05",
        "position": 4,
        "compare_at_price": "3199.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "L",
        "option2": "Teal",
        "option3": null,
        "created_at": "2023-12-08T18:49:22+05:30",
        "updated_at": "2024-07-01T17:04:09+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47089245192470,
        "product_id": 8057859211542,
        "title": "XL / Teal",
        "price": "1375.00",
        "sku": "MCSS21D14-67-83-06",
        "position": 5,
        "compare_at_price": "3199.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XL",
        "option2": "Teal",
        "option3": null,
        "created_at": "2023-12-08T18:49:22+05:30",
        "updated_at": "2024-07-01T17:04:09+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47089245225238,
        "product_id": 8057859211542,
        "title": "XXL / Teal",
        "price": "1375.00",
        "sku": "MCSS21D14-67-83-07",
        "position": 6,
        "compare_at_price": "3199.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XXL",
        "option2": "Teal",
        "option3": null,
        "created_at": "2023-12-08T18:49:23+05:30",
        "updated_at": "2024-07-01T17:04:09+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47089245258006,
        "product_id": 8057859211542,
        "title": "3XL / Teal",
        "price": "1375.00",
        "sku": "MCSS21D14-67-83-08",
        "position": 7,
        "compare_at_price": "3199.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "3XL",
        "option2": "Teal",
        "option3": null,
        "created_at": "2023-12-08T18:49:23+05:30",
        "updated_at": "2024-07-01T17:04:09+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47089245290774,
        "product_id": 8057859211542,
        "title": "4XL / Teal",
        "price": "1375.00",
        "sku": "MCSS21D14-67-83-09",
        "position": 8,
        "compare_at_price": "3199.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "4XL",
        "option2": "Teal",
        "option3": null,
        "created_at": "2023-12-08T18:49:23+05:30",
        "updated_at": "2024-07-01T17:04:09+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      }
    ],
    "options": [
      {
        "id": 10232936464662,
        "product_id": 8057859211542,
        "name": "Size",
        "position": 1,
        "values": [
          "XS",
          "S",
          "M",
          "L",
          "XL",
          "XXL",
          "3XL",
          "4XL"
        ]
      },
      {
        "id": 11427877585174,
        "product_id": 8057859211542,
        "name": "Color",
        "position": 2,
        "values": [
          "Teal"
        ]
      }
    ],
    "images": [
      {
        "id": 44422366396694,
        "product_id": 8057859211542,
        "position": 1,
        "created_at": "2024-04-25T10:49:52+05:30",
        "updated_at": "2024-06-18T17:15:44+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/6-Copy_ad7e1a54-9322-41d7-a502-61d7aea65685.jpg?v=1718711144",
        "variant_ids": []
      },
      {
        "id": 40074991173910,
        "product_id": 8057859211542,
        "position": 2,
        "created_at": "2022-12-22T18:19:00+05:30",
        "updated_at": "2024-06-18T17:15:44+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCSS21D14-67-83_4.jpg?v=1718711144",
        "variant_ids": []
      },
      {
        "id": 40074991042838,
        "product_id": 8057859211542,
        "position": 3,
        "created_at": "2022-12-22T18:19:00+05:30",
        "updated_at": "2024-04-25T10:50:05+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCSS21D14-67-83_1.jpg?v=1714022405",
        "variant_ids": []
      },
      {
        "id": 40074991075606,
        "product_id": 8057859211542,
        "position": 4,
        "created_at": "2022-12-22T18:19:00+05:30",
        "updated_at": "2024-04-25T10:50:05+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCSS21D14-67-83_2.jpg?v=1714022405",
        "variant_ids": []
      },
      {
        "id": 40074991108374,
        "product_id": 8057859211542,
        "position": 5,
        "created_at": "2022-12-22T18:19:00+05:30",
        "updated_at": "2024-04-25T10:50:05+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCSS21D14-67-83_3.jpg?v=1714022405",
        "variant_ids": []
      },
      {
        "id": 40074991239446,
        "product_id": 8057859211542,
        "position": 6,
        "created_at": "2022-12-22T18:19:00+05:30",
        "updated_at": "2024-04-25T10:50:05+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCSS21D14-67-83_5.jpg?v=1714022405",
        "variant_ids": []
      }
    ],
    "image": {
      "id": 44422366396694,
      "product_id": 8057859211542,
      "position": 1,
      "created_at": "2024-04-25T10:49:52+05:30",
      "updated_at": "2024-06-18T17:15:44+05:30",
      "alt": null,
      "width": 1080,
      "height": 1440,
      "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/6-Copy_ad7e1a54-9322-41d7-a502-61d7aea65685.jpg?v=1718711144",
      "variant_ids": []
    }
  }
]

export const chaseHaulProducts1 = [
  {
    "id": 8426146529558,
    "title": "Women's Rust Sweetheart Neck Raglan Sleeve Floral Lace Overlaid Crepe Regular Length Jumpsuit",
    "body_html": "\u003Cp\u003ERust Floral Lace Overlaid Crepe Regular Length Jumpsuit. It Has Sweetheart Neck And Raglan Sleeve. It Has Back Closure.\u003C/p\u003E\n\u003Cul\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EColor: \u003C/strong\u003ERust\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EMaterial Composition: \u003C/strong\u003E100% Polyester\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFabric: \u003C/strong\u003EMoss Crepe &amp; Lace\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ELength: \u003C/strong\u003ERegular\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EPattern: \u003C/strong\u003EFloral\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFit: \u003C/strong\u003ERelaxed Fit\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EOccasion: \u003C/strong\u003EParty\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EKnit/Woven: \u003C/strong\u003EWoven\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EClosure: \u003C/strong\u003EZip\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ENumber of Pockets: \u003C/strong\u003EYes, 2 Side Pockets\u003C/li\u003E\n\u003C/ul\u003E",
    "vendor": "Miss Chase",
    "product_type": "Jumpsuit",
    "created_at": "2023-07-31T11:50:33+05:30",
    "handle": "women-s-rust-sweetheart-neck-raglan-sleeve-floral-lace-overlaid-crepe-regular-length-jumpsuit-mcss23d15-68-606",
    "updated_at": "2024-07-02T19:44:39+05:30",
    "published_at": "2024-06-20T18:29:37+05:30",
    "template_suffix": null,
    "defaultDiscount": "57",
    "published_scope": "global",
    "tags": "Best Seller, Floral, Jumpsuits, L, Lace-Silhouettes, lights-camera-sparkle, M, Miss Chase, must haves, nightlife, Overall, Overalls, Party, Pockets, Regular, Rs. 1000 - Rs. 1999, Rust, S, shop all, Topwear, UPTO80, XL, XS",
    "variants": [
      {
        "id": 47088953688342,
        "product_id": 8426146529558,
        "title": "XS / Rust",
        "price": "1395.00",
        "sku": "MCSS23D15-68-606-02",
        "position": 1,
        "compare_at_price": "3245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XS",
        "option2": "Rust",
        "option3": null,
        "created_at": "2023-12-08T18:08:09+05:30",
        "updated_at": "2024-07-02T19:44:39+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088953721110,
        "product_id": 8426146529558,
        "title": "S / Rust",
        "price": "1395.00",
        "sku": "MCSS23D15-68-606-03",
        "position": 2,
        "compare_at_price": "3245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "S",
        "option2": "Rust",
        "option3": null,
        "created_at": "2023-12-08T18:08:09+05:30",
        "updated_at": "2024-07-02T19:44:39+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088953753878,
        "product_id": 8426146529558,
        "title": "M / Rust",
        "price": "1395.00",
        "sku": "MCSS23D15-68-606-04",
        "position": 3,
        "compare_at_price": "3245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "M",
        "option2": "Rust",
        "option3": null,
        "created_at": "2023-12-08T18:08:09+05:30",
        "updated_at": "2024-07-02T19:44:39+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088953786646,
        "product_id": 8426146529558,
        "title": "L / Rust",
        "price": "1395.00",
        "sku": "MCSS23D15-68-606-05",
        "position": 4,
        "compare_at_price": "3245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "L",
        "option2": "Rust",
        "option3": null,
        "created_at": "2023-12-08T18:08:09+05:30",
        "updated_at": "2024-07-02T19:44:39+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088953819414,
        "product_id": 8426146529558,
        "title": "XL / Rust",
        "price": "1395.00",
        "sku": "MCSS23D15-68-606-06",
        "position": 5,
        "compare_at_price": "3245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XL",
        "option2": "Rust",
        "option3": null,
        "created_at": "2023-12-08T18:08:09+05:30",
        "updated_at": "2024-07-02T19:44:39+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      }
    ],
    "options": [
      {
        "id": 10667241799958,
        "product_id": 8426146529558,
        "name": "Size",
        "position": 1,
        "values": [
          "XS",
          "S",
          "M",
          "L",
          "XL"
        ]
      },
      {
        "id": 11427820503318,
        "product_id": 8426146529558,
        "name": "Color",
        "position": 2,
        "values": [
          "Rust"
        ]
      }
    ],
    "images": [
      {
        "id": 41902406566166,
        "product_id": 8426146529558,
        "position": 1,
        "created_at": "2023-07-31T11:59:24+05:30",
        "updated_at": "2024-06-20T18:29:36+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/1_72bf07c7-9288-4c03-bf4d-ddd2572f8691.jpg?v=1718888376",
        "variant_ids": []
      },
      {
        "id": 41902406467862,
        "product_id": 8426146529558,
        "position": 2,
        "created_at": "2023-07-31T11:59:24+05:30",
        "updated_at": "2024-06-20T18:29:36+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/2_749261a2-afa3-4d46-b2ff-8db8bf64bbee.jpg?v=1718888376",
        "variant_ids": []
      },
      {
        "id": 41902406500630,
        "product_id": 8426146529558,
        "position": 3,
        "created_at": "2023-07-31T11:59:24+05:30",
        "updated_at": "2024-06-20T18:29:36+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/3_17dc584f-fac8-48a3-9762-2c8e99d716db.jpg?v=1718888376",
        "variant_ids": []
      },
      {
        "id": 41902406402326,
        "product_id": 8426146529558,
        "position": 4,
        "created_at": "2023-07-31T11:59:24+05:30",
        "updated_at": "2024-06-20T18:29:36+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/4_e465c324-bef5-422c-a19a-abe614b9f54c.jpg?v=1718888376",
        "variant_ids": []
      },
      {
        "id": 41902406631702,
        "product_id": 8426146529558,
        "position": 5,
        "created_at": "2023-07-31T11:59:24+05:30",
        "updated_at": "2023-10-19T13:14:11+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/6_544070fe-61c4-4c72-a75c-a25e0193d580.jpg?v=1697701451",
        "variant_ids": []
      },
      {
        "id": 41902406533398,
        "product_id": 8426146529558,
        "position": 6,
        "created_at": "2023-07-31T11:59:24+05:30",
        "updated_at": "2023-10-19T13:14:11+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/0_0ca084a0-427b-4430-a0b6-b8378e1427aa.jpg?v=1697701451",
        "variant_ids": []
      }
    ],
    "image": {
      "id": 41902406566166,
      "product_id": 8426146529558,
      "position": 1,
      "created_at": "2023-07-31T11:59:24+05:30",
      "updated_at": "2024-06-20T18:29:36+05:30",
      "alt": null,
      "width": 1080,
      "height": 1440,
      "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/1_72bf07c7-9288-4c03-bf4d-ddd2572f8691.jpg?v=1718888376",
      "variant_ids": []
    }
  },
  {
    "id": 8508422455574,
    "title": "Women's Black & Dusty Pink Square Neck Sleeveless Self Design Lace Overlaid Regular Jumpsuit",
    "body_html": "\u003Cp\u003EBlack &amp; Dusty Pink Georgette Self Design Lace Overlaid Regular Jumpsuit. It has a square neck and is sleeveless. It has two side pockets. It has contrasted paneled and tie-up detailing at shoulder.\u003C/p\u003E\n\u003Cul\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EColor: \u003C/strong\u003EBlack\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EMaterial Composition: \u003C/strong\u003E100% Polyester\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFabric: \u003C/strong\u003EGeorgette &amp; Lace\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ELength: \u003C/strong\u003ERegular\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EPattern: \u003C/strong\u003ESelf Design\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFit: \u003C/strong\u003ERelaxed Fit\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EOccasion: \u003C/strong\u003EParty\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EKnit/Woven: \u003C/strong\u003EWoven\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EClosure: \u003C/strong\u003EZip\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ENumber of Pockets: \u003C/strong\u003EYes, 2 Side Pockets\u003C/li\u003E\n\u003C/ul\u003E",
    "vendor": "Miss Chase",
    "product_type": "Jumpsuit",
    "defaultDiscount": "60",
    "created_at": "2023-08-22T10:23:13+05:30",
    "handle": "women-s-black-dusty-pink-square-neck-sleeveless-self-design-lace-overlaid-regular-jumpsuit-mcss23d98-28-62",
    "updated_at": "2024-07-02T19:45:54+05:30",
    "published_at": "2023-08-22T10:23:13+05:30",
    "template_suffix": null,
    "published_scope": "global",
    "tags": "60-80 off, Black, Jumpsuits, L, Lace-Silhouettes, Lights-Camera-Sparkle, M, Miss Chase, must haves, nightlife, Overall, Party, Pockets, Regular, romance, Rs. 1000 - Rs. 1999, S, Self Design, shop all, Topwear, UPTO80, XL, XS",
    "variants": [
      {
        "id": 47088938123542,
        "product_id": 8508422455574,
        "title": "XS / Black",
        "price": "1398.00",
        "sku": "MCSS23D98-28-62-02",
        "position": 1,
        "compare_at_price": "3495.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XS",
        "option2": "Black",
        "option3": null,
        "created_at": "2023-12-08T18:06:20+05:30",
        "updated_at": "2024-07-02T19:45:54+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 300,
        "image_id": null,
        "weight": 300,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088938156310,
        "product_id": 8508422455574,
        "title": "S / Black",
        "price": "1398.00",
        "sku": "MCSS23D98-28-62-03",
        "position": 2,
        "compare_at_price": "3495.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "S",
        "option2": "Black",
        "option3": null,
        "created_at": "2023-12-08T18:06:20+05:30",
        "updated_at": "2024-07-02T19:45:54+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 300,
        "image_id": null,
        "weight": 300,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088938189078,
        "product_id": 8508422455574,
        "title": "M / Black",
        "price": "1398.00",
        "sku": "MCSS23D98-28-62-04",
        "position": 3,
        "compare_at_price": "3495.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "M",
        "option2": "Black",
        "option3": null,
        "created_at": "2023-12-08T18:06:20+05:30",
        "updated_at": "2024-07-02T19:45:54+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 300,
        "image_id": null,
        "weight": 300,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088938221846,
        "product_id": 8508422455574,
        "title": "L / Black",
        "price": "1398.00",
        "sku": "MCSS23D98-28-62-05",
        "position": 4,
        "compare_at_price": "3495.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "L",
        "option2": "Black",
        "option3": null,
        "created_at": "2023-12-08T18:06:20+05:30",
        "updated_at": "2024-07-02T19:45:54+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 300,
        "image_id": null,
        "weight": 300,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088938254614,
        "product_id": 8508422455574,
        "title": "XL / Black",
        "price": "1398.00",
        "sku": "MCSS23D98-28-62-06",
        "position": 5,
        "compare_at_price": "3495.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XL",
        "option2": "Black",
        "option3": null,
        "created_at": "2023-12-08T18:06:20+05:30",
        "updated_at": "2024-07-02T19:45:54+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 300,
        "image_id": null,
        "weight": 300,
        "weight_unit": "g",
        "requires_shipping": true
      }
    ],
    "options": [
      {
        "id": 10759646052630,
        "product_id": 8508422455574,
        "name": "Size",
        "position": 1,
        "values": [
          "XS",
          "S",
          "M",
          "L",
          "XL"
        ]
      },
      {
        "id": 11427817619734,
        "product_id": 8508422455574,
        "name": "Color",
        "position": 2,
        "values": [
          "Black"
        ]
      }
    ],
    "images": [
      {
        "id": 42129582653718,
        "product_id": 8508422455574,
        "position": 1,
        "created_at": "2023-08-22T10:48:50+05:30",
        "updated_at": "2024-03-15T14:20:07+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/21723_Misschase_89_09copy.jpg?v=1710492607",
        "variant_ids": []
      },
      {
        "id": 42129582457110,
        "product_id": 8508422455574,
        "position": 2,
        "created_at": "2023-08-22T10:48:50+05:30",
        "updated_at": "2024-03-15T14:20:07+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/21723_Misschase_89_02copy.jpg?v=1710492607",
        "variant_ids": []
      },
      {
        "id": 42129582555414,
        "product_id": 8508422455574,
        "position": 3,
        "created_at": "2023-08-22T10:48:50+05:30",
        "updated_at": "2024-03-15T14:20:07+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/21723_Misschase_89_07copy.jpg?v=1710492607",
        "variant_ids": []
      },
      {
        "id": 42129582588182,
        "product_id": 8508422455574,
        "position": 4,
        "created_at": "2023-08-22T10:48:50+05:30",
        "updated_at": "2024-03-15T14:20:07+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/21723_Misschase_89_13.jpg?v=1710492607",
        "variant_ids": []
      },
      {
        "id": 42129582620950,
        "product_id": 8508422455574,
        "position": 5,
        "created_at": "2023-08-22T10:48:50+05:30",
        "updated_at": "2024-03-15T14:20:07+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/21723_Misschase_89_12.jpg?v=1710492607",
        "variant_ids": []
      },
      {
        "id": 42129582424342,
        "product_id": 8508422455574,
        "position": 6,
        "created_at": "2023-08-22T10:48:50+05:30",
        "updated_at": "2024-03-15T14:20:07+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/21723_Misschase_89_06copy.jpg?v=1710492607",
        "variant_ids": []
      }
    ],
    "image": {
      "id": 42129582653718,
      "product_id": 8508422455574,
      "position": 1,
      "created_at": "2023-08-22T10:48:50+05:30",
      "updated_at": "2024-03-15T14:20:07+05:30",
      "alt": null,
      "width": 1080,
      "height": 1440,
      "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/21723_Misschase_89_09copy.jpg?v=1710492607",
      "variant_ids": []
    }
  },
  {
    "id": 8057858326806,
    "title": "Women's Black Raglan Half Sleeve Solid Waist Cut-Out Regular Length Jumpsuit",
    "body_html": "\u003Cp\u003EBlack solid knitted waist Cut-Out jumpsuit, Has a round neck and half sleeves, Has a zip closure and sheer lace sleeves.\u003C/p\u003E\n\u003Cul\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EColor: \u003C/strong\u003EBlack\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EMaterial Composition: \u003C/strong\u003E96% Polyester 4% Spandex\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EGender: \u003C/strong\u003EWomen\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFabric: \u003C/strong\u003EPolyester\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ELength: \u003C/strong\u003ERegular\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EPattern: \u003C/strong\u003ESolid\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFit: \u003C/strong\u003ESlim Fit\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EOccasion: \u003C/strong\u003EParty\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EYear: \u003C/strong\u003E2022\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ESeason: \u003C/strong\u003EAW22\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EKnit/Woven: \u003C/strong\u003EKnitted\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EClosure: \u003C/strong\u003EZip\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ECollar: \u003C/strong\u003ERaglan\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ESleeve: \u003C/strong\u003EHalf Sleeves\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ENeck: \u003C/strong\u003ERound Neck\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ENumber of Pockets: \u003C/strong\u003EYes, 2 Side Pockets\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EWash Care: \u003C/strong\u003EHand Wash with Warm Water, wash dark colors separately, Do not Bleach, Dip Dry away from direct heat, Warm Iron Only\u003C/li\u003E\n\u003C/ul\u003E",
    "vendor": "Miss Chase",
    "product_type": "Jumpsuits",
    "created_at": "2022-12-22T16:58:10+05:30",
    "defaultDiscount": "60",
    "handle": "women-s-black-raglan-half-sleeve-solid-waist-cut-out-regular-length-jumpsuit-mcaw19d08-49-62",
    "updated_at": "2024-07-02T19:47:28+05:30",
    'noViews': "7.3",
    "noPurchases": "1.6",
    "published_at": "2022-12-22T18:10:49+05:30",
    "template_suffix": null,
    "published_scope": "global",
    "tags": "60-80 off, Best Fit, Best Seller, Black, Brand, Jumpsuits, L, Lace-Silhouettes, Lights-Camera-Sparkle, M, Miss Chase, must haves, nightlife, Overall, Party, Pockets, Regular, Rs. 1000 - Rs. 1999, S, shop all, Shop Knits, social proof, Solid, Solid Jumpsuits, Topwear, UPTO80, Women's Western Wear, XL, XS",
    "variants": [
      {
        "id": 47089249026326,
        "product_id": 8057858326806,
        "title": "XS / Black",
        "price": "1180.00",
        "sku": "MCAW19D08-49-62-02",
        "position": 1,
        "compare_at_price": "2949.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XS",
        "option2": "Black",
        "option3": null,
        "created_at": "2023-12-08T18:49:56+05:30",
        "updated_at": "2024-07-02T19:47:28+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47089249059094,
        "product_id": 8057858326806,
        "title": "S / Black",
        "price": "1180.00",
        "sku": "MCAW19D08-49-62-03",
        "position": 2,
        "compare_at_price": "2949.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "S",
        "option2": "Black",
        "option3": null,
        "created_at": "2023-12-08T18:49:56+05:30",
        "updated_at": "2024-07-02T19:47:28+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47089249091862,
        "product_id": 8057858326806,
        "title": "M / Black",
        "price": "1180.00",
        "sku": "MCAW19D08-49-62-04",
        "position": 3,
        "compare_at_price": "2949.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "M",
        "option2": "Black",
        "option3": null,
        "created_at": "2023-12-08T18:49:56+05:30",
        "updated_at": "2024-07-02T19:47:28+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47089249124630,
        "product_id": 8057858326806,
        "title": "L / Black",
        "price": "1180.00",
        "sku": "MCAW19D08-49-62-05",
        "position": 4,
        "compare_at_price": "2949.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "L",
        "option2": "Black",
        "option3": null,
        "created_at": "2023-12-08T18:49:56+05:30",
        "updated_at": "2024-07-02T19:47:28+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47089249157398,
        "product_id": 8057858326806,
        "title": "XL / Black",
        "price": "1180.00",
        "sku": "MCAW19D08-49-62-06",
        "position": 5,
        "compare_at_price": "2949.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XL",
        "option2": "Black",
        "option3": null,
        "created_at": "2023-12-08T18:49:56+05:30",
        "updated_at": "2024-07-02T19:47:28+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      }
    ],
    "options": [
      {
        "id": 10232934727958,
        "product_id": 8057858326806,
        "name": "Size",
        "position": 1,
        "values": [
          "XS",
          "S",
          "M",
          "L",
          "XL"
        ]
      },
      {
        "id": 11427878371606,
        "product_id": 8057858326806,
        "name": "Color",
        "position": 2,
        "values": [
          "Black"
        ]
      }
    ],
    "images": [
      {
        "id": 40074915217686,
        "product_id": 8057858326806,
        "position": 1,
        "created_at": "2022-12-22T18:11:03+05:30",
        "updated_at": "2024-03-15T14:19:08+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCAW19D08-49-62_1.jpg?v=1710492548",
        "variant_ids": []
      },
      {
        "id": 42051478225174,
        "product_id": 8057858326806,
        "position": 2,
        "created_at": "2023-08-14T12:47:27+05:30",
        "updated_at": "2024-03-15T14:19:08+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/image_6_56c21d2a-41d5-4c47-a18a-a85867ef9ca0.png?v=1710492548",
        "variant_ids": []
      },
      {
        "id": 40074915250454,
        "product_id": 8057858326806,
        "position": 3,
        "created_at": "2022-12-22T18:11:03+05:30",
        "updated_at": "2024-03-15T14:19:08+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCAW19D08-49-62_10.jpg?v=1710492548",
        "variant_ids": []
      },
      {
        "id": 40074915348758,
        "product_id": 8057858326806,
        "position": 4,
        "created_at": "2022-12-22T18:11:03+05:30",
        "updated_at": "2024-03-15T14:19:08+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCAW19D08-49-62_4.jpg?v=1710492548",
        "variant_ids": []
      },
      {
        "id": 40074915479830,
        "product_id": 8057858326806,
        "position": 5,
        "created_at": "2022-12-22T18:11:03+05:30",
        "updated_at": "2024-03-15T14:19:08+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCAW19D08-49-62_8.jpg?v=1710492548",
        "variant_ids": []
      },
      {
        "id": 40074915381526,
        "product_id": 8057858326806,
        "position": 6,
        "created_at": "2022-12-22T18:11:03+05:30",
        "updated_at": "2024-03-15T14:19:08+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCAW19D08-49-62_5.jpg?v=1710492548",
        "variant_ids": []
      },
      {
        "id": 40074915414294,
        "product_id": 8057858326806,
        "position": 7,
        "created_at": "2022-12-22T18:11:03+05:30",
        "updated_at": "2024-03-15T14:19:08+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCAW19D08-49-62_6.jpg?v=1710492548",
        "variant_ids": []
      },
      {
        "id": 40074915447062,
        "product_id": 8057858326806,
        "position": 8,
        "created_at": "2022-12-22T18:11:03+05:30",
        "updated_at": "2024-03-15T14:19:08+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCAW19D08-49-62_7.jpg?v=1710492548",
        "variant_ids": []
      }
    ],
    "image": {
      "id": 40074915217686,
      "product_id": 8057858326806,
      "position": 1,
      "created_at": "2022-12-22T18:11:03+05:30",
      "updated_at": "2024-03-15T14:19:08+05:30",
      "alt": null,
      "width": 1080,
      "height": 1440,
      "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCAW19D08-49-62_1.jpg?v=1710492548",
      "variant_ids": []
    }
  },
  {
    "id": 8200125514006,
    "title": "Women's Wine & Beige Halter neck Sleeveless Self Design Lace Overlaid Regular Jumpsuit",
    "body_html": "\u003Cp\u003EWine &amp; Beige Georgette Self Design Lace Overlaid Regular Jumpsuit. It has a halter neck and is sleeveless. It has a lining in beige.\u003C/p\u003E\n\u003Cul\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EColor: \u003C/strong\u003EWine &amp; Beige\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EMaterial Composition: \u003C/strong\u003E100% Polyester\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFabric: \u003C/strong\u003EGeorgette\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ELength: \u003C/strong\u003ERegular\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EPattern: \u003C/strong\u003ESelf Design\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFit: \u003C/strong\u003ERelaxed Fit\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EOccasion: \u003C/strong\u003ECasual\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EKnit/Woven: \u003C/strong\u003EWoven\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EClosure: \u003C/strong\u003EZip\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ENumber of Pockets: \u003C/strong\u003ENA\u003C/li\u003E\n\u003C/ul\u003E",
    "vendor": "Miss Chase",
    "product_type": "Jumpsuits",
    "created_at": "2023-04-13T18:52:51+05:30",
    "handle": "womens-wine-beige-halter-neck-sleeveless-self-design-lace-overlaid-regular-jumpsuit-mcss20d04-47-603",
    "updated_at": "2024-07-02T19:50:12+05:30",
    "published_at": "2024-06-19T13:54:37+05:30",
    "defaultDiscount": "60",
    "template_suffix": null,
    'noViews': "12",
    "noPurchases": "2.5",
    "published_scope": "web",
    "tags": "60-80 off, Jumpsuits, L, Lace-Silhouettes, M, Miss Chase, Overall, Regular, Rs. 500 - Rs. 999, S, Self Design, shop all, Topwear, UPTO80, Wine, XL, XS",
    "variants": [
      {
        "id": 47089097736470,
        "product_id": 8200125514006,
        "title": "XS / Wine & Beige",
        "price": "939.00",
        "sku": "MCSS20D04-47-603-02",
        "position": 1,
        "compare_at_price": "2349.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XS",
        "option2": "Wine & Beige",
        "option3": null,
        "created_at": "2023-12-08T18:26:00+05:30",
        "updated_at": "2024-07-02T19:50:12+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47089097769238,
        "product_id": 8200125514006,
        "title": "S / Wine & Beige",
        "price": "939.00",
        "sku": "MCSS20D04-47-603-03",
        "position": 2,
        "compare_at_price": "2349.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "S",
        "option2": "Wine & Beige",
        "option3": null,
        "created_at": "2023-12-08T18:26:00+05:30",
        "updated_at": "2024-07-02T19:50:12+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47089097802006,
        "product_id": 8200125514006,
        "title": "M / Wine & Beige",
        "price": "939.00",
        "sku": "MCSS20D04-47-603-04",
        "position": 3,
        "compare_at_price": "2349.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "M",
        "option2": "Wine & Beige",
        "option3": null,
        "created_at": "2023-12-08T18:26:00+05:30",
        "updated_at": "2024-07-02T19:50:12+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47089097834774,
        "product_id": 8200125514006,
        "title": "L / Wine & Beige",
        "price": "939.00",
        "sku": "MCSS20D04-47-603-05",
        "position": 4,
        "compare_at_price": "2349.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "L",
        "option2": "Wine & Beige",
        "option3": null,
        "created_at": "2023-12-08T18:26:00+05:30",
        "updated_at": "2024-07-02T19:50:12+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47089097867542,
        "product_id": 8200125514006,
        "title": "XL / Wine & Beige",
        "price": "939.00",
        "sku": "MCSS20D04-47-603-06",
        "position": 5,
        "compare_at_price": "2349.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XL",
        "option2": "Wine & Beige",
        "option3": null,
        "created_at": "2023-12-08T18:26:00+05:30",
        "updated_at": "2024-07-02T19:50:12+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      }
    ],
    "options": [
      {
        "id": 10405028856086,
        "product_id": 8200125514006,
        "name": "Size",
        "position": 1,
        "values": [
          "XS",
          "S",
          "M",
          "L",
          "XL"
        ]
      },
      {
        "id": 11427847438614,
        "product_id": 8200125514006,
        "name": "Color",
        "position": 2,
        "values": [
          "Wine & Beige"
        ]
      }
    ],
    "images": [
      {
        "id": 41033835675926,
        "product_id": 8200125514006,
        "position": 1,
        "created_at": "2023-04-20T16:25:27+05:30",
        "updated_at": "2023-09-19T12:26:45+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/0_3a5c0357-9d83-4753-a4a5-3f6f28e51b68.jpg?v=1695106605",
        "variant_ids": []
      },
      {
        "id": 41139943735574,
        "product_id": 8200125514006,
        "position": 2,
        "created_at": "2023-05-05T11:04:08+05:30",
        "updated_at": "2023-09-19T12:26:45+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/1_434a88c3-b660-454f-9ddd-96232da09cce.jpg?v=1695106605",
        "variant_ids": []
      },
      {
        "id": 41139943571734,
        "product_id": 8200125514006,
        "position": 3,
        "created_at": "2023-05-05T11:04:08+05:30",
        "updated_at": "2023-09-19T12:26:45+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/2_3c2a3ab6-e0a0-4ca9-b266-ff99aac02c2c.jpg?v=1695106605",
        "variant_ids": []
      },
      {
        "id": 41139943604502,
        "product_id": 8200125514006,
        "position": 4,
        "created_at": "2023-05-05T11:04:08+05:30",
        "updated_at": "2023-05-05T11:04:09+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/4_d65ff029-8bc8-449b-afe5-fc88099cd1d4.jpg?v=1683264849",
        "variant_ids": []
      },
      {
        "id": 41139943670038,
        "product_id": 8200125514006,
        "position": 5,
        "created_at": "2023-05-05T11:04:08+05:30",
        "updated_at": "2023-05-05T11:04:09+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/5_6680f728-1ac9-4b46-bd37-757e1354fa50.jpg?v=1683264849",
        "variant_ids": []
      },
      {
        "id": 41139943637270,
        "product_id": 8200125514006,
        "position": 6,
        "created_at": "2023-05-05T11:04:08+05:30",
        "updated_at": "2023-05-05T11:04:09+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/IMG_0127.jpg?v=1683264849",
        "variant_ids": []
      }
    ],
    "image": {
      "id": 41033835675926,
      "product_id": 8200125514006,
      "position": 1,
      "created_at": "2023-04-20T16:25:27+05:30",
      "updated_at": "2023-09-19T12:26:45+05:30",
      "alt": null,
      "width": 1080,
      "height": 1440,
      "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/0_3a5c0357-9d83-4753-a4a5-3f6f28e51b68.jpg?v=1695106605",
      "variant_ids": []
    }
  }
]


export const chaseHaulProducts2 = [
  {
    "id": 8331593777430,
    "title": "Women's Multicolored-Base-Lime Yellow V-Neck Bishop Sleeve Floral Gathered Chiffon Maxi Dress",
    "body_html": "\u003Cp\u003EMulticolored-Base-Lime Yellow Regular Length Floral Patterned Gathered Chiffon Maxi Dress. It Has Bishop Sleeve And V-Neck. It Has 2 Side Pockets.\u003C/p\u003E\n\u003Cul\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EColor: \u003C/strong\u003EMulticolored-Base-Lime Yellow\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EMaterial Composition: \u003C/strong\u003E100% Polyester\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFabric: \u003C/strong\u003EChiffon\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ELength: \u003C/strong\u003EMaxi\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EPattern: \u003C/strong\u003EFloral\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFit: \u003C/strong\u003ERelaxed Fit\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EOccasion: \u003C/strong\u003ECasual\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EKnit/Woven: \u003C/strong\u003EWoven\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EClosure: \u003C/strong\u003ENA\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ENumber of Pockets: \u003C/strong\u003EYes, 2 Side Pockets\u003C/li\u003E\n\u003C/ul\u003E",
    "vendor": "Miss Chase",
    "product_type": "Dresses",
    "created_at": "2023-06-19T17:15:06+05:30",
    "handle": "women-s-multicolored-base-lime-yellow-v-neck-bishop-sleeve-floral-gathered-chiffon-maxi-dress-mcss23d06-65-278",
    "updated_at": "2024-07-04T17:27:01+05:30",
    "published_at": "2024-06-19T17:50:42+05:30",
    'defaultDiscount': "53",
    "template_suffix": null,
    "published_scope": "global",
    "tags": "3XL, Best Fit, Best Seller, Brunch, Dresses, Floral, L, M, Maxi, Maxi Dresses, Miss Chase, must haves, Pockets, Rs. 1000 - Rs. 1999, S, shop all, Statment Prints, sufficient, summer, Topwear, UPTO80, vacation, XL, XS, XXL, Yellow",
    "variants": [
      {
        "id": 47088964370710,
        "product_id": 8331593777430,
        "title": "XS / Yellow",
        "price": "1995.00",
        "sku": "MCSS23D06-65-278-02",
        "position": 1,
        "compare_at_price": "4245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XS",
        "option2": "Yellow",
        "option3": null,
        "created_at": "2023-12-08T18:09:16+05:30",
        "updated_at": "2024-07-04T17:27:01+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088964403478,
        "product_id": 8331593777430,
        "title": "S / Yellow",
        "price": "1995.00",
        "sku": "MCSS23D06-65-278-03",
        "position": 2,
        "compare_at_price": "4245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "S",
        "option2": "Yellow",
        "option3": null,
        "created_at": "2023-12-08T18:09:16+05:30",
        "updated_at": "2024-07-04T17:27:01+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088964436246,
        "product_id": 8331593777430,
        "title": "M / Yellow",
        "price": "1995.00",
        "sku": "MCSS23D06-65-278-04",
        "position": 3,
        "compare_at_price": "4245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "M",
        "option2": "Yellow",
        "option3": null,
        "created_at": "2023-12-08T18:09:16+05:30",
        "updated_at": "2024-07-04T17:27:01+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088964469014,
        "product_id": 8331593777430,
        "title": "L / Yellow",
        "price": "1995.00",
        "sku": "MCSS23D06-65-278-05",
        "position": 4,
        "compare_at_price": "4245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "L",
        "option2": "Yellow",
        "option3": null,
        "created_at": "2023-12-08T18:09:16+05:30",
        "updated_at": "2024-07-04T17:27:01+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088964501782,
        "product_id": 8331593777430,
        "title": "XL / Yellow",
        "price": "1995.00",
        "sku": "MCSS23D06-65-278-06",
        "position": 5,
        "compare_at_price": "4245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XL",
        "option2": "Yellow",
        "option3": null,
        "created_at": "2023-12-08T18:09:16+05:30",
        "updated_at": "2024-07-04T17:27:01+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088964534550,
        "product_id": 8331593777430,
        "title": "XXL / Yellow",
        "price": "1995.00",
        "sku": "MCSS23D06-65-278-07",
        "position": 6,
        "compare_at_price": "4245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XXL",
        "option2": "Yellow",
        "option3": null,
        "created_at": "2023-12-08T18:09:16+05:30",
        "updated_at": "2024-07-04T17:27:01+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088964567318,
        "product_id": 8331593777430,
        "title": "3XL / Yellow",
        "price": "1995.00",
        "sku": "MCSS23D06-65-278-08",
        "position": 7,
        "compare_at_price": "4245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "3XL",
        "option2": "Yellow",
        "option3": null,
        "created_at": "2023-12-08T18:09:16+05:30",
        "updated_at": "2024-07-04T17:27:01+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      }
    ],
    "options": [
      {
        "id": 10557194895638,
        "product_id": 8331593777430,
        "name": "Size",
        "position": 1,
        "values": [
          "XS",
          "S",
          "M",
          "L",
          "XL",
          "XXL",
          "3XL"
        ]
      },
      {
        "id": 11427822403862,
        "product_id": 8331593777430,
        "name": "Color",
        "position": 2,
        "values": [
          "Yellow"
        ]
      }
    ],
    "images": [
      {
        "id": 41537341260054,
        "product_id": 8331593777430,
        "position": 1,
        "created_at": "2023-06-19T18:17:31+05:30",
        "updated_at": "2024-06-20T11:29:24+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/1_0ec11de4-0a73-477e-aaa5-ebcdb0980486.jpg?v=1718863164",
        "variant_ids": []
      },
      {
        "id": 41537341227286,
        "product_id": 8331593777430,
        "position": 2,
        "created_at": "2023-06-19T18:17:31+05:30",
        "updated_at": "2024-06-20T11:29:24+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/3_563f191f-6292-4fe9-8aea-cf2f2c733b2a.jpg?v=1718863164",
        "variant_ids": []
      },
      {
        "id": 41537341391126,
        "product_id": 8331593777430,
        "position": 3,
        "created_at": "2023-06-19T18:17:31+05:30",
        "updated_at": "2024-06-20T11:29:24+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/5_d95e5d83-ac6d-4088-87bd-ee75c41963cb.jpg?v=1718863164",
        "variant_ids": []
      },
      {
        "id": 41537341161750,
        "product_id": 8331593777430,
        "position": 4,
        "created_at": "2023-06-19T18:17:31+05:30",
        "updated_at": "2024-06-20T11:29:24+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/2_978b9f23-8eb4-4de6-907c-05479ca59442.jpg?v=1718863164",
        "variant_ids": []
      },
      {
        "id": 41537341194518,
        "product_id": 8331593777430,
        "position": 5,
        "created_at": "2023-06-19T18:17:31+05:30",
        "updated_at": "2024-06-19T17:49:49+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/4_dacbe417-0d9c-4739-9fc6-bd5d10798e7b.jpg?v=1718799589",
        "variant_ids": []
      },
      {
        "id": 41537341128982,
        "product_id": 8331593777430,
        "position": 6,
        "created_at": "2023-06-19T18:17:31+05:30",
        "updated_at": "2023-06-19T18:17:32+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/6_94e91397-d91f-4496-9bbd-0203b64f3fe7.jpg?v=1687178852",
        "variant_ids": []
      }
    ],
    "image": {
      "id": 41537341260054,
      "product_id": 8331593777430,
      "position": 1,
      "created_at": "2023-06-19T18:17:31+05:30",
      "updated_at": "2024-06-20T11:29:24+05:30",
      "alt": null,
      "width": 1080,
      "height": 1440,
      "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/1_0ec11de4-0a73-477e-aaa5-ebcdb0980486.jpg?v=1718863164",
      "variant_ids": []
    }
  },
  {
    "id": 8331593908502,
    "title": "Women's Multicolored-Base-Purple V-Neck Bishop Sleeve Floral Gathered Chiffon Maxi Dress",
    "body_html": "\u003Cp\u003EWine Georgette Fit &amp; Flare Maxi Dress. It has a Sweet heart neckline and 3/4th Sleeves. It has contrasted lining and concealed zipper.\u003C/p\u003E\n\u003Cul\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EColor: \u003C/strong\u003EWine\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EMaterial Composition: \u003C/strong\u003E100% Polyester\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFabric: \u003C/strong\u003EGeorgette\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ELength: \u003C/strong\u003EMaxi\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EPattern: \u003C/strong\u003EFloral\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFit: \u003C/strong\u003ERelaxed Fit\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EOccasion: \u003C/strong\u003EParty\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EKnit/Woven: \u003C/strong\u003EWoven\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EClosure: \u003C/strong\u003EZip\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong style=\"font-size: 0.875rem;\"\u003ENumber of Pockets: \u003C/strong\u003E\u003Cspan style=\"font-size: 0.875rem;\"\u003EYes, 2 side pockets\u003C/span\u003E\n\u003C/li\u003E\n\u003C/ul\u003E",
    "vendor": "Miss Chase",
    "product_type": "Dresses",
    "created_at": "2023-06-19T17:15:07+05:30",
    "handle": "women-s-multicolored-base-purple-v-neck-bishop-sleeve-floral-gathered-chiffon-maxi-dress-mcss23d06-65-190",
    "updated_at": "2024-07-04T17:28:24+05:30",
    "defaultDiscount": "53",
    "published_at": "2023-06-19T17:15:07+05:30",
    "template_suffix": null,
    "published_scope": "global",
    "tags": "3XL, Best Fit, Best Seller, Brunch, Dresses, Floral, L, M, Maxi, Miss Chase, Multicolor, must haves, Pockets, Purple, Rs. 1000 - Rs. 1999, S, shop all, Statement Prints, Statment Prints, sufficient, summer, Topwear, UPTO80, vacation, XL, XS, XXL",
    "variants": [
      {
        "id": 47088963453206,
        "product_id": 8331593908502,
        "title": "XS / Purple",
        "price": "1995.00",
        "sku": "MCSS23D06-65-190-02",
        "position": 1,
        "compare_at_price": "4245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XS",
        "option2": "Purple",
        "option3": null,
        "created_at": "2023-12-08T18:09:13+05:30",
        "updated_at": "2024-07-04T17:28:24+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088963485974,
        "product_id": 8331593908502,
        "title": "S / Purple",
        "price": "1995.00",
        "sku": "MCSS23D06-65-190-03",
        "position": 2,
        "compare_at_price": "4245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "S",
        "option2": "Purple",
        "option3": null,
        "created_at": "2023-12-08T18:09:13+05:30",
        "updated_at": "2024-07-04T17:28:24+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088963518742,
        "product_id": 8331593908502,
        "title": "M / Purple",
        "price": "1995.00",
        "sku": "MCSS23D06-65-190-04",
        "position": 3,
        "compare_at_price": "4245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "M",
        "option2": "Purple",
        "option3": null,
        "created_at": "2023-12-08T18:09:13+05:30",
        "updated_at": "2024-07-04T17:28:24+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088963551510,
        "product_id": 8331593908502,
        "title": "L / Purple",
        "price": "1995.00",
        "sku": "MCSS23D06-65-190-05",
        "position": 4,
        "compare_at_price": "4245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "L",
        "option2": "Purple",
        "option3": null,
        "created_at": "2023-12-08T18:09:13+05:30",
        "updated_at": "2024-07-04T17:28:24+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088963584278,
        "product_id": 8331593908502,
        "title": "XL / Purple",
        "price": "1995.00",
        "sku": "MCSS23D06-65-190-06",
        "position": 5,
        "compare_at_price": "4245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XL",
        "option2": "Purple",
        "option3": null,
        "created_at": "2023-12-08T18:09:13+05:30",
        "updated_at": "2024-07-04T17:28:24+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088963617046,
        "product_id": 8331593908502,
        "title": "XXL / Purple",
        "price": "1995.00",
        "sku": "MCSS23D06-65-190-07",
        "position": 6,
        "compare_at_price": "4245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XXL",
        "option2": "Purple",
        "option3": null,
        "created_at": "2023-12-08T18:09:13+05:30",
        "updated_at": "2024-07-04T17:28:24+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47088963649814,
        "product_id": 8331593908502,
        "title": "3XL / Purple",
        "price": "1995.00",
        "sku": "MCSS23D06-65-190-08",
        "position": 7,
        "compare_at_price": "4245.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "3XL",
        "option2": "Purple",
        "option3": null,
        "created_at": "2023-12-08T18:09:13+05:30",
        "updated_at": "2024-07-04T17:28:24+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "g",
        "requires_shipping": true
      }
    ],
    "options": [
      {
        "id": 10557195059478,
        "product_id": 8331593908502,
        "name": "Size",
        "position": 1,
        "values": [
          "XS",
          "S",
          "M",
          "L",
          "XL",
          "XXL",
          "3XL"
        ]
      },
      {
        "id": 11427822305558,
        "product_id": 8331593908502,
        "name": "Color",
        "position": 2,
        "values": [
          "Purple"
        ]
      }
    ],
    "images": [
      {
        "id": 41537346306326,
        "product_id": 8331593908502,
        "position": 1,
        "created_at": "2023-06-19T18:18:32+05:30",
        "updated_at": "2024-04-24T18:25:08+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/1_03eba4a3-895e-41de-a5d4-e6984fba6f2b.jpg?v=1713963308",
        "variant_ids": []
      },
      {
        "id": 44064549896470,
        "product_id": 8331593908502,
        "position": 2,
        "created_at": "2024-03-29T12:37:10+05:30",
        "updated_at": "2024-04-24T18:25:08+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/image_62.png?v=1713963308",
        "variant_ids": []
      },
      {
        "id": 41537346044182,
        "product_id": 8331593908502,
        "position": 3,
        "created_at": "2023-06-19T18:18:32+05:30",
        "updated_at": "2024-04-24T18:25:08+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/0_d3ebd73a-7012-4392-9ee6-361b6ab8c43b.jpg?v=1713963308",
        "variant_ids": []
      },
      {
        "id": 41537346240790,
        "product_id": 8331593908502,
        "position": 4,
        "created_at": "2023-06-19T18:18:32+05:30",
        "updated_at": "2024-04-24T18:25:08+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/6_0f4efffc-c366-4b2b-a073-cb7ebbc0efa7.jpg?v=1713963308",
        "variant_ids": []
      },
      {
        "id": 41537346109718,
        "product_id": 8331593908502,
        "position": 5,
        "created_at": "2023-06-19T18:18:32+05:30",
        "updated_at": "2024-04-10T13:49:06+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/3_b2e41053-e476-4399-a4f9-5f68670a6d93.jpg?v=1712737146",
        "variant_ids": []
      },
      {
        "id": 41537346339094,
        "product_id": 8331593908502,
        "position": 6,
        "created_at": "2023-06-19T18:18:32+05:30",
        "updated_at": "2024-03-29T12:37:15+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/5_45f7925a-cb96-4fc9-9baf-cd02f8adeb21.jpg?v=1711696035",
        "variant_ids": []
      },
      {
        "id": 41537346175254,
        "product_id": 8331593908502,
        "position": 7,
        "created_at": "2023-06-19T18:18:32+05:30",
        "updated_at": "2024-03-29T12:37:15+05:30",
        "alt": null,
        "width": 1080,
        "height": 1440,
        "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/7_196b90e1-175b-4ccb-8ee0-c358d60271f8.jpg?v=1711696035",
        "variant_ids": []
      }
    ],
    "image": {
      "id": 41537346306326,
      "product_id": 8331593908502,
      "position": 1,
      "created_at": "2023-06-19T18:18:32+05:30",
      "updated_at": "2024-04-24T18:25:08+05:30",
      "alt": null,
      "width": 1080,
      "height": 1440,
      "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/1_03eba4a3-895e-41de-a5d4-e6984fba6f2b.jpg?v=1713963308",
      "variant_ids": []
    }
  }
]


export const baniWomenProducts = [
  {
    "id": 8881741955360,
    "title": "Women Printed Co-Ord Set Teal",
    "body_html": "\u003Cp\u003EWhether you're heading to brunch or a night out, our co-ord sets for women are sure to turn heads. Made from cotton blend fabric, choose from a range of solid colours like navy blue, lilac, red &amp; more or add a touch of whimsy with our printed co-ord sets. It's time to embrace comfort &amp; elegance together.\u003C/p\u003E",
    "vendor": "CHESHTAA APPARELS INDIA PVT. LTD.",
    "product_type": "Co-Ord Set",
    "created_at": "2023-11-23T15:33:57+05:30",
    "handle": "bw7073-women-printed-co-ord-set-teal",
    "updated_at": "2024-07-11T16:21:48+05:30",
    "published_at": "2023-11-23T16:31:06+05:30",
    "defaultDiscount": "50",
    "template_suffix": null,
    "published_scope": "web",
    "tags": "Co-Ord Set, Co-Ord Set with Belt, Cotton Belnd, Hand Block, Night Suits Set, Orange, Printed, Printed Co-Ord Set, Tie Dye Co-Ord Set",
    "variants": [
      {
        "id": 47200517587232,
        "product_id": 8881741955360,
        "title": "XS",
        "price": "1499.00",
        "sku": "BW7073TEAL_COD-XS",
        "position": 1,
        "compare_at_price": "2999.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XS",
        "option2": null,
        "option3": null,
        "created_at": "2023-11-23T15:33:57+05:30",
        "updated_at": "2024-07-11T16:21:48+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 280,
        "image_id": null,
        "weight": 280,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47200517620000,
        "product_id": 8881741955360,
        "title": "S",
        "price": "1499.00",
        "sku": "BW7073TEAL_COD-S",
        "position": 2,
        "compare_at_price": "2999.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "S",
        "option2": null,
        "option3": null,
        "created_at": "2023-11-23T15:33:57+05:30",
        "updated_at": "2024-07-11T16:21:48+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 280,
        "image_id": null,
        "weight": 280,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47200517652768,
        "product_id": 8881741955360,
        "title": "M",
        "price": "1499.00",
        "sku": "BW7073TEAL_COD-M",
        "position": 3,
        "compare_at_price": "2999.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "M",
        "option2": null,
        "option3": null,
        "created_at": "2023-11-23T15:33:57+05:30",
        "updated_at": "2024-07-11T16:21:48+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 280,
        "image_id": null,
        "weight": 280,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47200517685536,
        "product_id": 8881741955360,
        "title": "L",
        "price": "1499.00",
        "sku": "BW7073TEAL_COD-L",
        "position": 4,
        "compare_at_price": "2999.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "L",
        "option2": null,
        "option3": null,
        "created_at": "2023-11-23T15:33:57+05:30",
        "updated_at": "2024-07-11T16:21:48+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 280,
        "image_id": null,
        "weight": 280,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47200517718304,
        "product_id": 8881741955360,
        "title": "XL",
        "price": "1499.00",
        "sku": "BW7073TEAL_COD-XL",
        "position": 5,
        "compare_at_price": "2999.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XL",
        "option2": null,
        "option3": null,
        "created_at": "2023-11-23T15:33:57+05:30",
        "updated_at": "2024-07-11T16:21:48+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 280,
        "image_id": null,
        "weight": 280,
        "weight_unit": "g",
        "requires_shipping": true
      },
      {
        "id": 47200517751072,
        "product_id": 8881741955360,
        "title": "2XL",
        "price": "1499.00",
        "sku": "BW7073TEAL_COD-XXL",
        "position": 6,
        "compare_at_price": "2999.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "2XL",
        "option2": null,
        "option3": null,
        "created_at": "2023-11-23T15:33:57+05:30",
        "updated_at": "2024-07-11T16:21:48+05:30",
        "taxable": true,
        "barcode": null,
        "grams": 280,
        "image_id": null,
        "weight": 280,
        "weight_unit": "g",
        "requires_shipping": true
      }
    ],
    "options": [
      {
        "id": 11207292879136,
        "product_id": 8881741955360,
        "name": "Size",
        "position": 1,
        "values": [
          "XS",
          "S",
          "M",
          "L",
          "XL",
          "2XL"
        ]
      }
    ],
    "images": [
      {
        "id": 43475303760160,
        "product_id": 8881741955360,
        "position": 1,
        "created_at": "2023-11-23T16:31:06+05:30",
        "updated_at": "2023-11-23T16:31:08+05:30",
        "alt": null,
        "width": 1500,
        "height": 2000,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7073TEAL_COD-1.jpg?v=1700737268",
        "variant_ids": []
      },
      {
        "id": 43475303727392,
        "product_id": 8881741955360,
        "position": 2,
        "created_at": "2023-11-23T16:31:06+05:30",
        "updated_at": "2023-11-23T16:31:07+05:30",
        "alt": null,
        "width": 1500,
        "height": 2000,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7073TEAL_COD-2.jpg?v=1700737267",
        "variant_ids": []
      },
      {
        "id": 43475303825696,
        "product_id": 8881741955360,
        "position": 3,
        "created_at": "2023-11-23T16:31:06+05:30",
        "updated_at": "2023-11-23T16:31:08+05:30",
        "alt": null,
        "width": 1500,
        "height": 2000,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7073TEAL_COD-3.jpg?v=1700737268",
        "variant_ids": []
      },
      {
        "id": 43475303792928,
        "product_id": 8881741955360,
        "position": 4,
        "created_at": "2023-11-23T16:31:06+05:30",
        "updated_at": "2023-11-23T16:31:08+05:30",
        "alt": null,
        "width": 1500,
        "height": 2000,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7073TEAL_COD4.jpg?v=1700737268",
        "variant_ids": []
      },
      {
        "id": 43475303596320,
        "product_id": 8881741955360,
        "position": 5,
        "created_at": "2023-11-23T16:31:06+05:30",
        "updated_at": "2023-11-23T16:31:07+05:30",
        "alt": null,
        "width": 1500,
        "height": 2000,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7073TEAL_COD-5.jpg?v=1700737267",
        "variant_ids": []
      },
      {
        "id": 43475303629088,
        "product_id": 8881741955360,
        "position": 6,
        "created_at": "2023-11-23T16:31:06+05:30",
        "updated_at": "2023-11-23T16:31:07+05:30",
        "alt": null,
        "width": 1500,
        "height": 2000,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7073TEAL_COD6.jpg?v=1700737267",
        "variant_ids": []
      },
      {
        "id": 43475303694624,
        "product_id": 8881741955360,
        "position": 7,
        "created_at": "2023-11-23T16:31:06+05:30",
        "updated_at": "2023-11-23T16:31:07+05:30",
        "alt": null,
        "width": 1500,
        "height": 2000,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7073TEAL_COD-7.jpg?v=1700737267",
        "variant_ids": []
      }
    ],
    "image": {
      "id": 43475303760160,
      "product_id": 8881741955360,
      "position": 1,
      "created_at": "2023-11-23T16:31:06+05:30",
      "updated_at": "2023-11-23T16:31:08+05:30",
      "alt": null,
      "width": 1500,
      "height": 2000,
      "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7073TEAL_COD-1.jpg?v=1700737268",
      "variant_ids": []
    }
  },
  {
    "id": 9088946077984,
    "title": "Bani Women Printed Co-Ord Set",
    "body_html": "\u003Cp\u003E\u003C/p\u003E",
    "vendor": "Bani Women",
    "product_type": "Co-Ord Set",
    "created_at": "2024-03-16T13:39:47+05:30",
    "handle": "bani-women-printed-co-ord-set-short-kurti-and-trouser-set-2-piece-outfit",
    "updated_at": "2024-07-11T16:23:06+05:30",
    "published_at": "2024-03-16T13:39:47+05:30",
    "template_suffix": "",
    "defaultDiscount": "60",
    "published_scope": "web",
    "tags": "Co-Ord Set, Printed, Red, Silk Blend",
    "variants": [
      {
        "id": 47849017966880,
        "product_id": 9088946077984,
        "title": "S",
        "price": "1479.00",
        "sku": "BW8062FUSH_COD-1",
        "position": 1,
        "compare_at_price": "3699.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "S",
        "option2": null,
        "option3": null,
        "created_at": "2024-03-16T13:39:49+05:30",
        "updated_at": "2024-07-11T16:23:06+05:30",
        "taxable": true,
        "barcode": "",
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47849017999648,
        "product_id": 9088946077984,
        "title": "M",
        "price": "1479.00",
        "sku": "BW8062FUSH_COD-2",
        "position": 2,
        "compare_at_price": "3699.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "M",
        "option2": null,
        "option3": null,
        "created_at": "2024-03-16T13:39:49+05:30",
        "updated_at": "2024-07-11T16:23:06+05:30",
        "taxable": true,
        "barcode": "",
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47849018032416,
        "product_id": 9088946077984,
        "title": "L",
        "price": "1479.00",
        "sku": "BW8062FUSH_COD-3",
        "position": 3,
        "compare_at_price": "3699.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "L",
        "option2": null,
        "option3": null,
        "created_at": "2024-03-16T13:39:49+05:30",
        "updated_at": "2024-07-11T16:23:06+05:30",
        "taxable": true,
        "barcode": "",
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47849018065184,
        "product_id": 9088946077984,
        "title": "XL",
        "price": "1479.00",
        "sku": "BW8062FUSH_COD-4",
        "position": 4,
        "compare_at_price": "3699.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XL",
        "option2": null,
        "option3": null,
        "created_at": "2024-03-16T13:39:49+05:30",
        "updated_at": "2024-07-11T16:23:06+05:30",
        "taxable": true,
        "barcode": "",
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47849018097952,
        "product_id": 9088946077984,
        "title": "XXL",
        "price": "1479.00",
        "sku": "BW8062FUSH_COD-5",
        "position": 5,
        "compare_at_price": "3699.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XXL",
        "option2": null,
        "option3": null,
        "created_at": "2024-03-16T13:39:49+05:30",
        "updated_at": "2024-07-11T16:23:06+05:30",
        "taxable": true,
        "barcode": "",
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      }
    ],
    "options": [
      {
        "id": 11444550041888,
        "product_id": 9088946077984,
        "name": "Size",
        "position": 1,
        "values": [
          "S",
          "M",
          "L",
          "XL",
          "XXL"
        ]
      }
    ],
    "images": [
      {
        "id": 44710054068512,
        "product_id": 9088946077984,
        "position": 1,
        "created_at": "2024-03-16T13:39:47+05:30",
        "updated_at": "2024-03-16T13:39:51+05:30",
        "alt": null,
        "width": 1200,
        "height": 1600,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW8062FUSH_COD-1.jpg?v=1710576591",
        "variant_ids": []
      },
      {
        "id": 44710054134048,
        "product_id": 9088946077984,
        "position": 2,
        "created_at": "2024-03-16T13:39:47+05:30",
        "updated_at": "2024-03-16T13:39:52+05:30",
        "alt": null,
        "width": 1200,
        "height": 1600,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW8062FUSH_COD-2.jpg?v=1710576592",
        "variant_ids": []
      },
      {
        "id": 44710054002976,
        "product_id": 9088946077984,
        "position": 3,
        "created_at": "2024-03-16T13:39:47+05:30",
        "updated_at": "2024-03-16T13:39:50+05:30",
        "alt": null,
        "width": 1200,
        "height": 1600,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW8062FUSH_COD-3.jpg?v=1710576590",
        "variant_ids": []
      },
      {
        "id": 44710054035744,
        "product_id": 9088946077984,
        "position": 4,
        "created_at": "2024-03-16T13:39:47+05:30",
        "updated_at": "2024-03-16T13:39:50+05:30",
        "alt": null,
        "width": 1200,
        "height": 1600,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW8062FUSH_COD-4.jpg?v=1710576590",
        "variant_ids": []
      },
      {
        "id": 44710054101280,
        "product_id": 9088946077984,
        "position": 5,
        "created_at": "2024-03-16T13:39:47+05:30",
        "updated_at": "2024-03-16T13:39:51+05:30",
        "alt": null,
        "width": 1200,
        "height": 1600,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW8062FUSH_COD-5.jpg?v=1710576591",
        "variant_ids": []
      },
      {
        "id": 44710053970208,
        "product_id": 9088946077984,
        "position": 6,
        "created_at": "2024-03-16T13:39:47+05:30",
        "updated_at": "2024-03-16T13:39:49+05:30",
        "alt": null,
        "width": 1200,
        "height": 1600,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW8062FUSH_COD-7.jpg?v=1710576589",
        "variant_ids": []
      }
    ],
    "image": {
      "id": 44710054068512,
      "product_id": 9088946077984,
      "position": 1,
      "created_at": "2024-03-16T13:39:47+05:30",
      "updated_at": "2024-03-16T13:39:51+05:30",
      "alt": null,
      "width": 1200,
      "height": 1600,
      "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW8062FUSH_COD-1.jpg?v=1710576591",
      "variant_ids": []
    }
  },
  {
    "id": 9114684555552,
    "title": "Bani Women Floral Printed Pure Cotton Kurta with Trousers",
    "body_html": "\u003Cp\u003E\u003C/p\u003E",
    "vendor": "CHESHTAA APPARELS INDIA PVT. LTD.",
    "product_type": "Kurta Sets",
    "defaultDiscount": "50",
    "noViews": "12",
    "noPurchases": "2.5",
    "created_at": "2024-03-26T10:28:11+05:30",
    "handle": "bani-women-floral-printed-pure-cotton-kurta-with-trousers",
    "updated_at": "2024-07-11T16:24:53+05:30",
    "published_at": "2024-03-26T10:28:11+05:30",
    "template_suffix": "",
    "published_scope": "web",
    "tags": "Cotton, Floral, Mauve, Printed, Pure Cotton, V-Neck",
    "variants": [
      {
        "id": 47923178635552,
        "product_id": 9114684555552,
        "title": "S",
        "price": "1749.00",
        "sku": "BW7067MAUVE_SET-1",
        "position": 1,
        "compare_at_price": "3499.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "S",
        "option2": null,
        "option3": null,
        "created_at": "2024-03-26T10:28:14+05:30",
        "updated_at": "2024-07-11T16:24:53+05:30",
        "taxable": true,
        "barcode": "True",
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47923178668320,
        "product_id": 9114684555552,
        "title": "M",
        "price": "1749.00",
        "sku": "BW7067MAUVE_SET-2",
        "position": 2,
        "compare_at_price": "3499.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "M",
        "option2": null,
        "option3": null,
        "created_at": "2024-03-26T10:28:14+05:30",
        "updated_at": "2024-07-11T16:24:53+05:30",
        "taxable": true,
        "barcode": "",
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47923178701088,
        "product_id": 9114684555552,
        "title": "L",
        "price": "1749.00",
        "sku": "BW7067MAUVE_SET-3",
        "position": 3,
        "compare_at_price": "3499.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "L",
        "option2": null,
        "option3": null,
        "created_at": "2024-03-26T10:28:14+05:30",
        "updated_at": "2024-07-11T16:24:53+05:30",
        "taxable": true,
        "barcode": "",
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47923178733856,
        "product_id": 9114684555552,
        "title": "XL",
        "price": "1749.00",
        "sku": "BW7067MAUVE_SET-4",
        "position": 4,
        "compare_at_price": "3499.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XL",
        "option2": null,
        "option3": null,
        "created_at": "2024-03-26T10:28:14+05:30",
        "updated_at": "2024-07-11T16:24:53+05:30",
        "taxable": true,
        "barcode": "",
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      },
      {
        "id": 47923178766624,
        "product_id": 9114684555552,
        "title": "XXL",
        "price": "1749.00",
        "sku": "BW7067MAUVE_SET-5",
        "position": 5,
        "compare_at_price": "3499.00",
        "fulfillment_service": "manual",
        "inventory_management": "shopify",
        "option1": "XXL",
        "option2": null,
        "option3": null,
        "created_at": "2024-03-26T10:28:14+05:30",
        "updated_at": "2024-07-11T16:24:53+05:30",
        "taxable": true,
        "barcode": "",
        "grams": 0,
        "image_id": null,
        "weight": 0,
        "weight_unit": "kg",
        "requires_shipping": true
      }
    ],
    "options": [
      {
        "id": 11473513578784,
        "product_id": 9114684555552,
        "name": "Size",
        "position": 1,
        "values": [
          "S",
          "M",
          "L",
          "XL",
          "XXL"
        ]
      }
    ],
    "images": [
      {
        "id": 44829514891552,
        "product_id": 9114684555552,
        "position": 1,
        "created_at": "2024-03-26T10:28:11+05:30",
        "updated_at": "2024-03-26T10:28:13+05:30",
        "alt": null,
        "width": 1500,
        "height": 2000,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7067MAUVE_SET-1_f7acf967-711f-48a0-a1ef-58a99eb564fc.jpg?v=1711429093",
        "variant_ids": []
      },
      {
        "id": 44829515186464,
        "product_id": 9114684555552,
        "position": 2,
        "created_at": "2024-03-26T10:28:11+05:30",
        "updated_at": "2024-03-26T10:28:14+05:30",
        "alt": null,
        "width": 1500,
        "height": 2000,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7067MAUVE_SET-0.jpg?v=1711429094",
        "variant_ids": []
      },
      {
        "id": 44829515219232,
        "product_id": 9114684555552,
        "position": 3,
        "created_at": "2024-03-26T10:28:11+05:30",
        "updated_at": "2024-03-26T10:28:15+05:30",
        "alt": null,
        "width": 1500,
        "height": 2000,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7067MAUVE_SET-3.jpg?v=1711429095",
        "variant_ids": []
      },
      {
        "id": 44829515252000,
        "product_id": 9114684555552,
        "position": 4,
        "created_at": "2024-03-26T10:28:11+05:30",
        "updated_at": "2024-03-26T10:28:15+05:30",
        "alt": null,
        "width": 1500,
        "height": 2000,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7067MAUVE_SET-4.jpg?v=1711429095",
        "variant_ids": []
      },
      {
        "id": 44829515350304,
        "product_id": 9114684555552,
        "position": 5,
        "created_at": "2024-03-26T10:28:11+05:30",
        "updated_at": "2024-03-26T10:28:15+05:30",
        "alt": null,
        "width": 1500,
        "height": 2000,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7067MAUVE_SET-5.jpg?v=1711429095",
        "variant_ids": []
      },
      {
        "id": 44829514957088,
        "product_id": 9114684555552,
        "position": 6,
        "created_at": "2024-03-26T10:28:11+05:30",
        "updated_at": "2024-03-26T10:28:13+05:30",
        "alt": null,
        "width": 1500,
        "height": 2000,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7067MAUVE_SET-6.jpg?v=1711429093",
        "variant_ids": []
      },
      {
        "id": 44829515055392,
        "product_id": 9114684555552,
        "position": 7,
        "created_at": "2024-03-26T10:28:11+05:30",
        "updated_at": "2024-03-26T10:28:13+05:30",
        "alt": null,
        "width": 1500,
        "height": 2000,
        "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7067MAUVE_SET-7.jpg?v=1711429093",
        "variant_ids": []
      }
    ],
    "image": {
      "id": 44829514891552,
      "product_id": 9114684555552,
      "position": 1,
      "created_at": "2024-03-26T10:28:11+05:30",
      "updated_at": "2024-03-26T10:28:13+05:30",
      "alt": null,
      "width": 1500,
      "height": 2000,
      "src": "https://cdn.shopify.com/s/files/1/0735/1484/4448/files/BW7067MAUVE_SET-1_f7acf967-711f-48a0-a1ef-58a99eb564fc.jpg?v=1711429093",
      "variant_ids": []
    }
  }
]