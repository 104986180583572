export const chaseHaulProducts3 = [
    {
        "id": 8310649159958,
        "title": "Women's Yellow & White V neck Full sleeve Floral Layered Maxi Dress",
        "body_html": "\u003Cp\u003EYellow &amp; White Polyester Floral Layered Maxi Dress. Has a V-neck and full sleeves.\u003C/p\u003E\n\u003Cul\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EColor: \u003C/strong\u003EYellow &amp; White\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EMaterial Composition: \u003C/strong\u003E100% Polyester\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EGender: \u003C/strong\u003EWomen\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFabric: \u003C/strong\u003EPolyester\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ELength: \u003C/strong\u003EMaxi\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EPattern: \u003C/strong\u003EFloral\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFit: \u003C/strong\u003ERelaxed Fit\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EOccasion: \u003C/strong\u003ECasual\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EYear: \u003C/strong\u003E2022\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ESeason: \u003C/strong\u003EAW22\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EKnit/Woven: \u003C/strong\u003EWoven\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EClosure: \u003C/strong\u003EZipper\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ECollar: \u003C/strong\u003EV neck\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ESleeve: \u003C/strong\u003EFull Sleeves\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ENeck: \u003C/strong\u003EV neck\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ENumber of Pockets: \u003C/strong\u003E2 Side Pockets\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EWash Care: \u003C/strong\u003EHand Wash with Warm Water, wash dark colors separately, Do not Bleach, Dip Dry away from direct heat, Warm Iron Only\u003C/li\u003E\n\u003C/ul\u003E",
        "vendor": "Miss Chase",
        "product_type": "Dresses",
        "created_at": "2023-06-08T18:10:01+05:30",
        "handle": "women-s-yellow-white-v-neck-full-sleeve-floral-layered-maxi-dress-mcaw21d14-94-37",
        "updated_at": "2024-07-15T19:04:58+05:30",
        "published_at": "2024-06-19T13:12:00+05:30",
        "template_suffix": null,
        "published_scope": "global",
        'defaultDiscount': "42",
        "tags": "3XL, 4XL, Asymmetric dress, Best Fit, Best Seller, Brunch, Dresses, Floral, L, M, Maxi, Miss Chase, must haves, Pockets, Printed, Rs. 1000 - Rs. 1999, S, shop all, social proof, Statment Prints, sufficient, summer, Topwear, UPTO80, vacation, Women's Western Wear, XL, XS, XXL, Yellow",
        "variants": [
          {
            "id": 47088970826006,
            "product_id": 8310649159958,
            "title": "XS / Yellow & White",
            "price": "1389.00",
            "sku": "MCAW21D14-94-37-02",
            "position": 1,
            "compare_at_price": "2395.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "XS",
            "option2": "Yellow & White",
            "option3": null,
            "created_at": "2023-12-08T18:10:39+05:30",
            "updated_at": "2024-07-15T19:04:58+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          },
          {
            "id": 47088970858774,
            "product_id": 8310649159958,
            "title": "S / Yellow & White",
            "price": "1389.00",
            "sku": "MCAW21D14-94-37-03",
            "position": 2,
            "compare_at_price": "2395.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "S",
            "option2": "Yellow & White",
            "option3": null,
            "created_at": "2023-12-08T18:10:39+05:30",
            "updated_at": "2024-07-15T19:04:58+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          },
          {
            "id": 47088970891542,
            "product_id": 8310649159958,
            "title": "M / Yellow & White",
            "price": "1389.00",
            "sku": "MCAW21D14-94-37-04",
            "position": 3,
            "compare_at_price": "2395.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "M",
            "option2": "Yellow & White",
            "option3": null,
            "created_at": "2023-12-08T18:10:39+05:30",
            "updated_at": "2024-07-15T19:04:58+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          },
          {
            "id": 47088970924310,
            "product_id": 8310649159958,
            "title": "L / Yellow & White",
            "price": "1389.00",
            "sku": "MCAW21D14-94-37-05",
            "position": 4,
            "compare_at_price": "2395.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "L",
            "option2": "Yellow & White",
            "option3": null,
            "created_at": "2023-12-08T18:10:39+05:30",
            "updated_at": "2024-07-15T19:04:58+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          },
          {
            "id": 47088970957078,
            "product_id": 8310649159958,
            "title": "XL / Yellow & White",
            "price": "1389.00",
            "sku": "MCAW21D14-94-37-06",
            "position": 5,
            "compare_at_price": "2395.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "XL",
            "option2": "Yellow & White",
            "option3": null,
            "created_at": "2023-12-08T18:10:39+05:30",
            "updated_at": "2024-07-15T19:04:58+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          },
          {
            "id": 47239732298006,
            "product_id": 8310649159958,
            "title": "XXL / Yellow & White",
            "price": "1389.00",
            "sku": "MCAW21D14-94-37-07",
            "position": 6,
            "compare_at_price": "2395.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "XXL",
            "option2": "Yellow & White",
            "option3": null,
            "created_at": "2024-01-05T14:49:27+05:30",
            "updated_at": "2024-07-15T19:04:58+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          },
          {
            "id": 47239732330774,
            "product_id": 8310649159958,
            "title": "3XL / Yellow & White",
            "price": "1389.00",
            "sku": "MCAW21D14-94-37-08",
            "position": 7,
            "compare_at_price": "2395.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "3XL",
            "option2": "Yellow & White",
            "option3": null,
            "created_at": "2024-01-05T14:49:27+05:30",
            "updated_at": "2024-07-15T19:04:58+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          },
          {
            "id": 47239732363542,
            "product_id": 8310649159958,
            "title": "4XL / Yellow & White",
            "price": "1389.00",
            "sku": "MCAW21D14-94-37-09",
            "position": 8,
            "compare_at_price": "2395.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "4XL",
            "option2": "Yellow & White",
            "option3": null,
            "created_at": "2024-01-05T14:49:27+05:30",
            "updated_at": "2024-07-15T19:04:58+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          }
        ],
        "options": [
          {
            "id": 10532690657558,
            "product_id": 8310649159958,
            "name": "Size",
            "position": 1,
            "values": [
              "XS",
              "S",
              "M",
              "L",
              "XL",
              "XXL",
              "3XL",
              "4XL"
            ]
          },
          {
            "id": 11427823681814,
            "product_id": 8310649159958,
            "name": "Color",
            "position": 2,
            "values": [
              "Yellow & White"
            ]
          }
        ],
        "images": [
          {
            "id": 45087331778838,
            "product_id": 8310649159958,
            "position": 1,
            "created_at": "2024-06-28T12:21:52+05:30",
            "updated_at": "2024-06-28T12:21:54+05:30",
            "alt": null,
            "width": 1080,
            "height": 1440,
            "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/image_10_61c70fcc-2f7a-44d3-b53e-0bf2c936aecb.png?v=1719557514",
            "variant_ids": []
          },
          {
            "id": 43562141450518,
            "product_id": 8310649159958,
            "position": 2,
            "created_at": "2024-02-08T19:58:51+05:30",
            "updated_at": "2024-06-28T12:22:05+05:30",
            "alt": null,
            "width": 1080,
            "height": 1440,
            "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/1_a9694f88-af3b-45ee-90bf-f22402cf56e3.jpg?v=1719557525",
            "variant_ids": []
          },
          {
            "id": 42413734494486,
            "product_id": 8310649159958,
            "position": 3,
            "created_at": "2023-09-19T12:50:56+05:30",
            "updated_at": "2024-06-28T12:22:05+05:30",
            "alt": null,
            "width": 1080,
            "height": 1440,
            "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/6-Copy.jpg?v=1719557525",
            "variant_ids": []
          },
          {
            "id": 42413734134038,
            "product_id": 8310649159958,
            "position": 4,
            "created_at": "2023-09-19T12:50:56+05:30",
            "updated_at": "2024-06-28T12:22:05+05:30",
            "alt": null,
            "width": 1080,
            "height": 1440,
            "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/3_7f7f36fb-c2a4-4476-9497-028571189ab1.jpg?v=1719557525",
            "variant_ids": []
          },
          {
            "id": 42413734330646,
            "product_id": 8310649159958,
            "position": 5,
            "created_at": "2023-09-19T12:50:56+05:30",
            "updated_at": "2024-06-28T12:22:05+05:30",
            "alt": null,
            "width": 1080,
            "height": 1440,
            "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/5_86d5404c-b3ce-4f55-852a-07fd79b5867c.jpg?v=1719557525",
            "variant_ids": []
          },
          {
            "id": 42413734396182,
            "product_id": 8310649159958,
            "position": 6,
            "created_at": "2023-09-19T12:50:56+05:30",
            "updated_at": "2024-06-28T12:22:05+05:30",
            "alt": null,
            "width": 1080,
            "height": 1440,
            "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/9copy_226029ae-ed45-4d13-981a-ce223fdf29ea.jpg?v=1719557525",
            "variant_ids": []
          }
        ],
        "image": {
          "id": 45087331778838,
          "product_id": 8310649159958,
          "position": 1,
          "created_at": "2024-06-28T12:21:52+05:30",
          "updated_at": "2024-06-28T12:21:54+05:30",
          "alt": null,
          "width": 1080,
          "height": 1440,
          "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/image_10_61c70fcc-2f7a-44d3-b53e-0bf2c936aecb.png?v=1719557514",
          "variant_ids": []
        }
      },
      {
        "id": 8015062368534,
        "title": "Women's Multicolored-Base-Maroon V Neck Flared Sleeve Floral Wrap Maxi Dress",
        "body_html": "\u003Cul\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EColor: \u003C/strong\u003EMulticolored\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EMaterial Composition: \u003C/strong\u003E100% Polyester\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFabric: \u003C/strong\u003EPolyester\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ELength: \u003C/strong\u003EMaxi\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EPattern: \u003C/strong\u003EFloral\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EFit: \u003C/strong\u003ERelaxed Fit\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EOccasion: \u003C/strong\u003ECasual Wear\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ESeason: \u003C/strong\u003E Winter\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EKnit/Woven: \u003C/strong\u003EWoven\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EClosure: \u003C/strong\u003EConcealed Zipper\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ESleeve: \u003C/strong\u003EShort Sleeve\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ENeck: \u003C/strong\u003EV Neck\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003ENumber of Pockets: \u003C/strong\u003EYes, 2 pockets\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EWash Care: \u003C/strong\u003EHand Wash with Warm Water wash dark colors separately Do not Bleach Dip Dry away from direct heat Warm Iron Only\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EArticle No.: \u003C/strong\u003EMCAW21D06-14\u003C/li\u003E\n\u003Cli\u003E\n\u003Cstrong\u003EStyle: \u003C/strong\u003EMCAW21D06-14-224\u003C/li\u003E\n\u003C/ul\u003E",
        "vendor": "Miss Chase",
        "product_type": "Dresses",
        "created_at": "2022-11-18T17:53:51+05:30",
        "handle": "women-multicolored-base-maroon-v-neck-flared-sleeve-floral-wrap-maxi-dress-mcaw21d06-14-224",
        "updated_at": "2024-07-15T19:05:52+05:30",
        "published_at": "2024-06-19T13:12:00+05:30",
        'defaultDiscount': '39',
        "template_suffix": null,
        "published_scope": "global",
        "tags": "3XL, 4XL, Best Fit, Best Seller, Brand, Brunch, Dresses, Floral, L, M, Maxi, Miss Chase, Multicolor, must haves, Non Discounted, Pockets, Printed, Rs. 1000 - Rs. 1999, S, shop all, Statment Prints, sufficient, summer, Topwear, UPTO80, vacation, Women's Western Wear, XL, XS, XXL",
        "variants": [
          {
            "id": 47089303617814,
            "product_id": 8015062368534,
            "title": "XS / Multicolored",
            "price": "1398.00",
            "sku": "MCAW21D06-14-224-02",
            "position": 1,
            "compare_at_price": "2299.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "XS",
            "option2": "Multicolored",
            "option3": null,
            "created_at": "2023-12-08T18:56:42+05:30",
            "updated_at": "2024-07-15T19:05:52+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          },
          {
            "id": 47089303650582,
            "product_id": 8015062368534,
            "title": "S / Multicolored",
            "price": "1398.00",
            "sku": "MCAW21D06-14-224-03",
            "position": 2,
            "compare_at_price": "2299.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "S",
            "option2": "Multicolored",
            "option3": null,
            "created_at": "2023-12-08T18:56:42+05:30",
            "updated_at": "2024-07-15T19:05:52+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          },
          {
            "id": 47089303683350,
            "product_id": 8015062368534,
            "title": "M / Multicolored",
            "price": "1398.00",
            "sku": "MCAW21D06-14-224-04",
            "position": 3,
            "compare_at_price": "2299.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "M",
            "option2": "Multicolored",
            "option3": null,
            "created_at": "2023-12-08T18:56:42+05:30",
            "updated_at": "2024-07-15T19:05:52+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          },
          {
            "id": 47089303716118,
            "product_id": 8015062368534,
            "title": "L / Multicolored",
            "price": "1398.00",
            "sku": "MCAW21D06-14-224-05",
            "position": 4,
            "compare_at_price": "2299.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "L",
            "option2": "Multicolored",
            "option3": null,
            "created_at": "2023-12-08T18:56:42+05:30",
            "updated_at": "2024-07-15T19:05:52+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          },
          {
            "id": 47089303748886,
            "product_id": 8015062368534,
            "title": "XL / Multicolored",
            "price": "1398.00",
            "sku": "MCAW21D06-14-224-06",
            "position": 5,
            "compare_at_price": "2299.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "XL",
            "option2": "Multicolored",
            "option3": null,
            "created_at": "2023-12-08T18:56:42+05:30",
            "updated_at": "2024-07-15T19:05:52+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          },
          {
            "id": 47089303781654,
            "product_id": 8015062368534,
            "title": "XXL / Multicolored",
            "price": "1398.00",
            "sku": "MCAW21D06-14-224-07",
            "position": 6,
            "compare_at_price": "2299.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "XXL",
            "option2": "Multicolored",
            "option3": null,
            "created_at": "2023-12-08T18:56:42+05:30",
            "updated_at": "2024-07-15T19:05:52+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          },
          {
            "id": 47089303814422,
            "product_id": 8015062368534,
            "title": "3XL / Multicolored",
            "price": "1398.00",
            "sku": "MCAW21D06-14-224-08",
            "position": 7,
            "compare_at_price": "2299.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "3XL",
            "option2": "Multicolored",
            "option3": null,
            "created_at": "2023-12-08T18:56:42+05:30",
            "updated_at": "2024-07-15T19:05:52+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          },
          {
            "id": 47089303847190,
            "product_id": 8015062368534,
            "title": "4XL / Multicolored",
            "price": "1398.00",
            "sku": "MCAW21D06-14-224-09",
            "position": 8,
            "compare_at_price": "2299.00",
            "fulfillment_service": "manual",
            "inventory_management": "shopify",
            "option1": "4XL",
            "option2": "Multicolored",
            "option3": null,
            "created_at": "2023-12-08T18:56:42+05:30",
            "updated_at": "2024-07-15T19:05:52+05:30",
            "taxable": true,
            "barcode": null,
            "grams": 0,
            "image_id": null,
            "weight": 0,
            "weight_unit": "kg",
            "requires_shipping": true
          }
        ],
        "options": [
          {
            "id": 10182938460438,
            "product_id": 8015062368534,
            "name": "Size",
            "position": 1,
            "values": [
              "XS",
              "S",
              "M",
              "L",
              "XL",
              "XXL",
              "3XL",
              "4XL"
            ]
          },
          {
            "id": 11427888103702,
            "product_id": 8015062368534,
            "name": "Color",
            "position": 2,
            "values": [
              "Multicolored"
            ]
          }
        ],
        "images": [
          {
            "id": 44052779565334,
            "product_id": 8015062368534,
            "position": 1,
            "created_at": "2024-03-28T16:34:48+05:30",
            "updated_at": "2024-05-06T11:21:08+05:30",
            "alt": null,
            "width": 1080,
            "height": 1440,
            "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/image_14_67a8c5af-79da-484d-9763-cc77008401f7.png?v=1714974668",
            "variant_ids": []
          },
          {
            "id": 39879067173142,
            "product_id": 8015062368534,
            "position": 2,
            "created_at": "2022-11-19T11:23:54+05:30",
            "updated_at": "2024-05-06T11:21:08+05:30",
            "alt": null,
            "width": 1080,
            "height": 1440,
            "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCAW21D06-14-224_6.jpg?v=1714974668",
            "variant_ids": []
          },
          {
            "id": 42140718039318,
            "product_id": 8015062368534,
            "position": 3,
            "created_at": "2023-08-23T10:45:15+05:30",
            "updated_at": "2024-04-10T12:28:48+05:30",
            "alt": null,
            "width": 1080,
            "height": 1440,
            "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/MotleyCrewLeafPrintedMaxiDressMaroon.jpg?v=1712732328",
            "variant_ids": []
          },
          {
            "id": 39879067140374,
            "product_id": 8015062368534,
            "position": 4,
            "created_at": "2022-11-19T11:23:54+05:30",
            "updated_at": "2024-04-10T12:28:48+05:30",
            "alt": null,
            "width": 1080,
            "height": 1440,
            "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCAW21D06-14-224_5.jpg?v=1712732328",
            "variant_ids": []
          },
          {
            "id": 39879067074838,
            "product_id": 8015062368534,
            "position": 5,
            "created_at": "2022-11-19T11:23:54+05:30",
            "updated_at": "2024-04-03T17:52:41+05:30",
            "alt": null,
            "width": 1080,
            "height": 1440,
            "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCAW21D06-14-224_3.jpg?v=1712146961",
            "variant_ids": []
          },
          {
            "id": 39879067107606,
            "product_id": 8015062368534,
            "position": 6,
            "created_at": "2022-11-19T11:23:54+05:30",
            "updated_at": "2024-03-28T16:34:51+05:30",
            "alt": null,
            "width": 1080,
            "height": 1440,
            "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/products/MCAW21D06-14-224_4.jpg?v=1711623891",
            "variant_ids": []
          }
        ],
        "image": {
          "id": 44052779565334,
          "product_id": 8015062368534,
          "position": 1,
          "created_at": "2024-03-28T16:34:48+05:30",
          "updated_at": "2024-05-06T11:21:08+05:30",
          "alt": null,
          "width": 1080,
          "height": 1440,
          "src": "https://cdn.shopify.com/s/files/1/0674/3906/7414/files/image_14_67a8c5af-79da-484d-9763-cc77008401f7.png?v=1714974668",
          "variant_ids": []
        }
      }
]